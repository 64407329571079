export const GREETING_STEP = 'GREETING_STEP';
export const BOOKING_CONFIRMATION_STEP = 'BOOKING_CONFIRMATION_STEP';
export const DECISION_TREES_INTRO_STEP = 'DECISION_TREES_INTRO_STEP';
export const DECISION_TREE_STEP = 'DECISION_TREE_STEP';
export const DECISION_TREE_DESCRIPTION_STEP = 'DECISION_TREE_DESCRIPTION_STEP';
export const ADDITIONAL_NOTES_STEP = 'ADDITIONAL_NOTES_STEP';
export const ADDITIONAL_SERVICES_STEP = 'ADDITIONAL_SERVICES_STEP';
export const AVAILABLE_RECALLS_STEP = 'AVAILABLE_RECALLS_STEP';
export const BOOKING_SUMMARY_STEP = 'BOOKING_SUMMARY_STEP';
export const SIGNATURE_STEP = 'SIGNATURE_STEP';
export const COMMUNICATION_STEP = 'COMMUNICATION_STEP';
export const KEY_HANDOVER_STEP = 'KEY_HANDOVER_STEP';
export const PAYMENT_STEP = 'PAYMENT_STEP';
export const TRANSPORT_SELECTION_STEP = 'TRANSPORT_SELECTION_STEP';
export const UBER_STEP = 'UBER_STEP';
export const LYFT_STEP = 'LYFT_STEP';
export const SHUTTLE_STEP = 'SHUTTLE_STEP';
export const LOANER_STEP = 'LOANER_STEP';
export const FINAL_STEP = 'FINAL_STEP';
export const ERROR_STEP = 'ERROR_STEP';
export const CONFIRM_IDENTITY_STEP = 'CONFIRM_IDENTITY_STEP';
export const ENTER_PHONE_NUMBER_STEP = 'ENTER_PHONE_NUMBER_STEP';
export const CUSTOMER_CREATION_STEP = 'CUSTOMER_CREATION_STEP';
export const CAR_SELECTION_STEP = 'CAR_SELECTION_STEP';
export const CAR_CREATION_STEP = 'CAR_CREATION_STEP';
export const MILEAGE_STEP = 'MILEAGE_STEP';
export const SERVICE_SELECTION_STEP = 'SERVICE_SELECTION_STEP';
