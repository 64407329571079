import {
  GREETING_STEP,
  BOOKING_CONFIRMATION_STEP,
  DECISION_TREES_INTRO_STEP,
  DECISION_TREE_STEP,
  DECISION_TREE_DESCRIPTION_STEP,
  ADDITIONAL_NOTES_STEP,
  ADDITIONAL_SERVICES_STEP,
  AVAILABLE_RECALLS_STEP,
  BOOKING_SUMMARY_STEP,
  SIGNATURE_STEP,
  COMMUNICATION_STEP,
  KEY_HANDOVER_STEP,
  PAYMENT_STEP,
  TRANSPORT_SELECTION_STEP,
  UBER_STEP,
  SHUTTLE_STEP,
  LOANER_STEP,
  LYFT_STEP,
  FINAL_STEP,
  ERROR_STEP,
  CONFIRM_IDENTITY_STEP,
  ENTER_PHONE_NUMBER_STEP,
  CUSTOMER_CREATION_STEP,
  CAR_CREATION_STEP,
  MILEAGE_STEP,
  SERVICE_SELECTION_STEP,
  CAR_SELECTION_STEP,
} from 'shared/constants/visit-steps';

import GreetingStep from './GreetingStep';
import BookingConfirmationStep from './BookingConfirmationStep';
import BookingConfirmationStepInput from './BookingConfirmationStep/Input';
import DecisionTreesIntroStep from './DecisionTreesIntroStep';
import DecisionTreeStep from './DecisionTreeStep';
import DecisionTreeStepInput from './DecisionTreeStep/Input';
import DecisionTreeDescriptionStep from './DecisionTreeDescriptionStep';
import DecisionTreeDescriptionStepInput from './DecisionTreeDescriptionStep/Input';
import AdditionaNotesStep from './AdditionalNotesStep';
import AdditionalNotesStepInput from './AdditionalNotesStep/Input';
import AdditionalServicesStep from './AdditionalServicesStep';
import AdditionalServicesStepInput from './AdditionalServicesStep/Input';
import AvailableRecallsStep from './AvailableRecallsStep';
import AvailableRecallsStepInput from './AvailableRecallsStep/Input';
import BookingSummaryStep from './BookingSummaryStep';
import BookingSummaryStepInput from './BookingSummaryStep/Input';
import SignatureStep from './SignatureStep';
import SignatureStepInput from './SignatureStep/Input';
import CommunicationStep from './CommunicationStep';
import CommunicationStepInput from './CommunicationStep/Input';
import KeyHandoverStep from './KeyHandoverStep';
import KeyHandoverStepInput from './KeyHandoverStep/Input';
import PaymentStep from './PaymentStep';
import TransportSelectionStep from './TransportSelectionStep';
import TransportSelectionStepInput from './TransportSelectionStep/Input';
import UberStep from './UberStep';
import UberStepInput from './UberStep/Input';
import ShuttleStep from './ShuttleStep';
import LoanerStep from './LoanerStep';
import LyftStep from './LyftStep';
import FinalStep from './FinalStep';
import FinalStepInput from './FinalStep/Input';
import ErrorStep from './ErrorStep';
import ErrorStepInput from './ErrorStep/Input';
import ConfirmIdentityStep from './ConfirmIdentityStep';
import ConfirmIdentityStepInput from './ConfirmIdentityStep/Input';
import EnterPhoneNumberStep from './EnterPhoneNumberStep';
import CustomerCreationStep from './CustomerCreationStep';
import CarCreationStep from './CarCreationStep';
import MileageStep from './MileageStep';
import MileageStepInput from './MileageStep/Input';
import ServiceSelectionStep from './ServiceSelectionStep';
import ServiceSelectionStepInput from './ServiceSelectionStep/Input';
import CarSelectionStep from './CarSelectionStep';
import CarSelectionStepInput from './CarSelectionStep/Input';

const STEPS = {
  [GREETING_STEP]: {
    step: GreetingStep,
    progress: 0,
  },
  [BOOKING_CONFIRMATION_STEP]: {
    step: BookingConfirmationStep,
    input: BookingConfirmationStepInput,
    progress: 0,
  },
  [CONFIRM_IDENTITY_STEP]: {
    step: ConfirmIdentityStep,
    input: ConfirmIdentityStepInput,
    progress: 1,
  },
  [ENTER_PHONE_NUMBER_STEP]: {
    step: EnterPhoneNumberStep,
    progress: 1,
  },
  [CUSTOMER_CREATION_STEP]: {
    step: CustomerCreationStep,
    progress: 1,
  },
  [CAR_CREATION_STEP]: {
    step: CarCreationStep,
    progress: 1,
  },
  [CAR_SELECTION_STEP]: {
    step: CarSelectionStep,
    input: CarSelectionStepInput,
    progress: 2,
  },
  [SERVICE_SELECTION_STEP]: {
    step: ServiceSelectionStep,
    input: ServiceSelectionStepInput,
    progress: 1,
  },
  [MILEAGE_STEP]: {
    step: MileageStep,
    input: MileageStepInput,
    progress: 2,
  },
  [DECISION_TREES_INTRO_STEP]: {
    step: DecisionTreesIntroStep,
    progress: 1,
  },
  [DECISION_TREE_STEP]: {
    step: DecisionTreeStep,
    input: DecisionTreeStepInput,
    progress: 1,
  },
  [DECISION_TREE_DESCRIPTION_STEP]: {
    step: DecisionTreeDescriptionStep,
    input: DecisionTreeDescriptionStepInput,
    progress: 1,
  },
  [ADDITIONAL_NOTES_STEP]: {
    step: AdditionaNotesStep,
    input: AdditionalNotesStepInput,
    progress: 2,
  },
  [ADDITIONAL_SERVICES_STEP]: {
    step: AdditionalServicesStep,
    input: AdditionalServicesStepInput,
    progress: 3,
  },
  [AVAILABLE_RECALLS_STEP]: {
    step: AvailableRecallsStep,
    input: AvailableRecallsStepInput,
    progress: 4,
  },
  [BOOKING_SUMMARY_STEP]: {
    step: BookingSummaryStep,
    input: BookingSummaryStepInput,
    progress: 5,
  },
  [SIGNATURE_STEP]: {
    step: SignatureStep,
    input: SignatureStepInput,
    progress: 6,
  },
  [COMMUNICATION_STEP]: {
    step: CommunicationStep,
    input: CommunicationStepInput,
    progress: 7,
  },
  [KEY_HANDOVER_STEP]: {
    step: KeyHandoverStep,
    input: KeyHandoverStepInput,
    progress: 8,
  },
  [TRANSPORT_SELECTION_STEP]: {
    step: TransportSelectionStep,
    input: TransportSelectionStepInput,
    progress: 9,
  },
  [UBER_STEP]: {
    step: UberStep,
    input: UberStepInput,
    progress: 10,
  },
  [SHUTTLE_STEP]: {
    step: ShuttleStep,
    progress: 10,
  },
  [LOANER_STEP]: {
    step: LoanerStep,
    progress: 10,
  },
  [LYFT_STEP]: {
    step: LyftStep,
    progress: 10,
  },
  [PAYMENT_STEP]: {
    step: PaymentStep,
    progress: 11,
  },
  [FINAL_STEP]: {
    step: FinalStep,
    input: FinalStepInput,
    progress: 12,
  },
  [ERROR_STEP]: {
    step: ErrorStep,
    input: ErrorStepInput,
    progress: 12,
  },
};

export const MAX_PROGRESS = Math.max(...Object.values(STEPS).map(step => step.progress));

export default STEPS;
