import React, { Fragment } from 'react';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { DELAY_500, DELAY_1000 } from 'shared/constants/delays';
import { makeFloat } from 'shared/utils';
import { bookingPropType } from 'shared/constants/prop-types';
import { chatBookingSelector } from 'selectors/visit/chat-selectors';
import { removeService, removeLineItem } from 'actions/visit/chat-actions';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
// import clockIcon from 'assets/images/clock.svg';
import removeIcon from 'assets/images/remove.svg';

import styles from './styles.scss';

const serviceRemovable = (services, lineItems) => {
  const removableServices = [services.maintenance, services.concern, services.extensions];

  return [Object.values(removableServices).flatten(), lineItems].flatten().length > 1;
};

const containsPackage = services =>
  services.maintenance.concat(services.concern).some(s => s.package_items.length !== 0);

const renderServices = (services, label, remove, isEditing, isComplete, removeAllowed) => (
  services.map(({
    /* eslint-disable camelcase */
    id, name, fee, package_items, saving,
    /* eslint-enable camelcase */
  }) => (
    <div key={id} className={styles.service}>
      {isEditing && !isComplete && removeAllowed && (
      <button className={styles.remove} onClick={() => remove(id)}>
        <img alt="remove service" src={removeIcon} />
      </button>
      )}
      <div>
        <span className={styles.name}>{name}</span>
        <span className={styles.kind}>{label}</span>
        { package_items.length > 0 && (
          <div className={styles.packageItemsContainer}>
            <span> package contains: </span>
            { package_items.map(item => (
              <span>{item.name}</span>
              ))}
          </div>
        )}
      </div>
      <div>
        { package_items.length > 0 && saving > 0 && (
          <span className={cx(styles.price, styles.packageItemsPrice)}>
            ${makeFloat(fee + saving)}
          </span>
        )}
        <span className={styles.price}>${makeFloat(fee)}</span>
      </div>
    </div>
  ))
);

const renderLineItems = (lineItems, remove, isEditing, isComplete, removeAllowed) => (
  lineItems.map(({
    id, name, fee,
  }) => (
    <div key={id} className={styles.service}>
      {isEditing && !isComplete && removeAllowed && (
      <button className={styles.remove} onClick={() => remove(id)}>
        <img alt="remove service" src={removeIcon} />
      </button>
      )}
      <div>
        <span className={styles.name}>{name}</span>
      </div>
      <div>
        <span className={styles.price}>${makeFloat(fee)}</span>
      </div>
    </div>
  ))
);

const BookingSummaryStep = ({
  booking: {
    vehicle,
    services,
    date,
    totalPrice,
    // totalTime,
    totalPriceBeforeDiscount,
    discount,
    lineItems,
  },
  isEditing,
  isComplete,
  remove,
  removeItem,
}) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_500}
        message="Great! Here is the summary of your booking:"
        source={SOURCE_CONCIERGE}
        isOpening
        isBig
        isSpeaking
      />
      <Animation delay={DELAY_1000}>
        <div className={styles.container}>
          <div className={styles.header}>Booking details</div>
          <div className={styles.info}>
            <span className={styles.vehicle}>{vehicle}</span>
            <span className={styles.date}>{date}</span>
          </div>
          <div>
            {/* eslint-disable max-len */}
            {renderServices(services.maintenance, 'Maintenance', remove, isEditing, isComplete, serviceRemovable(services, lineItems))}
            {renderServices(services.concern, 'Inspection', remove, isEditing, isComplete, serviceRemovable(services, lineItems))}
            {renderServices(services.extensions, '', remove, isEditing, isComplete, serviceRemovable(services, lineItems))}
            {renderLineItems(lineItems, removeItem, isEditing, isComplete, serviceRemovable(services, lineItems))}
            {/* eslint-enable max-len */}
          </div>
          <div className={styles.summary}>
            <div className={styles.total}>
              <span className={styles.totalLabel}>Total</span>
              <div className={styles.totalPriceContainer}>
                <div>
                  { containsPackage(services) && (
                    <span className={cx(styles.totalValue, styles.totalPackageItemsValue)}>
                      ${totalPriceBeforeDiscount}
                    </span>
                  )}
                  <span className={styles.totalValue}>${makeFloat(totalPrice)}</span>
                </div>
                { containsPackage(services) && (
                  <div>
                    <span>With package you save</span>
                    <span className={styles.discount}>{discount}%</span>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.summaryBlock}>
              <span className={styles.taxInfo}>Plus applicable fees and taxes</span>
            </div>
            {/* <div className={styles.time}>
              <img alt="clock icon" src={clockIcon} />
              <div>
                <span className={styles.timeLabel}>The estimated service time is:</span>
                <span className={styles.timeValue}>{totalTime}</span>
              </div>
            </div> */}
          </div>
        </div>
      </Animation>
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage
          message="Great, let's do this!"
          source={SOURCE_USER}
          isBig
        />
      </AnimationGroup>
    )}
  </Fragment>
);

BookingSummaryStep.propTypes = {
  isComplete: bool,
  isEditing: bool,
  remove: func.isRequired,
  removeItem: func.isRequired,
  booking: bookingPropType.isRequired,
};

BookingSummaryStep.defaultProps = {
  isComplete: false,
  isEditing: false,
};

const mapStateToProps = state => ({
  booking: chatBookingSelector(state),
});

const actions = {
  remove: removeService,
  removeItem: removeLineItem,
};

const BookingSummaryStepContainer = connect(mapStateToProps, actions)(BookingSummaryStep);

export default BookingSummaryStepContainer;
