import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';

import { DELAY_500 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const CustomerCreationStep = ({ name, isComplete }) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_500}
        message="I couldn't find your personal details in the system. Please make your way to the advisor's desk."
        source={SOURCE_CONCIERGE}
        isBig
        isOpening
        isSpeaking
      />
    </AnimationGroup>
    { isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={`Nice to meet you, ${name}!`}
          source={SOURCE_CONCIERGE}
          isBig
          isClosing
          isSpeaking
        />
      </AnimationGroup>
    )}
  </Fragment>
);

CustomerCreationStep.propTypes = {
  name: string,
  isComplete: bool,
};

CustomerCreationStep.defaultProps = {
  name: '',
  isComplete: false,
};

export default CustomerCreationStep;
