import React, { Component } from 'react';
import { func, string, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { DELAY_1000 } from 'shared/constants/delays';
import { extractPhoneNumberFromString } from 'shared/utils';
import { phoneNumberFormatValidator, phoneNumberLengthValidator } from 'shared/validators';
import { appDealershipDmsTypeSelector } from 'selectors/visit/app-selectors';
import { confirmNumber } from 'actions/visit/chat-actions';
import {
  voiceClassificationSelector,
  voiceModelNameSelector,
  voiceTranscriptionSelector,
} from 'selectors/visit/voice-selectors';
import { classifyTranscription, utteranceNotMatched } from 'actions/visit/voice-actions';
import sendIcon from 'assets/images/send.svg';
import animationWrapper from 'components/common/animationWrapper';
import Button from 'components/common/Button';

import styles from './styles.scss';

class CommunicationStepInput extends Component {
  state = {
    phoneNumber: '',
    valid: true,
    isEditing: this.props.emptyNumber,
    isDisabled: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.transcription !== prevProps.transcription && !this.state.isComplete) {
      this.props.classifyTranscription({
        modelName: 'communication',
        transcription: this.props.transcription,
      });
    }
    if (this.props.classification !== prevProps.classification &&
        this.props.modelName === 'communication' && !this.state.isComplete) {
      switch (this.props.classification.intent) {
        case 'confirm':
        case 'yes':
          if (this.state.isEditing) {
            const numberString = this.state.phoneNumber;
            let phoneNumber = null;
            if (phoneNumberFormatValidator(numberString)) {
              phoneNumber = extractPhoneNumberFromString(numberString);
            }
            if (phoneNumber && phoneNumberLengthValidator(phoneNumber, this.props.dmsType)) {
              this.setState({ valid: true, isDisabled: true });
              this.props.onConfirm(phoneNumber);
            } else {
              this.setState({ valid: false });
            }
          } else {
            this.confirmPhoneNumber();
          }
          break;
        case 'change':
          this.toggleEditing();
          break;
        default:
          this.props.utteranceNotMatched();
      }
    }
  }

  toggleEditing = () => this.setState(state => ({ isEditing: !state.isEditing }));
  handleChange = event => this.setState({ phoneNumber: event.target.value });

  handleSubmit = (event) => {
    event.preventDefault();
    const numberString = this.state.phoneNumber;
    let phoneNumber = null;
    if (phoneNumberFormatValidator(numberString)) {
      phoneNumber = extractPhoneNumberFromString(numberString);
    }
    if (phoneNumber && phoneNumberLengthValidator(phoneNumber, this.props.dmsType)) {
      this.setState({ valid: true, isDisabled: true });
      this.props.onConfirm(phoneNumber);
    } else {
      this.setState({ valid: false });
    }
  }

  confirmPhoneNumber = () => {
    this.setState({ isDisabled: true });
    this.props.onConfirm();
  }

  renderForm = () => (
    <div className={styles.formContainer}>
      <form className={styles.form} onSubmit={this.handleSubmit}>
        <input
          type="tel"
          className={cx(styles.input, { [styles.invalid]: !this.state.valid })}
          value={this.state.phoneNumber}
          onChange={this.handleChange}
        />
        <button className={styles.confirm}>
          <img alt="send icon" className={styles.icon} src={sendIcon} />
        </button>
      </form>
    </div>
  )

  renderButtons = () => (
    <div className={styles.container}>
      <Button
        isSecondary
        className={styles.firstButton}
        onClick={this.toggleEditing}
        caption="Change number"
        isBig
      />
      <Button
        onClick={this.confirmPhoneNumber}
        caption="Confirm number"
        isBig
      />
    </div>
  )

  render() {
    const { isDisabled, isEditing } = this.state;

    if (!isDisabled) {
      return isEditing
        ? this.renderForm()
        : this.renderButtons();
    }
    return null;
  }
}

CommunicationStepInput.propTypes = {
  onConfirm: func.isRequired,
  classification: object, // eslint-disable-line react/forbid-prop-types
  modelName: string,
  transcription: string,
  utteranceNotMatched: func.isRequired,
  classifyTranscription: func.isRequired,
  emptyNumber: bool,
  dmsType: string.isRequired,
};

CommunicationStepInput.defaultProps = {
  classification: null,
  transcription: '',
  modelName: '',
  emptyNumber: false,
};

const mapStateToProps = state => ({
  classification: voiceClassificationSelector(state),
  transcription: voiceTranscriptionSelector(state),
  modelName: voiceModelNameSelector(state),
  dmsType: appDealershipDmsTypeSelector(state),
});

const actions = {
  onConfirm: confirmNumber,
  utteranceNotMatched,
  classifyTranscription,
};

const CommunicationStepInputContainer = connect(mapStateToProps, actions)(CommunicationStepInput);

export default animationWrapper(CommunicationStepInputContainer, DELAY_1000);
