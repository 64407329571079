import format from 'date-fns/format';

import { shortenString, sum, percentageDiscount, makeFloat } from 'shared/utils';
import {
  appDealershipAddressSelector,
  appDealershipNameSelector,
} from './app-selectors';

export const servicesSelector = state => ({
  maintenance: state.chat.order.services.maintenance || [],
  concern: state.chat.order.services.concern || [],
  extensions: state.chat.order.additionalServices || [],
  recalls: state.chat.order.selectedRecalls || [],
});

export const allServicesSelector = state =>
  servicesSelector(state).maintenance
    .concat(servicesSelector(state).concern)
    .concat(servicesSelector(state).extensions);

const filterServices = (services, lineItems) => (
  services.filter(service => lineItems.every(lineItem => lineItem.name !== service.name))
);

export const chatLineItemsSelector = state => state.chat.order.lineItems;
export const filteredServicesSelector = (state) => {
  const {
    maintenance,
    concern,
    extensions,
    recalls,
  } = servicesSelector(state);
  const lineItems = chatLineItemsSelector(state);

  return {
    maintenance: filterServices(maintenance, lineItems),
    concern: filterServices(concern, lineItems),
    extensions: filterServices(extensions, lineItems),
    recalls: filterServices(recalls, lineItems),
  };
};

export const allFilteredServicesSelector = state =>
  filteredServicesSelector(state).maintenance
    .concat(filteredServicesSelector(state).concern)
    .concat(filteredServicesSelector(state).extensions)
    .concat(filteredServicesSelector(state).recalls);

export const chatAppointmentDatetimeSelector = state =>
  state.chat.order.appointment.appointment_datetime;
export const chatOrderSelector = state => state.chat.order;
export const chatHistorySelector = state => state.chat.history;
export const chatLoadingSelector = state => state.chat.isLoading;
export const chatInputSelector = state => state.chat.currentInput;
export const chatDecisionTreeResultsSelector = state => state.chat.decisionTreeResults;
export const chatCustomerNameSelector = state => state.chat.order.customer.name;
export const chatCustomerIdSelector = state => state.chat.order.customer.id;
export const chatAdditionalNotesSelector = state => state.chat.order.additionalNotes;
export const chatDecisionTreesSelector = state => state.chat.decisionTrees;
export const chatAdditionalServicesSelector = state => state.chat.additionalServices;
export const chatSelectedExtensionsSelector = state => state.chat.order.additionalServices;
export const chatCurrentVehicleSelector = state => state.chat.order.vehicle;
export const chatCurrentCustomerSelector = state => state.chat.order.customer;
export const chatAvailableRecallsSelector = state => state.chat.availableRecalls;
export const chatSelectedRecallsSelector = state => state.chat.order.selectedRecalls;
export const chatSignatureSelector = state => state.chat.order.signature;
export const chatPhoneNumberSelector = state => state.chat.order.customer.phoneNumber;
export const chatAvailableTransportsSelector = state => state.chat.availableTransports;
export const chatCustomerWaitingSelector = state => state.chat.order.isWaiting;
export const chatLoadingStateDelaySelector = state => state.chat.loadingStateDelay;
export const chatAppointmentIdSelector = state => state.chat.order.appointment.id || '';
export const chatMakeModelYearMapSelector = state => state.chat.makeModelYearMap;
export const chatLastRequestSelector = state => state.chat.lastRequest;
export const chatIsVehicleSyncedSelector = state => state.chat.isVehicleSynced;
export const chatIsInitializedSelector = state => state.chat.isInitialized;
export const chatVehiclesSelector = state => state.chat.vehicles;
export const chatBookingNotFoundSelector = state => typeof state.chat.order.appointment === 'undefined';
export const chatCustomerProvidedPhoneNumberSelector = state =>
  state.chat.customerProvidedPhoneNumber;
export const chatTotalPriceSelector = state => Number(sum(allServicesSelector(state), 'fee'));
export const chatFilteredServicesPriceSelector = state =>
  sum(allFilteredServicesSelector(state), 'fee');
export const chatLineItemsPriceSelector = state =>
  +(chatLineItemsSelector(state)
    .reduce((total, item) => total + parseFloat(item.fee), 0.0).toFixed(2));
export const chatBookingSelector = (state) => {
  const { order } = state.chat;
  const allServices = allServicesSelector(state);
  const lineItems = chatLineItemsSelector(state);
  const services = filteredServicesSelector(state);
  const allFilteredServices = allFilteredServicesSelector(state);
  const totalMenuItemHours = allFilteredServices
    .reduce((total, service) => (total + +(service.allocated_labor_time || 0)), 0)
    .toFixed(1);
  const totalHours = lineItems
    .reduce((total, item) => (Number(total) + parseFloat(item.labor_hours)), +totalMenuItemHours)
    .toFixed(1);
  const timeLabel = totalHours > 1 ? 'hours' : 'hour';
  const { make, model } = order.vehicle;
  const modelYear = order.vehicle.year;
  const vehicle = `${make} ${model}, ${modelYear}`;
  const packageServices = allServices.filter(s => s.package_items.length > 0);
  const totalPriceBeforeDiscount = Number(sum(allServices, 'fee')) +
    Number(sum(packageServices, 'saving'));
  const totalPrice = Number(chatLineItemsPriceSelector(state)
    + chatFilteredServicesPriceSelector(state));
  const discount = percentageDiscount(totalPriceBeforeDiscount, totalPrice);

  return {
    vehicle,
    services,
    date: order.date,
    customer: order.customer.name,
    totalPrice,
    totalPriceBeforeDiscount,
    discount,
    totalTime: `${totalHours} ${timeLabel}`,
    lineItems,
    totalHours,
  };
};

export const chatAllAvailableServicesSelector = state => state.chat.availableServices;
export const chatAvailableServicesSelector = state => ({
  maintenance: state.chat.availableServices.maintenance.filter((service) => {
    const selectedMaintenance = ((state.chat.order.services || {}).maintenance || []);
    const packageItems = [].concat(...selectedMaintenance.map(s => s.package_items));

    return !selectedMaintenance.concat(packageItems).map(s => s.id).includes(service.id);
  }),
  concern: state.chat.availableServices.concern.filter((service) => {
    const selectedConcern = ((state.chat.order.services || {}).concern || []);
    const packageItems = [].concat(...selectedConcern.map(s => s.package_items));

    return !selectedConcern.concat(packageItems).map(s => s.id).includes(service.id);
  }),
});

export const chatServiceIdsSelector = state => Object
  .values(state.chat.order.services)
  .reduce((arr, elem) => [...arr, ...elem.map(e => e.id)], []);

export const chatUberSelector = (state) => {
  const { uber } = state.chat;
  const vehicle = uber.vehicle && `${uber.vehicle.make} ${uber.vehicle.model}`;

  return {
    driverName: uber.driver && uber.driver.name,
    driverPicture: uber.driver && uber.driver.picture_url,
    driverPhone: uber.driver && uber.driver.phone_number,
    time: uber.eta,
    vehicle,
  };
};

export const chatPrintingSummarySelector = (state) => {
  const dealershipInfo =
    `${appDealershipNameSelector(state)}, ${appDealershipAddressSelector(state)}`;
  const filteredServices = allFilteredServicesSelector(state);
  /* eslint-disable no-useless-escape */
  const lineItems = chatLineItemsSelector(state);
  const itemsSummary = filteredServices.concat(lineItems).map(s => `${s.name}: ${makeFloat(s.fee)} USD`)
    .join('\n');
  const totalValue = chatLineItemsPriceSelector(state) + chatFilteredServicesPriceSelector(state);

  const summary = (
    `
     This is your confirmation.
     ${dealershipInfo}
     ${format(new Date(), 'MM/DD/YYYY h:mm a')}
     Repair Order number: ${state.chat.order.repair_order}
     Selected Services:
     ${itemsSummary}
     Total: ${makeFloat(totalValue)} USD plus applicable fees and taxes
    `
  );
  const summaryLines = summary.split('\n');

  return summaryLines.map((el) => {
    const textLines = [];
    let stringToCut = el;
    let lineStartIndex = 0;
    do {
      const textLine = shortenString(stringToCut);
      lineStartIndex += textLine.length;
      textLines.push(textLine.trimLeft());
      stringToCut = el.substr(lineStartIndex, el.length);
    } while (stringToCut !== '');
    return textLines;
  }).reduce((acc, val) => acc.concat(val), []).join('\n');
  /* eslint-enable */
};
