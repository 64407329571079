import React, { Fragment } from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { number, string, func } from 'prop-types';
import { connect } from 'react-redux';

import { visitPath } from 'shared/constants/routes';
import { sessionTokenSelector } from 'selectors/visit/session-selectors';
import { VISIT_CHANNEL } from 'shared/constants/action-cable';
import { actionCableListener } from 'actions/visit/app-actions';
import { appCableChannelKeySelector, appDealershipIdSelector } from 'selectors/visit/app-selectors';
import { dealershipStylesheetUrl } from 'shared/api';
import ActionCable from 'components/common/ActionCable';

import HeaderBar from './HeaderBar';
import Auth from './Auth';
import Visit from './Visit';
import './styles.scss';

const VisitView = ({
  dealershipId,
  authToken,
  channelKey,
  actionCableReceiver,
}) => (
  <div id="visit" className="visit dealership-color-scheme-container">
    {dealershipId && (
      <link rel="stylesheet" type="text/css" href={dealershipStylesheetUrl(dealershipId)} />
    )}
    <BrowserRouter>
      <Fragment>
        <HeaderBar />
        <Route path={visitPath()} render={() => (authToken ? <Visit /> : <Auth />)} />
        <Route exact path="/" render={() => <Redirect to={visitPath()} />} />
      </Fragment>
    </BrowserRouter>
    <ActionCable
      channel={VISIT_CHANNEL}
      channelKey={channelKey}
      authToken={authToken}
      onReceive={actionCableReceiver}
      isTokenNeeded
    />
  </div>
);

VisitView.propTypes = {
  dealershipId: number,
  actionCableReceiver: func.isRequired,
  authToken: string,
  channelKey: string,
};

VisitView.defaultProps = {
  dealershipId: null,
  authToken: null,
  channelKey: null,
};

const mapStateToProps = state => ({
  dealershipId: appDealershipIdSelector(state),
  authToken: sessionTokenSelector(state),
  channelKey: appCableChannelKeySelector(state),
});

const actions = {
  actionCableReceiver: actionCableListener,
};

const VisitViewContainer = connect(mapStateToProps, actions)(VisitView);

export default VisitViewContainer;
