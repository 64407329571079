import { fetchAccessToken } from 'shared/api';

export const SET_AUTH_LOADING_STATE = 'SET_AUTH_LOADING_STATE';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';

const setLoadingState = () => ({
  type: SET_AUTH_LOADING_STATE,
  payload: {},
});

export const authenticateVisit = (login, password) => (dispatch) => {
  dispatch(setLoadingState());

  return fetchAccessToken(login, password)
    .then((response) => {
      dispatch({
        type: AUTHENTICATION_SUCCESS,
        payload: response,
      });
    })
    .catch(error => dispatch({
      type: AUTHENTICATION_FAILURE,
      payload: { error },
    }));
};
