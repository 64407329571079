import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';

import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const CarCreationStep = ({ tryAgain, selectedCarName, isComplete }) => (
  <Fragment>
    {tryAgain && (
      <AnimationGroup>
        <AnimatedTextMessage
          message="There was problem with creating this vehicle. Please try again."
          source={SOURCE_CONCIERGE}
          isClosing
          isBig
          isSpeaking
        />
      </AnimationGroup>
    )}
    <AnimationGroup>
      <AnimatedTextMessage
        message="In order to add a new vehicle information, please make your way to the advisor's desk."
        source={SOURCE_CONCIERGE}
        isClosing
        isBig
        isSpeaking
      />
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={`${selectedCarName}`}
          source={SOURCE_USER}
          isOpening
          isBig
        />
      </AnimationGroup>
    )}
  </Fragment>
);

CarCreationStep.propTypes = {
  selectedCarName: string,
  isComplete: bool,
  tryAgain: bool,
};

CarCreationStep.defaultProps = {
  selectedCarName: '',
  isComplete: false,
  tryAgain: false,
};

export default CarCreationStep;
