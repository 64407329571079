import React from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';

import { exit } from 'actions/visit/app-actions';
import { replayLastRequest } from 'actions/visit/chat-actions';
import animationWrapper from 'components/common/animationWrapper';
import Button from 'components/common/Button';

import styles from './styles.scss';

const ErrorStepInput = ({
  canRetry,
  onRetry,
  onExit,
}) => (
  canRetry && (
    <div className={styles.container}>
      <Button
        caption="Exit"
        isSecondary
        onClick={onExit}
        isBig
      />
      <Button
        caption="Try Again"
        onClick={onRetry}
        isBig
      />
    </div>
  )
);

ErrorStepInput.propTypes = {
  canRetry: bool,
  onExit: func.isRequired,
  onRetry: func.isRequired,
};

ErrorStepInput.defaultProps = {
  canRetry: false,
};

const actions = {
  onExit: exit,
  onRetry: replayLastRequest,
};

const ErrorStepInputContainer = connect(null, actions)(ErrorStepInput);

export default animationWrapper(ErrorStepInputContainer);
