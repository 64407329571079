import React from 'react';
import { func, bool } from 'prop-types';
import InlineSVG from 'react-inlinesvg';
import cx from 'classnames';

import { servicePropType } from 'shared/constants/prop-types';
import selectedIcon from 'assets/images/services/service-checked.svg';
import unselectedIcon from 'assets/images/services/service-plus.svg';

import styles from './styles.scss';

const ExtensionService = ({ service, isHighlighted, onClick }) => (
  <button
    className={cx(styles.container, { [styles.highlighted]: isHighlighted })}
    onClick={onClick}
  >
    <span className={styles.name}>{service.name}</span>
    {service.allocated_labor_time && (
      <span className={styles.time_range}>
        + {service.allocated_labor_time} hours more
      </span>
    )}
    <div className={styles.footer}>
      <div className={isHighlighted ? styles.selectedIcon : styles.unselectedIcon}>
        <InlineSVG src={isHighlighted ? selectedIcon : unselectedIcon} />
      </div>
      <span className={styles.fee}>${service.fee}</span>
    </div>
  </button>
);

ExtensionService.propTypes = {
  service: servicePropType.isRequired,
  onClick: func.isRequired,
  isHighlighted: bool,
};

ExtensionService.defaultProps = {
  isHighlighted: false,
};

export default ExtensionService;
