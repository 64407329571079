import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { func, string, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import Select from 'react-select';

import { backFromChat } from 'actions/visit/app-actions';
import { syncVehicleWithBooking } from 'actions/visit/welcome-actions';
import { appValidPlateSelector } from 'selectors/visit/app-selectors';
import { chatIsVehicleSyncedSelector, chatIsInitializedSelector } from 'selectors/visit/chat-selectors';
import { plateNumberValidator } from 'shared/validators';
import { chatPath } from 'shared/constants/routes';
import spinnerIcon from 'assets/images/spinner.svg';
import { states } from 'shared/constants/us-states';

import styles from './styles.scss';

const getOptionForState = state => states.find(({ value }) => value === state);

class PlateNumberForm extends Component {
  state = {
    plateNumber: this.props.plateNumber || '',
    state: getOptionForState(this.props.state) || '',
    isLoading: false,
    isPlateNumberValid: true,
    isStateValid: true,
  }

  componentDidUpdate(prevProps) {
    if (this.props.isPlateInfoValid !== prevProps.isPlateInfoValid) {
      this.setState({
        isPlateNumberValid: this.props.isPlateInfoValid,
        isStateValid: this.props.isPlateInfoValid,
        isLoading: this.props.isPlateInfoValid,
      });
    }
  }

  onConfirm = () => {
    if (this.validate()) {
      this.props.onConfirm(this.state.plateNumber, this.state.state.value);
      this.setState({ isLoading: true });
    }
  }

  validate = () => {
    const { plateNumber, state } = this.state;
    const isPlateNumberValid = plateNumberValidator(plateNumber);
    const isStateValid = !!state.value;
    this.setState({ isPlateNumberValid, isStateValid });
    return isPlateNumberValid && isStateValid;
  }

  handlePlateNumberChange = event => this.setState({
    isPlateNumberValid: plateNumberValidator(event.target.value),
    plateNumber: event.target.value,
  })
  handleStateChange = state => this.setState({ state, isStateValid: !!state })

  back = () => {
    this.props.onBack();
    this.props.history.push(this.props.location.state.backPath);
  }

  render() {
    const {
      isPlateNumberValid,
      isStateValid,
      plateNumber,
      state,
      isLoading,
    } = this.state;

    if (this.props.isChatInitialized && this.props.isVehicleSynced) {
      return <Redirect to={chatPath()} />;
    }
    return (
      <div className={styles.container}>
        <div className={styles.confirmContainer}>
          <div className={styles.header}>
            Car details
            { !this.props.isVehicleSynced && (
              <div className={styles.small}>
                Please provide plate numbers and state before you continue.
              </div>
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.inputContainer}>
              <div className={styles.label}>Plate number</div>
              <input
                className={cx(styles.plateNumberInput, { [styles.invalid]: !isPlateNumberValid })}
                value={plateNumber}
                disabled={isLoading}
                onChange={this.handlePlateNumberChange}
              />
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.label}>State</div>
              <Select
                className={cx(styles.stateInputContainer, { [styles.invalid]: !isStateValid })}
                classNamePrefix="plate-state-input"
                placeholder=""
                options={states}
                value={state}
                disabled={isLoading}
                onChange={this.handleStateChange}
              />
            </div>
          </div>
          {!isPlateNumberValid && !isStateValid && (
            <div className={styles.errors}>
              This license plate doesn&apos;t appear to be valid
            </div>
          )}
          <div className={styles.footer}>
            <button
              disabled={isLoading}
              onClick={this.back}
              className={cx(styles.back, { [styles.disabled]: isLoading })}
            >
              Back
            </button>
            <button
              disabled={isLoading}
              onClick={this.onConfirm}
              className={cx(styles.confirm, { [styles.disabled]: isLoading })}
            >
              Confirm
            </button>
          </div>
        </div>
        {isLoading && (
          <img alt="loading" className={styles.loadingState} src={spinnerIcon} />
        )}
      </div>
    );
  }
}

PlateNumberForm.propTypes = {
  onConfirm: func.isRequired,
  onBack: func.isRequired,
  history: object.isRequired, // eslint-disable-line react/forbid-prop-types
  location: object.isRequired, // eslint-disable-line react/forbid-prop-types
  plateNumber: string,
  state: string,
  isPlateInfoValid: bool,
  isChatInitialized: bool,
  isVehicleSynced: bool,
};

PlateNumberForm.defaultProps = {
  plateNumber: '',
  state: '',
  isPlateInfoValid: true,
  isChatInitialized: false,
  isVehicleSynced: false,
};

const mapStateToProps = state => ({
  isChatInitialized: chatIsInitializedSelector(state),
  isPlateInfoValid: appValidPlateSelector(state),
  isVehicleSynced: chatIsVehicleSyncedSelector(state),
});

const actions = {
  onBack: backFromChat,
  onConfirm: syncVehicleWithBooking,
};

const PlateNumberFormContainer = connect(mapStateToProps, actions)(PlateNumberForm);

export default PlateNumberFormContainer;
