import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { Lottie } from '@crello/react-lottie';

import {
  voiceIsConciergeSpeakingSelector,
  voiceIsConciergeListeningSelector,
  voiceIsUserSpeakingSelector,
} from 'selectors/visit/voice-selectors';
import { userFinishedSpeaking } from 'actions/visit/voice-actions';
import conciergeSpeaking from 'assets/animations/concierge-speaking.json';
import conciergeListening from 'assets/animations/concierge-listening.json';
import userSpeaking from 'assets/animations/user-speaking.json';

import styles from './styles.scss';

const CONCIERGE_LISTENING = 'CONCIERGE_LISTENING';
const CONCIERGE_SPEAKING = 'CONCIERGE_SPEAKING';
const USER_SPEAKING = 'USER_SPEAKING';

const animationForType = (animationType) => {
  switch (animationType) {
    case CONCIERGE_SPEAKING:
      return conciergeSpeaking;
    case USER_SPEAKING:
      return userSpeaking;
    default:
      return conciergeListening;
  }
};

const lottieOptions = animationType => ({
  loop: true,
  autoplay: true,
  animationData: animationForType(animationType),
});

class SpeechInterface extends Component {
  state = {
    animation: CONCIERGE_LISTENING,
  }

  componentDidMount() {
    window.voiceInterface.onConciergeStartedSpeaking = this.onConciergeStartedSpeaking;
    window.voiceInterface.onConciergeFinishedSpeaking = this.onConciergeFinishedSpeaking;
    window.voiceInterface.onUserStartedSpeaking = this.onUserStartedSpeaking;
    window.voiceInterface.onUserFinishedSpeaking = this.onUserFinishedSpeaking;
  }

  onConciergeStartedSpeaking = () => this.setState({ animation: CONCIERGE_SPEAKING })
  onConciergeFinishedSpeaking = () => this.setState({ animation: CONCIERGE_LISTENING })
  onUserStartedSpeaking = () => this.setState({ animation: USER_SPEAKING })
  onUserFinishedSpeaking = (transcription) => {
    this.props.onTranscriptionReceived(transcription);
    this.setState({ animation: CONCIERGE_LISTENING });
  };

  render() {
    return (
      <div className={styles.container}>
        <Lottie config={lottieOptions(this.state.animation)} />
      </div>
    );
  }
}

SpeechInterface.propTypes = {
  onTranscriptionReceived: func.isRequired,
};

const mapStateToProps = state => ({
  isConciergeSpeaking: voiceIsConciergeSpeakingSelector(state),
  isConciergeListening: voiceIsConciergeListeningSelector(state),
  isUserSpeaking: voiceIsUserSpeakingSelector(state),
});

const actions = { onTranscriptionReceived: userFinishedSpeaking };

const SpeechInterfaceContainer = connect(mapStateToProps, actions)(SpeechInterface);

export default SpeechInterfaceContainer;
