import {
  FETCH_READERS_SUCCESS,
  CONNECT_READER_SUCCESS,
  CREATE_PAYMENT_INTENT_SUCCESS,
  COLLECT_PAYMENT_METHOD_SUCCESS,
  CONFIRM_PAYMENT_INTENT_SUCCESS,
  CAPTURE_PAYMENT_INTENT_SUCCESS,
  PAYMENT_FAILURE,
  CONNECTION_FAILURE,
} from 'actions/visit/payment-actions';

const initialState = {
  discoveredReaders: [],
  reader: null,
  paymentIntent: null,
  paymentIntentSecret: null,
  isCompleted: false,
  errorsCount: 0,
  paymentError: false,
  connectionError: false,
};

const payment = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_READERS_SUCCESS:
      return {
        ...state,
        discoveredReaders: payload.discoveredReaders,
      };
    case CONNECT_READER_SUCCESS:
      return {
        ...state,
        reader: payload.connection.reader,
      };
    case CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        paymentIntentSecret: payload.secret,
      };
    case COLLECT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentIntent: payload.paymentIntent,
      };
    case CONFIRM_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        paymentIntent: {
          ...state.paymentIntent,
          ...payload.paymentIntent,
        },
      };
    case CAPTURE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        paymentIntentSecret: payload.secret,
        isCompleted: true,
      };
    case PAYMENT_FAILURE:
      return {
        ...state,
        paymentIntent: null,
        paymentIntentSecret: null,
        errorsCount: payload.errorsCount,
        paymentError: true,
      };
    case CONNECTION_FAILURE:
      return {
        ...state,
        discoveredReaders: [],
        reader: null,
        errorsCount: payload.errorsCount,
        connectionError: true,
      };
    default:
      return state;
  }
};

export default payment;
