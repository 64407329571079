import React, { Component, Fragment } from 'react';
import { func, string, object } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_1000 } from 'shared/constants/delays';
import { editBooking, confirmOrder } from 'actions/visit/chat-actions';
import {
  voiceClassificationSelector,
  voiceModelNameSelector,
  voiceTranscriptionSelector,
} from 'selectors/visit/voice-selectors';
import { classifyTranscription, utteranceNotMatched } from 'actions/visit/voice-actions';
import animationWrapper from 'components/common/animationWrapper';
import Button from 'components/common/Button';

import styles from './styles.scss';

class BookingConfirmationStepInput extends Component {
  state = {
    isEditing: false,
    isComplete: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.transcription !== prevProps.transcription && !this.state.isComplete) {
      this.props.classifyTranscription({
        modelName: 'bookingSummary',
        transcription: this.props.transcription,
      });
    }
    if (this.props.classification !== prevProps.classification &&
      this.props.modelName === 'bookingSummary' && !this.state.isComplete) {
      switch (this.props.classification.intent) {
        case 'confirm':
          this.confirmBooking();
          break;
        case 'change':
          this.changeDetails();
          break;
        default:
          this.props.utteranceNotMatched();
      }
    }
  }

  confirmBooking = () => {
    this.setState({ isEditing: false, isComplete: true });
    this.props.onConfirm();
  }

  changeDetails = () => {
    this.props.editBooking();
    this.setState({ isEditing: true });
  }

  render() {
    const { isEditing, isComplete } = this.state;
    if (isComplete) { return null; }
    return (
      <div className={styles.container}>
        {isEditing ? (
          <Button
            className={styles.confirm}
            caption="Confirm"
            onClick={this.confirmBooking}
            isBig
          />
        ) : (
          <Fragment>
            <Button
              isSecondary
              caption="Change details"
              onClick={this.changeDetails}
              isBig
            />
            <Button
              className={styles.confirm}
              caption="Confirm"
              onClick={this.confirmBooking}
              isBig
            />
          </Fragment>
        )}
      </div>
    );
  }
}

BookingConfirmationStepInput.propTypes = {
  onConfirm: func.isRequired,
  editBooking: func.isRequired,
  classification: object, // eslint-disable-line react/forbid-prop-types
  modelName: string,
  transcription: string,
  utteranceNotMatched: func.isRequired,
  classifyTranscription: func.isRequired,
};

BookingConfirmationStepInput.defaultProps = {
  classification: null,
  transcription: '',
  modelName: '',
};

const mapStateToProps = state => ({
  classification: voiceClassificationSelector(state),
  transcription: voiceTranscriptionSelector(state),
  modelName: voiceModelNameSelector(state),
});

const actions = {
  editBooking,
  onConfirm: confirmOrder,
  utteranceNotMatched,
  classifyTranscription,
};

const BookingConfirmationStepInputContainer =
  connect(mapStateToProps, actions)(BookingConfirmationStepInput);

export default animationWrapper(BookingConfirmationStepInputContainer, DELAY_1000);
