import React, { useState, Fragment } from 'react';
import { string, bool } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import { appDealershipNameSelector } from 'selectors/visit/app-selectors';

import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const ConfirmIdentityStep = ({
  name,
  isComplete,
  isConfirmed,
  dealershipName,
}) => {
  const [customerName] = useState(name);
  return (
    <Fragment>
      <AnimationGroup>
        <AnimatedTextMessage
          message={`Am I speaking with ${customerName}?`}
          source={SOURCE_CONCIERGE}
          isOpening
          isBig
          isSpeaking
        />
      </AnimationGroup>
      {isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={isConfirmed ? "Yes, that's right." : "No, it's someone else."}
          source={SOURCE_USER}
          isBig
        />
      </AnimationGroup>
    )}
      {isComplete && isConfirmed && (
      <AnimationGroup>
        <AnimatedTextMessage
          delay={DELAY_500}
          message={`Hello, ${customerName}. Welcome to ${dealershipName}. My name is Carmen!`}
          source={SOURCE_CONCIERGE}
          isOpening
          isBig
          isSpeaking
        />
      </AnimationGroup>
    )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  dealershipName: appDealershipNameSelector(state),
});

ConfirmIdentityStep.propTypes = {
  name: string.isRequired,
  dealershipName: string.isRequired,
  isComplete: bool,
  isConfirmed: bool,
};

ConfirmIdentityStep.defaultProps = {
  isComplete: false,
  isConfirmed: false,
};

export default connect(mapStateToProps)(ConfirmIdentityStep);
