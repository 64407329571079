import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { func, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { welcomePath, appointmentListPath, chatPath } from 'shared/constants/routes';
import { extractPhoneNumberFromString } from 'shared/utils';
import { phoneNumberFormatValidator, phoneNumberLengthValidator } from 'shared/validators';
import {
  appDealershipDmsTypeSelector,
  appAppointmentListFetchedSelector,
} from 'selectors/visit/app-selectors';
import { chatIsInitializedSelector } from 'selectors/visit/chat-selectors';
import { fetchCustomerDetails } from 'actions/visit/chat-actions';
import spinnerIcon from 'assets/images/spinner.svg';

import styles from './styles.scss';

class PhoneNumberForm extends Component {
  state = {
    phoneNumber: this.props.phoneNumber || '',
    isLoading: false,
    isValid: true,
    shouldRedirectBack: false,
  }

  confirm = () => {
    if (this.validate()) {
      const phoneNumber = extractPhoneNumberFromString(this.state.phoneNumber);
      this.setState({ isLoading: true });
      this.props.onConfirm(phoneNumber);
    }
  }

  back = () => {
    if (window.printing_communication_interface) {
      window.printing_communication_interface.requestQrCode();
    }
    this.setState({ shouldRedirectBack: true });
  }

  validate = () => {
    const phoneNumberFormatValid = phoneNumberFormatValidator(this.state.phoneNumber);
    const phoneNumberLengthValid = phoneNumberFormatValid && phoneNumberLengthValidator(
      extractPhoneNumberFromString(this.state.phoneNumber),
      this.props.dmsType,
    );
    const isValid = phoneNumberFormatValid && phoneNumberLengthValid;
    this.setState({ isValid });
    return isValid;
  }

  handleChange = event => this.setState({ phoneNumber: event.target.value });

  render() {
    const {
      isValid,
      phoneNumber,
      isLoading,
    } = this.state;
    if (this.props.isAppointmentListFetched) { return <Redirect to={appointmentListPath()} />; }
    if (this.state.shouldRedirectBack) { return <Redirect to={welcomePath()} />; }
    if (this.props.isChatInitialized) { return <Redirect to={chatPath()} />; }

    return (
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.header}>Check in with phone number</div>
          <div className={styles.content}>
            <div className={styles.inputContainer}>
              <div className={styles.label}>Phone number</div>
              <input
                type="tel"
                className={cx(styles.input, { [styles.invalid]: !isValid })}
                value={phoneNumber}
                disabled={isLoading}
                onChange={this.handleChange}
              />
              <div className={styles.errors}>
                {!isValid ? 'This phone number is invalid' : '' }
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <button
              disabled={isLoading}
              onClick={this.back}
              className={cx(styles.back, { [styles.disabled]: isLoading })}
            >
              Back
            </button>
            <button
              disabled={isLoading}
              onClick={this.confirm}
              className={cx(styles.confirm, { [styles.disabled]: isLoading })}
            >
              Confirm
            </button>
          </div>
        </div>
        {isLoading && (
          <div>
            <img alt="loading" className={styles.loadingState} src={spinnerIcon} />
          </div>
        )}
      </div>
    );
  }
}

PhoneNumberForm.propTypes = {
  onConfirm: func.isRequired,
  phoneNumber: string,
  isAppointmentListFetched: bool,
  isChatInitialized: bool,
  dmsType: string.isRequired,
};

PhoneNumberForm.defaultProps = {
  phoneNumber: '',
  isAppointmentListFetched: false,
  isChatInitialized: false,
};

const mapStateToProps = state => ({
  isAppointmentListFetched: appAppointmentListFetchedSelector(state),
  isChatInitialized: chatIsInitializedSelector(state),
  dmsType: appDealershipDmsTypeSelector(state),
});

const actions = {
  onConfirm: fetchCustomerDetails,
};

const PhoneNumberFormContainer = connect(mapStateToProps, actions)(PhoneNumberForm);

export default PhoneNumberFormContainer;
