import React, { Component, Fragment } from 'react';
import { func, arrayOf, shape, number, string, object } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { DELAY_1000, DELAY_2000 } from 'shared/constants/delays';
import { remove } from 'shared/utils';
import { editBooking, confirmBooking, addServices } from 'actions/visit/chat-actions';
import {
  voiceClassificationSelector,
  voiceModelNameSelector,
  voiceTranscriptionSelector,
} from 'selectors/visit/voice-selectors';
import { classifyTranscription, utteranceNotMatched } from 'actions/visit/voice-actions';
import Button from 'components/common/Button';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import { allServicesSelector } from 'selectors/visit/chat-selectors';

import ServiceSelector from './ServiceSelector';
import styles from './styles.scss';

class BookingConfirmationStepInput extends Component {
  state = {
    newMaintenance: [],
    newConcern: [],
    isEditing: false,
    isModalOpen: false,
    isComplete: false,
    disabledPackageItems: [],
  }

  componentDidUpdate(prevProps) {
    if (this.props.transcription !== prevProps.transcription && !this.state.isComplete) {
      this.props.classifyTranscription({
        modelName: 'bookingConfirmationMain',
        transcription: this.props.transcription,
      });
    }
    if (this.props.classification !== prevProps.classification &&
        this.props.modelName === 'bookingConfirmationMain' && !this.state.isComplete) {
      switch (this.props.classification.intent) {
        case 'confirm':
          this.confirmBooking();
          break;
        case 'change':
          this.changeDetails();
          break;
        default:
          this.props.utteranceNotMatched();
      }
    }
  }

  openModal = () => this.setState({ isModalOpen: true })

  confirmBooking = () => {
    this.setState({ isEditing: false, isComplete: true });
    this.props.onConfirm();
  }

  closeModal = () => this.setState({
    isModalOpen: false,
    newMaintenance: [],
    newConcern: [],
  })

  includesService = (collection, service) => collection.map(item => item.id).includes(service.id);

  toggleMaintenance = service => () => {
    const { newMaintenance, disabledPackageItems } = this.state;
    const packageItems = service.package_items;

    if (newMaintenance.find(({ id }) => id === service.id)) {
      this.setState({
        newMaintenance: newMaintenance.filter(({ id }) => id !== service.id),
        disabledPackageItems: remove(disabledPackageItems, packageItems),
      });
    } else {
      this.setState({
        // eslint-disable-next-line max-len
        newMaintenance: newMaintenance.filter(s => !this.includesService(packageItems, s)).concat(service),
        disabledPackageItems: (disabledPackageItems || []).concat(packageItems),
      });
    }
  }

  toggleConcern = service => () => {
    const { newConcern, disabledPackageItems } = this.state;
    const packageItems = service.package_items;

    if (newConcern.find(({ id }) => id === service.id)) {
      this.setState({
        newConcern: newConcern.filter(({ id }) => id !== service.id),
        disabledPackageItems: remove(disabledPackageItems, packageItems),
      });
    } else {
      this.setState({
        newConcern: newConcern.filter(s => !this.includesService(packageItems, s)).concat(service),
        disabledPackageItems: (disabledPackageItems || []).concat(packageItems),
      });
    }
  }

  changeDetails = () => {
    this.props.editBooking();
    this.setState({ isEditing: true });
  }

  addServices = () => {
    this.props.addServices({
      maintenance: this.state.newMaintenance,
      concern: this.state.newConcern,
    });
    this.closeModal();
  }

  renderModal = () => {
    const { newMaintenance, newConcern, disabledPackageItems } = this.state;

    return (
      <div className={styles.overlay}>
        <div className={styles.modal}>
          <ServiceSelector
            selectedMaintenance={newMaintenance}
            selectedConcern={newConcern}
            disabledPackageItems={disabledPackageItems}
            toggleMaintenance={this.toggleMaintenance}
            toggleConcern={this.toggleConcern}
            addServices={this.addServices}
            closeModal={this.closeModal}
          />
        </div>
      </div>
    );
  }

  render() {
    const { isModalOpen, isEditing, isComplete } = this.state;
    const { selectedServices } = this.props;

    if (isComplete) { return null; }
    if (isModalOpen) {
      return this.renderModal();
    }

    return (
      <div className={styles.container}>
        {isEditing ? (
          <Fragment>
            <Button
              isSecondary
              caption="Add more"
              onClick={this.openModal}
              isBig
            />
            <Button
              className={cx(styles.confirm, { [styles.disabled]: !selectedServices.length })}
              caption="Ok"
              onClick={this.confirmBooking}
              disabled={!selectedServices.length}
              isBig
            />
          </Fragment>
        ) : (
          <Fragment>
            <Button
              delay={DELAY_1000}
              isSecondary
              caption="Change details"
              onClick={this.changeDetails}
              isBig
            />
            <Button
              className={styles.confirm}
              caption="Confirm"
              onClick={this.confirmBooking}
              isBig
            />
          </Fragment>
        )}
      </div>
    );
  }
}

BookingConfirmationStepInput.propTypes = {
  onConfirm: func.isRequired,
  editBooking: func.isRequired,
  addServices: func.isRequired,
  selectedServices: arrayOf(shape({
    id: number,
    name: string,
    package_items: arrayOf(shape({ id: number, name: string })),
  })).isRequired,
  transcription: string,
  classification: object, // eslint-disable-line react/forbid-prop-types
  modelName: string,
  classifyTranscription: func.isRequired,
  utteranceNotMatched: func.isRequired,
};

BookingConfirmationStepInput.defaultProps = {
  transcription: '',
  classification: null,
  modelName: '',
};

const mapStateToProps = state => ({
  selectedServices: allServicesSelector(state),
  transcription: voiceTranscriptionSelector(state),
  classification: voiceClassificationSelector(state),
  modelName: voiceModelNameSelector(state),
});

const actions = {
  editBooking,
  addServices,
  onConfirm: confirmBooking,
  classifyTranscription,
  utteranceNotMatched,
};

const BookingConfirmationStepInputContainer = connect(
  mapStateToProps,
  actions,
)(BookingConfirmationStepInput);

/* eslint-disable react/prop-types */
const AnimationWrapper = props => (
  <AnimationGroup>
    <Animation delay={props.delayed ? DELAY_2000 : DELAY_1000}>
      <BookingConfirmationStepInputContainer {...props} />
    </Animation>
  </AnimationGroup>
);
/* eslint-enable react/prop-types */

export default AnimationWrapper;
