import { EXIT } from 'actions/visit/app-actions';

import {
  FETCH_CUSTOMER_APPOINTMENTS_SUCCESS,
  UPDATE_PLATE_DETAILS,
  FETCHING_BOOKING_BY_QR_CODE,
} from 'actions/visit/welcome-actions';

import { START_VISIT_SUCCESS } from 'actions/visit/chat-actions';

const initialState = {
  visitId: null,
  actionCableChannelKey: null,
  dealership: {
    id: 1,
    address: 'Audi Denver, South Broadway, Littleton, CO, USA',
    name: null,
    latitude: 39.606867,
    longitude: -104.9878108,
    taxRate: null,
    welcomeScreen: {},
    dmsType: null,
    timeZone: null,
  },
  plateDetails: {},
  isPlateValid: false,
  isBookingFetchedByQrCode: false,
  isAppointmentListFetched: false,
  appointmentList: [],
};

const app = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case START_VISIT_SUCCESS:
      return {
        ...state,
        visitId: payload.id,
        dealership: {
          ...state.dealership,
          id: payload.dealership.id,
          address: payload.dealership.address,
          name: payload.dealership.name,
          latitude: payload.dealership.latitude,
          longitude: payload.dealership.longitude,
          taxRate: payload.dealership.tax_rate,
          welcomeScreen: payload.dealership.welcome_screen,
          dmsType: payload.dealership.dms_type,
          timeZone: payload.dealership.time_zone,
          servicesPriceVisible: payload.dealership.services_price_visible,
        },
        actionCableChannelKey: payload.channel_key,
      };
    case EXIT:
      return initialState;
    case FETCHING_BOOKING_BY_QR_CODE:
      return {
        ...state,
        isBookingFetchedByQrCode: payload.isBookingFetchedByQrCode,
      };
    case FETCH_CUSTOMER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        isAppointmentListFetched: true,
        appointmentList: [].concat(payload).map(appointment => (
          {
            ...appointment,
            appointmentNumber: appointment.appointment_number,
            carMake: appointment.vehicle_set.make,
            carModel: appointment.vehicle_set.model,
            carYear: appointment.vehicle_set.model_year,
            carPlateNumber: appointment.vehicle.plate_number,
            carRegion: appointment.vehicle.region,
            appointmentDateTime: appointment.appointment_datetime,
          }
        )),
      };
    case UPDATE_PLATE_DETAILS:
      return {
        ...state,
        plateDetails: {
          ...state.plateDetails,
          ...payload,
        },
      };
    default:
      return state;
  }
};

export default app;
