export const states = [{
  label: 'Alabama',
  value: 'al',
},
{
  label: 'Alaska',
  value: 'ak',
},
{
  label: 'American Samoa',
  value: 'as',
},
{
  label: 'Arizona',
  value: 'az',
},
{
  label: 'Arkansas',
  value: 'ar',
},
{
  label: 'California',
  value: 'ca',
},
{
  label: 'Colorado',
  value: 'co',
},
{
  label: 'Connecticut',
  value: 'ct',
},
{
  label: 'Delaware',
  value: 'de',
},
{
  label: 'District Of Columbia',
  value: 'dc',
},
{
  label: 'Federated States Of Micronesia',
  value: 'fm',
},
{
  label: 'Florida',
  value: 'fl',
},
{
  label: 'Georgia',
  value: 'ga',
},
{
  label: 'Guam',
  value: 'gu',
},
{
  label: 'Hawaii',
  value: 'hi',
},
{
  label: 'Idaho',
  value: 'id',
},
{
  label: 'Illinois',
  value: 'il',
},
{
  label: 'Indiana',
  value: 'in',
},
{
  label: 'Iowa',
  value: 'ia',
},
{
  label: 'Kansas',
  value: 'ks',
},
{
  label: 'Kentucky',
  value: 'ky',
},
{
  label: 'Louisiana',
  value: 'la',
},
{
  label: 'Maine',
  value: 'me',
},
{
  label: 'Marshall Islands',
  value: 'mh',
},
{
  label: 'Maryland',
  value: 'md',
},
{
  label: 'Massachusetts',
  value: 'ma',
},
{
  label: 'Michigan',
  value: 'mi',
},
{
  label: 'Minnesota',
  value: 'mn',
},
{
  label: 'Mississippi',
  value: 'ms',
},
{
  label: 'Missouri',
  value: 'mo',
},
{
  label: 'Montana',
  value: 'mt',
},
{
  label: 'Nebraska',
  value: 'ne',
},
{
  label: 'Nevada',
  value: 'nv',
},
{
  label: 'New Hampshire',
  value: 'nh',
},
{
  label: 'New Jersey',
  value: 'nj',
},
{
  label: 'New Mexico',
  value: 'nm',
},
{
  label: 'New York',
  value: 'ny',
},
{
  label: 'North Carolina',
  value: 'nc',
},
{
  label: 'North Dakota',
  value: 'nd',
},
{
  label: 'Northern Mariana Islands',
  value: 'mp',
},
{
  label: 'Ohio',
  value: 'oh',
},
{
  label: 'Oklahoma',
  value: 'ok',
},
{
  label: 'Oregon',
  value: 'or',
},
{
  label: 'Palau',
  value: 'pw',
},
{
  label: 'Pennsylvania',
  value: 'pa',
},
{
  label: 'Puerto Rico',
  value: 'pr',
},
{
  label: 'Rhode Island',
  value: 'ri',
},
{
  label: 'South Carolina',
  value: 'sc',
},
{
  label: 'South Dakota',
  value: 'sd',
},
{
  label: 'Tennessee',
  value: 'tn',
},
{
  label: 'Texas',
  value: 'tx',
},
{
  label: 'Utah',
  value: 'ut',
},
{
  label: 'Vermont',
  value: 'vt',
},
{
  label: 'Virgin Islands',
  value: 'vi',
},
{
  label: 'Virginia',
  value: 'va',
},
{
  label: 'Washington',
  value: 'wa',
},
{
  label: 'West Virginia',
  value: 'wv',
},
{
  label: 'Wisconsin',
  value: 'wi',
},
{
  label: 'Wyoming',
  value: 'wy',
}];
