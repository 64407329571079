import React from 'react';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';

import { chatLoadingSelector } from 'selectors/visit/chat-selectors';
import { confirmIdentity, denyIdentity } from 'actions/visit/chat-actions';
import animationWrapper from 'components/common/animationWrapper';
import Button from 'components/common/Button';

import styles from './styles.scss';

const ConfirmIdentityStepInput = ({ onConfirm, onDeny, isDisabled }) => (
  !isDisabled && (
    <div className={styles.container}>
      <Button
        caption="No"
        isSecondary
        className={styles.button}
        onClick={onDeny}
        isBig
      />
      <Button
        caption="Yes"
        className={styles.button}
        onClick={onConfirm}
        isBig
      />
    </div>
  )
);

ConfirmIdentityStepInput.propTypes = {
  onConfirm: func.isRequired,
  onDeny: func.isRequired,
  isDisabled: bool.isRequired,
};

const mapStateToProps = state => ({
  isDisabled: chatLoadingSelector(state),
});

const actions = {
  onConfirm: confirmIdentity,
  onDeny: denyIdentity,
};

const ConfirmIdentityStepInputContainer =
  connect(mapStateToProps, actions)(ConfirmIdentityStepInput);

export default animationWrapper(ConfirmIdentityStepInputContainer);
