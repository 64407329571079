import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { func, bool } from 'prop-types';

import {
  chatAdditionalServicesSelector,
  chatSelectedExtensionsSelector,
  chatCurrentVehicleSelector,
} from 'selectors/visit/chat-selectors';
import { DELAY_1000, DELAY_1500, DELAY_2000 } from 'shared/constants/delays';
import { servicesPropType, vehiclePropType } from 'shared/constants/prop-types';
import { toggleExtension } from 'actions/visit/chat-actions';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import ExtensionService from 'components/common/ExtensionService';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

import styles from './styles.scss';

const MAX_VISIBLE_EXTENSIONS = 3;

const AdditionalServicesStep = ({
  isComplete, currentVehicle, onToggle, additionalServices, selectedServices,
}) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_1000}
        message={`I can recommend some additional services based on what other ${currentVehicle.model} owners choose for their cars:`}
        source={SOURCE_CONCIERGE}
        isOpening
        isBig
        isSpeaking
      />
      <AnimatedTextMessage
        delay={DELAY_1500}
        message="Feel free to add them to booking!"
        source={SOURCE_CONCIERGE}
        isBig
        isSpeaking
      />
      <Animation delay={DELAY_2000}>
        <div className={styles.services}>
          {additionalServices.slice(0, MAX_VISIBLE_EXTENSIONS).map(service => (
            <ExtensionService
              key={service.id}
              service={service}
              onClick={isComplete ? () => null : () => onToggle(service)}
              isHighlighted={!!selectedServices.find(({ id }) => service.id === id)}
            />
          ))}
        </div>
      </Animation>
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={
            selectedServices.length
              ? `Let's add this to the list: ${selectedServices.map(s => s.name).join(', ')}`
              : 'No, thank you.'
          }
          source={SOURCE_USER}
          isBig
        />
      </AnimationGroup>
    )}
  </Fragment>
);

AdditionalServicesStep.propTypes = {
  isComplete: bool,
  currentVehicle: vehiclePropType.isRequired,
  additionalServices: servicesPropType.isRequired,
  selectedServices: servicesPropType,
  onToggle: func.isRequired,
};

AdditionalServicesStep.defaultProps = {
  isComplete: false,
  selectedServices: [],
};

const mapStateToProps = state => ({
  currentVehicle: chatCurrentVehicleSelector(state),
  additionalServices: chatAdditionalServicesSelector(state),
  selectedServices: chatSelectedExtensionsSelector(state),
});

const actions = {
  onToggle: toggleExtension,
};

const AdditionalServicesStepContainer =
  connect(mapStateToProps, actions)(AdditionalServicesStep);

export default AdditionalServicesStepContainer;
