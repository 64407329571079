import React, { Component } from 'react';
import { number, string, func, arrayOf, shape, bool } from 'prop-types';
import cx from 'classnames';
import InlineSVG from 'react-inlinesvg';

import selectedIcon from 'assets/images/checkmark.svg';
import unselectedIcon from 'assets/images/add.svg';
import { remove, makeFloat } from 'shared/utils';

import styles from './styles.scss';

class ServiceSelector extends Component {
  state = {
    isExpanded: false,
    selectedServices: this.props.selectedServices,
    disabledPackageItems: [].concat(...this.props.selectedServices.map(s => s.package_items)),
  }

  onExpand = () => this.setState({ isExpanded: true })

  handleSubmit = () => {
    this.setState({ isExpanded: false });
    this.props.onSubmit(this.state.selectedServices);
  }

  includesService = (collection, service) => collection.map(item => item.id).includes(service.id);
  isDisabled = service => this.includesService(this.state.disabledPackageItems || [], service);

  toggleService = service => () => {
    const { selectedServices, disabledPackageItems } = this.state;
    if (selectedServices.find(s => s.id === service.id)) {
      this.setState({
        selectedServices: selectedServices.filter(s => s.id !== service.id),
        disabledPackageItems: remove(disabledPackageItems, service.package_items),
      });
    } else {
      this.setState({
        selectedServices: selectedServices.filter(s =>
          !this.includesService(service.package_items, s)).concat(service),
        disabledPackageItems: (disabledPackageItems || []).concat(service.package_items),
      });
    }
  }

  renderIcon = (className, src) => (
    <div className={className}>
      <InlineSVG src={src} />
    </div>
  )

  renderService = (service, isSelected, isDisabled) => (
    <button
      key={service.id}
      className={cx(styles.service, {
          [styles.selected]: isSelected,
          [styles.disabled]: isDisabled,
        })}
      onClick={this.toggleService(service)}
      disabled={isDisabled}
    >
      <div className={styles.serviceContainer}>
        <span className={styles.serviceCaption}>{service.name}</span>
        { service.package_items.length > 0 && (
          <div className={styles.packageItemsContainer}>
            <div className={styles.servicePriceWrap}>
              {this.props.servicesPriceVisible && (
                <div className={styles.servicePriceTag}>
                  ${makeFloat(service.fee || service.price || 0)}
                </div>
              )}
            </div>
            <span> package contains: </span>
            { service.package_items.map(item => (
              <span>{item.name}</span>
            ))}
          </div>
        )}
      </div>
      {isSelected
        ? this.renderIcon(styles.selectedIcon, selectedIcon)
        : this.renderIcon(styles.unselectedIcon, unselectedIcon)
      }
    </button>
  )

  render() {
    const {
      icon,
      label,
      availableServices,
    } = this.props;
    const { isExpanded, selectedServices } = this.state;

    if (isExpanded) {
      return (
        <div className={styles.container}>
          {label === 'Concern' &&
            <div className={styles.alert}>
              <p>Diagnostic fee may be waived if your vehicle is still under warranty.</p>
            </div>
          }
          <div className={styles.services}>
            {availableServices.map(service => (
              this.renderService(
                service,
                !!selectedServices.find(s => s.id === service.id),
                this.isDisabled(service),
              )
            ))}
          </div>
          <div className={styles.submitContainer}>
            <button className={styles.submit} onClick={this.handleSubmit}>Done</button>
          </div>
        </div>
      );
    }
    return (
      <button
        className={cx(styles.button, { [styles.highlighted]: !!selectedServices.length })}
        onClick={this.onExpand}
      >
        {!!selectedServices.length && (
          <div className={styles.counter}>
            {selectedServices.length}
          </div>
        )}
        <img alt={label} src={icon} />
        <span className={styles.caption}>{label}</span>
      </button>
    );
  }
}

ServiceSelector.propTypes = {
  icon: string.isRequired,
  label: string.isRequired,
  servicesPriceVisible: bool.isRequired,
  availableServices: arrayOf(shape({
    id: number,
    name: string,
    package_items: arrayOf(shape({ id: number, name: string })),
  })).isRequired,
  selectedServices: arrayOf(shape({
    id: number,
    name: string,
    package_items: arrayOf(shape({ id: number, name: string })),
  })).isRequired,
  onSubmit: func.isRequired,
};

export default ServiceSelector;
