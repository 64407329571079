import React, { Component } from 'react';
import { connect } from 'react-redux';
import { object, func, bool, string } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { DELAY_1500 } from 'shared/constants/delays';
import { welcomePath } from 'shared/constants/routes';
import { exit } from 'actions/visit/app-actions';
import {
  voiceClassificationSelector,
  voiceModelNameSelector,
  voiceTranscriptionSelector,
} from 'selectors/visit/voice-selectors';
import { classifyTranscription, utteranceNotMatched } from 'actions/visit/voice-actions';
import animationWrapper from 'components/common/animationWrapper';
import Button from 'components/common/Button';

import styles from './styles.scss';

class FinalStepInput extends Component {
  componentDidMount() {
    setTimeout(this.exit, 15000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.transcription !== prevProps.transcription && !this.state.isComplete) {
      this.props.classifyTranscription({
        modelName: 'final',
        transcription: this.props.transcription,
      });
    }
    if (this.props.classification !== prevProps.classification &&
      this.props.modelName === 'final' && !this.state.isComplete) {
      switch (this.props.classification.intent) {
        case 'thanks':
          this.exit();
          break;
        default:
          this.props.utteranceNotMatched();
      }
    }
  }

  exit = () => {
    this.props.onExit();
    this.props.history.push(welcomePath());
  }

  render() {
    return this.props.isComplete && (
      <div className={styles.container}>
        <Button
          delay={DELAY_1500}
          caption="Thanks"
          onClick={this.exit}
          isBig
        />
      </div>
    );
  }
}

FinalStepInput.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  history: object.isRequired,
  classification: object,
  /* eslint-enable react/forbid-prop-types */
  onExit: func.isRequired,
  isComplete: bool,
  modelName: string,
  transcription: string,
  utteranceNotMatched: func.isRequired,
  classifyTranscription: func.isRequired,
};

FinalStepInput.defaultProps = {
  isComplete: false,
  classification: null,
  transcription: '',
  modelName: '',
};

const mapStateToProps = state => ({
  classification: voiceClassificationSelector(state),
  transcription: voiceTranscriptionSelector(state),
  modelName: voiceModelNameSelector(state),
});

const actions = {
  onExit: exit,
  utteranceNotMatched,
  classifyTranscription,
};

const FinalStepInputContainer = connect(mapStateToProps, actions)(FinalStepInput);

export default animationWrapper(withRouter(FinalStepInputContainer), DELAY_1500);
