import {
  syncVehicle,
  fetchBookingByGuid,
  fetchBookingByNumber,
  importAppointmentsOfCustomer,
} from 'shared/api';
import {
  appAppointmentListSelector,
  appVisitIdSelector,
  appPlateDetailsSelector,
} from 'selectors/visit/app-selectors';
import { chatOrderSelector } from 'selectors/visit/chat-selectors';
import { sessionTokenSelector } from 'selectors/visit/session-selectors';
import { setLoadingState, initializeChat } from './chat-actions';

export const UPDATE_PLATE_DETAILS = 'UPDATE_PLATE_DETAILS';
export const FETCH_APPOINTMENT_DETAILS_SUCCESS = 'FETCH_APPOINTMENT_DETAILS_SUCCESS';
export const FETCH_CUSTOMER_APPOINTMENTS_SUCCESS = 'FETCH_CUSTOMER_APPOINTMENTS_SUCCESS';
export const FETCHING_BOOKING_BY_QR_CODE = 'FETCHING_BOOKING_BY_QR_CODE';
export const SET_APPOINTMENT_DETAILS = 'SET_APPOINTMENT_DETAILS';

export const syncVehicleDetails = () => (dispatch, getState) => (
  syncVehicle(
    appVisitIdSelector(getState()),
    chatOrderSelector(getState()),
    appPlateDetailsSelector(getState()),
    sessionTokenSelector(getState()),
  )
);

export const fetchAppointmentsOfCustomer = phoneNumber => (dispatch, getState) => {
  importAppointmentsOfCustomer(
    appVisitIdSelector(getState()),
    sessionTokenSelector(getState()),
    phoneNumber,
  );
};

export const importAppointment = bookingNumber => (dispatch, getState) => (
  fetchBookingByNumber(
    appVisitIdSelector(getState()),
    bookingNumber,
    sessionTokenSelector(getState()),
  )
);

export const importAppointmentByGuid = bookingGuid => (dispatch, getState) => {
  dispatch(setLoadingState());
  dispatch({ type: FETCHING_BOOKING_BY_QR_CODE, payload: { isBookingFetchedByQrCode: true } });
  fetchBookingByGuid(
    appVisitIdSelector(getState()),
    bookingGuid,
    sessionTokenSelector(getState()),
  );
};

export const initializeNewBooking = () => (dispatch) => {
  dispatch(setLoadingState(false));
  dispatch(initializeChat());
};

export const setAppointmentDetials = appointment => (dispatch, getState) => {
  const { appointmentNumber, carPlateNumber, carRegion } = appointment;
  const selectedAppointment = appAppointmentListSelector(getState()).find(a =>
    a.appointment_number === appointmentNumber);
  dispatch({ type: SET_APPOINTMENT_DETAILS, payload: selectedAppointment });
  if (carPlateNumber && carRegion) {
    dispatch({
      type: UPDATE_PLATE_DETAILS,
      payload: {
        plateNumber: carPlateNumber,
        state: carRegion,
        valid: true,
      },
    });
  } else {
    dispatch({ type: UPDATE_PLATE_DETAILS, payload: { valid: false } });
  }
  dispatch(initializeChat());
};

export const syncVehicleWithBooking = (plateNumber, state) => (dispatch) => {
  dispatch({ type: UPDATE_PLATE_DETAILS, payload: { plateNumber, state, valid: true } });
  dispatch(syncVehicleDetails());
};
