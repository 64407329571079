import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { SearchBox } from 'react-google-maps/lib/components/places/SearchBox';
import { compose, withProps, lifecycle } from 'recompose';
import InlineSVG from 'react-inlinesvg';
import cx from 'classnames';

import {
  appDealershipLocationSelector,
  appDealershipAddressSelector,
} from 'selectors/visit/app-selectors';
import { requestRide } from 'actions/visit/chat-actions';
import conciergeIcon from 'assets/images/concierge-icon.svg';
import pinIcon from 'assets/images/pin.svg';
import closeIcon from 'assets/images/close.svg';
import Button from 'components/common/Button';

import styles from './styles.scss';

const BOUNDS_STEP = 0.5;
const MAPS_BASE_URL = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places';
const MAPS_FULL_URL = `${MAPS_BASE_URL}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

const UberStepInput = compose(
  withProps({
    googleMapURL: MAPS_FULL_URL,
    loadingElement: <div className={styles.mapLoading} />,
    containerElement: <div className={styles.mapContainer} />,
    mapElement: <div className={styles.map} />,
  }),
  lifecycle({
    componentDidMount() {
      const { lat, lng } = this.props.dealershipLocation;
      const refs = {};
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        marker: null,
        center: { lat, lng },
        destinationSelected: false,
        rideRequested: false,
        value: '',
        getBounds: () => (
          new google.maps.LatLngBounds(
            new google.maps.LatLng({ lat: lat - BOUNDS_STEP, lng: lng - BOUNDS_STEP }),
            new google.maps.LatLng({ lat: lat + BOUNDS_STEP, lng: lng + BOUNDS_STEP }),
          )
        ),
        onMapMounted: (ref) => { refs.map = ref; },
        onSearchBoxMounted: (ref) => { refs.searchBox = ref; },
        onInputMounted: (ref) => { refs.searchInput = ref; },
        handleChange: event => this.setState({
          value: event.target.value,
          destinationSelected: false,
          marker: null,
        }),
        clearInput: () => this.setState({
          destinationSelected: false,
          value: '',
          marker: null,
          center: { lat, lng },
        }),
        requestRide: () => {
          this.setState({ rideRequested: true });
          this.props.requestRide(this.state.center);
        },
        onPlacesChanged: () => {
          const destination = refs.searchBox.getPlaces()[0];
          this.setState({
            value: refs.searchInput.value,
            destinationSelected: true,
            marker: {
              position: destination.geometry.location,
            },
            center: {
              lat: destination.geometry.location.lat(),
              lng: destination.geometry.location.lng(),
            },
          });
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap,
)(props => (
  <Fragment>
    <GoogleMap
      ref={props.onMapMounted}
      center={props.center}
      defaultZoom={19}
      clickableIcons={false}
      options={{ disableDefaultUI: true }}
    >
      <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.getBounds()}
        controlPosition={google.maps.ControlPosition.TOP_CENTER}
        onPlacesChanged={props.onPlacesChanged}
      >
        <div>
          <div
            className={cx(styles.searchContainer, { [styles.hidden]: props.destinationSelected })}
          >
            <img alt="logo" src={conciergeIcon} />
            <input
              ref={props.onInputMounted}
              type="text"
              value={props.value}
              onChange={props.handleChange}
              placeholder="Where to?"
              className={styles.searchInput}
            />
          </div>
          {props.destinationSelected && (
            <div className={styles.container}>
              <div className={styles.fromContainer}>
                <img alt="logo" src={conciergeIcon} />
                <div className={styles.address}>
                  {props.dealershipAddress}
                </div>
              </div>
              <div className={styles.connector} />
              <div className={styles.toContainer}>
                <img alt="destination" src={pinIcon} />
                <div className={styles.address}>
                  {props.value}
                </div>
                <button className={styles.clear} onClick={props.clearInput}>
                  <InlineSVG src={closeIcon} className={styles.clearIcon} />
                </button>
              </div>
              <Button
                className={styles.submit}
                caption="Request a ride"
                onClick={props.requestRide}
              />
            </div>
          )}
        </div>
      </SearchBox>
      {props.marker && <Marker position={props.marker.position} />}
    </GoogleMap>
    {props.rideRequested && (
      <div className={styles.overlay}>
        <img alt="logo" src={conciergeIcon} className={styles.conciergeIcon} />
        <span className={styles.loading}>
          Searching for a ride...
        </span>
      </div>
    )}
  </Fragment>
));

const mapStateToProps = state => ({
  dealershipLocation: appDealershipLocationSelector(state),
  dealershipAddress: appDealershipAddressSelector(state),
});

const actions = {
  requestRide,
};

const UberStepInputContainer = connect(mapStateToProps, actions)(UberStepInput);

export default UberStepInputContainer;
