import { toFixedPrecision } from 'shared/utils';
import {
  CHANGE_STEP,
  UPDATE_CURRENT_STEP,
  SET_CHAT_LOADING_STATE,
  INITIALIZE_CHAT,
  REMOVE_SERVICE,
  REMOVE_LINE_ITEM,
  ADD_SERVICES,
  FETCH_DECISION_TREES,
  SET_ADDITIONAL_NOTES,
  SET_ADDITIONAL_SERVICES,
  CLEAR_ADDITIONAL_SERVICES,
  TOGGLE_EXTENSION,
  SET_AVAILABLE_RECALLS,
  TOGGLE_RECALL,
  UPDATE_SIGNATURE,
  SET_PHONE_NUMBER,
  SET_CLIENT_WAITING,
  SET_AVAILABLE_TRANSPORTS,
  FETCH_VEHICLE_DETAILS,
  FETCH_REPAIR_ORDER_DETAILS,
  SET_CUSTOMER,
  FETCH_UBER_DETAILS,
  FETCH_SERVICES_SUCCESS,
  SET_TRANSPORT,
  FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS,
  SET_CURRENT_VEHICLE,
  SET_MILEAGE,
  SET_LAST_REQUEST,
  FETCH_VEHICLES_SUCCESS,
  SET_CUSTOMER_PROVIDED_PHONE_NUMBER,
  CLEAR_SELECTED_RECALLS,
} from 'actions/visit/chat-actions';

import { FETCH_APPOINTMENT_DETAILS_SUCCESS, SET_APPOINTMENT_DETAILS } from 'actions/visit/welcome-actions';

import {
  INITIALIZE_DECISION_TREE,
  UPDATE_DECISION_TREE,
  SET_DECISION_TREE_DESCRIPTION,
} from 'actions/visit/decision-trees-actions';

import { EXIT, NOT_SYNCED, BACK_FROM_CHAT } from 'actions/visit/app-actions';
import STEPS, { MAX_PROGRESS } from 'components/steps/visit';
import { SOURCE_CONCIERGE } from 'components/common/TextMessage';

const removeService = (collection = [], id) => (
  collection.filter(service => service.id !== id)
);

const toggleService = (collection, service) => (
  collection.find(({ id }) => service.id === id)
    ? collection.filter(({ id }) => id !== service.id)
    : collection.concat(service)
);

const removeCorrespondingServices = (services, lineItems, id) => {
  const lineItem = lineItems.find(item => item.id === id);

  return {
    maintenance: services.maintenance.filter(({ name }) => name !== lineItem.name),
    concern: services.concern.filter(({ name }) => name !== lineItem.name),
  };
};

const initialState = {
  lastId: 0,
  isLoading: false,
  history: [],
  order: {
    services: {},
    selectedRecalls: [],
    lineItems: [],
  },
  uber: {},
  availableServices: {},
  additionalServices: [],
  decisionTrees: [],
  decisionTreeResults: [],
  progress: 0,
  lastRequest: null,
  isVehicleSynced: true,
  isInitialized: false,
  customerProvidedPhoneNumber: false,
};

/* eslint-disable no-case-declarations */

const chat = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case EXIT:
      return initialState;
    case BACK_FROM_CHAT:
      return {
        ...state,
        isInitialized: false,
      };
    case SET_LAST_REQUEST:
      return {
        ...state,
        lastRequest: payload.request,
      };
    case CHANGE_STEP:
      return {
        ...state,
        history: state.history.concat({
          component: STEPS[payload.step].step,
          props: payload.props || {},
          id: state.lastId + 1,
        }),
        currentInput: STEPS[payload.step].input && {
          component: STEPS[payload.step].input,
          props: { stepId: state.lastId + 1, ...payload.inputProps },
        },
        progress: toFixedPrecision((STEPS[payload.step].progress * 100) / MAX_PROGRESS, 2),
        lastId: state.lastId + 1,
      };
    case UPDATE_CURRENT_STEP:
      return {
        ...state,
        history: state.history.slice(0, -1).concat({
          ...state.history[state.history.length - 1],
          props: {
            ...state.history[state.history.length - 1].props,
            ...payload.props,
          },
        }),
        currentInput: state.currentInput && {
          ...state.currentInput,
          props: {
            ...state.currentInput.props,
            ...payload.inputProps,
          },
        },
      };
    case SET_CHAT_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.value,
        loadingStateDelay: payload.delay,
      };
    case INITIALIZE_CHAT:
      return {
        ...state,
        isInitialized: true,
      };
    case SET_CUSTOMER_PROVIDED_PHONE_NUMBER:
      return {
        ...state,
        customerProvidedPhoneNumber: payload.value,
      };
    case FETCH_VEHICLES_SUCCESS:
      return {
        ...state,
        loadingState: false,
        vehicles: ([].concat(...[], payload.vehicles)).map(vehicle => ({
          id: vehicle.id,
          customerId: vehicle.customerId,
          name: `${vehicle.vehicle_set.make} ${vehicle.vehicle_set.model}`,
          vin: vehicle.vin,
          make: vehicle.vehicle_set.make,
          model: vehicle.vehicle_set.model,
          year: vehicle.vehicle_set.model_year,
          mileage: vehicle.mileage || 0,
        })),
      };
    case FETCH_VEHICLE_DETAILS:
      return {
        ...state,
        isVehicleSynced: payload.state !== NOT_SYNCED,
        order: {
          ...state.order,
          vehicle: {
            ...payload,
            make: payload.vehicle_set.make,
            model: payload.vehicle_set.model,
            year: payload.vehicle_set.model_year,
          },
        },
      };
    case SET_APPOINTMENT_DETAILS:
    case FETCH_APPOINTMENT_DETAILS_SUCCESS:
      const { menu_items: menuItems } = payload;
      const { maintenance = [], concern = [] } = menuItems || {};
      return {
        ...state,
        isVehicleSynced: payload.vehicle.state !== NOT_SYNCED,
        order: {
          ...state.order,
          appointment: {
            ...payload,
          },
          date: payload.appointment_datetime,
          services: { maintenance, concern },
          customer: {
            id: payload.customer.id,
            name: payload.customer.first_name,
            lastName: payload.customer.last_name,
            phoneNumber: [
              state.order.customer && state.order.customer.phoneNumber,
              payload.customer.phone_number,
            ].filter(number => number !== '0').pop(),
            customerNumber: payload.customer.customer_number,
          },
          vehicle: {
            ...payload.vehicle,
            make: payload.vehicle_set.make,
            model: payload.vehicle_set.model,
            year: payload.vehicle_set.model_year,
            mileage: payload.vehicle.mileage,
          },
          lineItems: payload.line_items || [],
        },
      };
    case FETCH_REPAIR_ORDER_DETAILS:
      return {
        ...state,
        order: {
          ...state.order,
          repair_order: payload.repair_order_number,
        },
      };
    case FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS:
      return {
        ...state,
        makeModelYearMap: payload,
      };
    case SET_CURRENT_VEHICLE:

      return {
        ...state,
        order: {
          ...state.order,
          vehicle: payload,
        },
      };
    case SET_MILEAGE:
      return {
        ...state,
        order: {
          ...state.order,
          vehicle: {
            ...state.order.vehicle,
            mileage: payload.mileage,
          },
        },
      };
    case FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        availableServices: {
          maintenance: payload.maintenance || [],
          concern: payload.concern || [],
        },
      };
    case REMOVE_SERVICE:
      return {
        ...state,
        order: {
          ...state.order,
          services: {
            maintenance: removeService(state.order.services.maintenance, payload.id),
            concern: removeService(state.order.services.concern, payload.id),
          },
          additionalServices: removeService(state.order.additionalServices, payload.id),
          selectedRecalls: removeService(state.order.selectedRecalls, payload.id),
        },
      };
    case REMOVE_LINE_ITEM:
      return {
        ...state,
        order: {
          ...state.order,
          services: removeCorrespondingServices(
            state.order.services,
            state.order.lineItems,
            payload.id,
          ),
          lineItems: removeService(state.order.lineItems, payload.id),
        },
      };
    case ADD_SERVICES:
      return {
        ...state,
        order: {
          ...state.order,
          services: {
            maintenance: ((state.order.services || {}).maintenance || [])
              .concat(payload.maintenance),
            concern: ((state.order.services || {}).concern || [])
              .concat(payload.concern),
          },
        },
      };
    case FETCH_DECISION_TREES:
      return {
        ...state,
        decisionTrees: action.payload.map(decisionTree => (
          { ...decisionTree, serviceId: decisionTree.menu_item.id })),
      };
    case INITIALIZE_DECISION_TREE:
      return {
        ...state,
        decisionTreeResults: state.decisionTreeResults.concat({
          serviceId: payload.serviceId,
          conversation: [{ text: payload.intro, source: SOURCE_CONCIERGE }],
        }),
      };
    case UPDATE_DECISION_TREE:
      return {
        ...state,
        decisionTreeResults: [
          ...state.decisionTreeResults.slice(0, -1),
          {
            ...state.decisionTreeResults[state.decisionTreeResults.length - 1],
            conversation: [
              ...state.decisionTreeResults[state.decisionTreeResults.length - 1].conversation,
              { text: payload.text, source: payload.source },
            ],
          },
        ],
      };
    case SET_DECISION_TREE_DESCRIPTION:
      return {
        ...state,
        decisionTreeResults: [
          ...state.decisionTreeResults.slice(0, -1),
          {
            ...state.decisionTreeResults[state.decisionTreeResults.length - 1],
            description: payload.description,
          },
        ],
      };
    case SET_ADDITIONAL_NOTES:
      return {
        ...state,
        order: {
          ...state.order,
          additionalNotes: payload.notes,
        },
      };
    case SET_ADDITIONAL_SERVICES:
      return {
        ...state,
        additionalServices: payload && payload.extension,
        order: {
          ...state.order,
          additionalServices: [],
        },
      };
    case CLEAR_ADDITIONAL_SERVICES:
      return {
        ...state,
        order: {
          ...state.order,
          additionalServices: [],
        },
      };
    case CLEAR_SELECTED_RECALLS:
      return {
        ...state,
        order: {
          ...state.order,
          selectedRecalls: [],
        },
      };
    case TOGGLE_EXTENSION:
      return {
        ...state,
        order: {
          ...state.order,
          additionalServices: toggleService(state.order.additionalServices, payload.service),
        },
      };
    case SET_AVAILABLE_RECALLS:
      return {
        ...state,
        availableRecalls: payload.recalls,
        order: {
          ...state.order,
          selectedRecalls: [],
        },
      };
    case TOGGLE_RECALL:
      return {
        ...state,
        order: {
          ...state.order,
          selectedRecalls: toggleService(state.order.selectedRecalls, payload.service),
        },
      };
    case UPDATE_SIGNATURE:
      return {
        ...state,
        order: {
          ...state.order,
          signature: payload.signature,
        },
      };
    case SET_PHONE_NUMBER:
      return {
        ...state,
        order: {
          ...state.order,
          preferredPhoneNumber: payload.preferredPhoneNumber,
        },
      };
    case SET_CLIENT_WAITING:
      return {
        ...state,
        order: {
          ...state.order,
          clientWaiting: payload.isWaiting,
        },
      };
    case SET_AVAILABLE_TRANSPORTS:
      return {
        ...state,
        availableTransports: payload,
      };
    case SET_TRANSPORT:
      return {
        ...state,
        selectedTransport: payload.transport,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        order: {
          ...state.order,
          customer: {
            id: payload.id,
            name: payload.first_name,
            lastName: payload.last_name,
            phoneNumber: payload.phone_number,
            customerNumber: payload.customer_number,
          },
        },
      };
    case FETCH_UBER_DETAILS:
      return {
        ...state,
        uber: payload.details,
      };
    default:
      return state;
  }
};
/* eslint-enable no-case-declarations */

export default chat;
