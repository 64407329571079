import React, { Fragment } from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';

import { chatCustomerNameSelector } from 'selectors/visit/chat-selectors';
import { appDealershipNameSelector } from 'selectors/visit/app-selectors';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import { SOURCE_CONCIERGE } from 'components/common/TextMessage';

const GreetingStep = ({ customerName, dealershipName }) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        message={`Hello, ${customerName}. Welcome to ${dealershipName}. My name is Carmen!`}
        source={SOURCE_CONCIERGE}
        isOpening
        isBig
        isSpeaking
      />
    </AnimationGroup>
  </Fragment>
);

GreetingStep.propTypes = {
  customerName: string.isRequired,
  dealershipName: string.isRequired,
};

const mapStateToProps = state => ({
  dealershipName: appDealershipNameSelector(state),
  customerName: chatCustomerNameSelector(state),
});

export default connect(mapStateToProps, null)(GreetingStep);
