import React, { Component } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import { finalizeVisit } from 'actions/visit/chat-actions';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

class ShuttleStep extends Component {
  componentDidMount() {
    this.props.finalizeVisit();
  }

  render() {
    return (
      <AnimationGroup>
        <AnimatedTextMessage
          message="A shuttle would be great."
          source={SOURCE_USER}
          isBig
        />
        <AnimatedTextMessage
          delay={DELAY_500}
          message="Sure, the shuttle bus leaves every 20 minutes from outside the workshop."
          source={SOURCE_CONCIERGE}
          isOpening
          isBig
          isSpeaking
        />
      </AnimationGroup>
    );
  }
}

ShuttleStep.propTypes = {
  finalizeVisit: func.isRequired,
};

const actions = {
  finalizeVisit,
};

const ShuttleStepContainer = connect(null, actions)(ShuttleStep);

export default ShuttleStepContainer;
