import React, { Component } from 'react';
import { func, string, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { DELAY_1500 } from 'shared/constants/delays';
import { chatSignatureSelector } from 'selectors/visit/chat-selectors';
import { confirmSignature } from 'actions/visit/chat-actions';
import {
  voiceClassificationSelector,
  voiceModelNameSelector,
  voiceTranscriptionSelector,
} from 'selectors/visit/voice-selectors';
import { classifyTranscription, utteranceNotMatched } from 'actions/visit/voice-actions';
import animationWrapper from 'components/common/animationWrapper';
import Button from 'components/common/Button';

import styles from './styles.scss';

class SignatureStepInput extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.transcription !== prevProps.transcription && !this.props.isComplete) {
      this.props.classifyTranscription({
        modelName: 'signature',
        transcription: this.props.transcription,
      });
    }
    if (this.props.classification !== prevProps.classification &&
        this.props.modelName === 'signature' && !this.props.isComplete) {
      switch (this.props.classification.intent) {
        case 'confirm':
          this.props.onConfirm();
          break;
        default:
          this.props.utteranceNotMatched();
      }
    }
  }

  render() {
    const { signature, onConfirm, isComplete } = this.props;
    return (
      !isComplete && (
        <div className={styles.container}>
          <Button isBig caption="Clear" onClick={() => window.clearSignature()} />
          <Button
            className={cx(styles.confirm, { [styles.disabled]: !signature })}
            caption="Confirm"
            onClick={onConfirm}
            disabled={!signature}
            isBig
          />
        </div>
      )
    );
  }
}

SignatureStepInput.propTypes = {
  signature: string,
  onConfirm: func.isRequired,
  isComplete: bool,
  classification: object, // eslint-disable-line react/forbid-prop-types
  modelName: string,
  transcription: string,
  utteranceNotMatched: func.isRequired,
  classifyTranscription: func.isRequired,
};

SignatureStepInput.defaultProps = {
  signature: null,
  isComplete: false,
  classification: null,
  transcription: '',
  modelName: '',
};

const mapStateToProps = state => ({
  signature: chatSignatureSelector(state),
  classification: voiceClassificationSelector(state),
  transcription: voiceTranscriptionSelector(state),
  modelName: voiceModelNameSelector(state),
});

const actions = {
  onConfirm: confirmSignature,
  utteranceNotMatched,
  classifyTranscription,
};

const SignatureStepInputContainer = connect(mapStateToProps, actions)(SignatureStepInput);

export default animationWrapper(SignatureStepInputContainer, DELAY_1500);
