import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';

import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const CarSelectionStep = ({ selectedCarName, isComplete, preselectVehicle }) => (
  <Fragment>
    {(!preselectVehicle && (!isComplete || (isComplete && selectedCarName))) && (
      <AnimationGroup>
        <AnimatedTextMessage
          message="Which car would you like to service?"
          source={SOURCE_CONCIERGE}
          isClosing
          isBig
          isSpeaking
        />
      </AnimationGroup>
    )}
    {(preselectVehicle && (!isComplete || (isComplete && selectedCarName))) && (
      <AnimationGroup>
        <AnimatedTextMessage
          message="Is this a car you want to service?"
          source={SOURCE_CONCIERGE}
          isClosing
          isBig
          isSpeaking
        />
      </AnimationGroup>
    )}
    {!preselectVehicle && isComplete && selectedCarName && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={`I would like to service my ${selectedCarName}.`}
          source={SOURCE_USER}
          isOpening
          isBig
        />
      </AnimationGroup>
    )}
    {preselectVehicle && isComplete && selectedCarName && (
      <AnimationGroup>
        <AnimatedTextMessage
          message="Yes"
          source={SOURCE_USER}
          isOpening
          isBig
        />
      </AnimationGroup>
    )}
  </Fragment>
);

CarSelectionStep.propTypes = {
  selectedCarName: string,
  isComplete: bool,
  preselectVehicle: bool,
};

CarSelectionStep.defaultProps = {
  selectedCarName: '',
  isComplete: false,
  preselectVehicle: false,
};

export default CarSelectionStep;
