import { createPaymentIntent, capturePaymentIntent, cancelPaymentIntent } from 'shared/api';

import { sessionTokenSelector } from 'selectors/visit/session-selectors';
import { appVisitIdSelector, appDealershipIdSelector } from 'selectors/visit/app-selectors';
import {
  paymentIntentSecretSelector,
  paymentIntentSelector,
  paymentIntentIdSelector,
  paymentErrorsCountSelector,
} from 'selectors/visit/payment-selectors';

import { exit } from './app-actions';
import { initializeErrorStep, setLastRequest } from './chat-actions';

export const FETCH_READERS_SUCCESS = 'FETCH_READERS_SUCCESS';
export const CONNECT_READER_SUCCESS = 'CONNECT_READER_SUCCESS';
export const CREATE_PAYMENT_INTENT_SUCCESS = 'CREATE_PAYMENT_INTENT_SUCCESS';
export const COLLECT_PAYMENT_METHOD_SUCCESS = 'COLLECT_PAYMENT_METHOD_SUCCESS';
export const CONFIRM_PAYMENT_INTENT_SUCCESS = 'CONFIRM_PAYMENT_INTENT_SUCCESS';
export const CAPTURE_PAYMENT_INTENT_SUCCESS = 'CAPTURE_PAYMENT_INTENT_SUCCESS';
export const CANCEL_PAYMENT_INTENT_SUCCESS = 'CANCEL_PAYMENT_INTENT_SUCCESS';
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';
export const CONNECTION_FAILURE = 'CONNECTION_FAILURE';

const FATAL_ERROR = 'A fatal error occured. Please try again later.';

const fatalError = () => (dispatch) => {
  dispatch(initializeErrorStep(FATAL_ERROR, false));
  setTimeout(() => dispatch(exit()), 5000);
};

const handleError = (error, withCancel, errorType) => (dispatch, getState) => {
  const errorsCount = paymentErrorsCountSelector(getState()) + 1;
  const retryable = errorsCount < 3;
  /* eslint-disable no-use-before-define */
  if (withCancel) dispatch(cancelPayment());
  /* eslint-enable */
  if (retryable) {
    dispatch(initializeErrorStep(error, retryable));
    dispatch({ type: errorType, payload: { errorsCount } });
  } else {
    dispatch(fatalError());
  }
};

const cancelPayment = () => (dispatch, getState) => {
  const paymentIntentId = paymentIntentIdSelector(getState());
  const token = sessionTokenSelector(getState());
  const visitId = appVisitIdSelector(getState());
  const dealershipId = appDealershipIdSelector(getState());
  const params = { payment_intent_id: paymentIntentId, dealership_id: dealershipId };
  cancelPaymentIntent(visitId, params, token)
    .then(response => dispatch({ type: CANCEL_PAYMENT_INTENT_SUCCESS, payload: response }))
    .catch(error => dispatch(handleError(error, false)));
};

export const fetchReaders = (terminal, method) => (dispatch) => {
  const request = () => (
    terminal.discoverReaders({ method })
      .then((response) => {
        if (response.discoveredReaders) {
          dispatch({ type: FETCH_READERS_SUCCESS, payload: response });
        } else if (response.error) {
          dispatch(handleError(response.error.message, false, CONNECTION_FAILURE));
        }
      })
  );
  dispatch(setLastRequest(request));
  request();
};

export const connectReader = (terminal, reader) => (dispatch) => {
  terminal.connectReader(reader)
    .then((response) => {
      if (response.connection) {
        dispatch({ type: CONNECT_READER_SUCCESS, payload: response });
      } else if (response.error) {
        dispatch(handleError(response.error.message, false, CONNECTION_FAILURE));
      }
    });
};

export const createPayment = amount => (dispatch, getState) => {
  const token = sessionTokenSelector(getState());
  const visitId = appVisitIdSelector(getState());
  const dealershipId = appDealershipIdSelector(getState());
  const params = { amount, dealership_id: dealershipId };
  const request = () => (
    createPaymentIntent(visitId, params, token)
      .then(response => dispatch({ type: CREATE_PAYMENT_INTENT_SUCCESS, payload: response }))
      .catch(error => dispatch(handleError(error, false, PAYMENT_FAILURE)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const collectPaymentMethod = terminal => (dispatch, getState) => {
  const paymentIntentSecret = paymentIntentSecretSelector(getState());
  terminal.collectPaymentMethod(paymentIntentSecret)
    .then((response) => {
      if (response.paymentIntent) {
        dispatch({ type: COLLECT_PAYMENT_METHOD_SUCCESS, payload: response });
      } else if (response.error) {
        dispatch(handleError(response.error.message, false, PAYMENT_FAILURE));
      }
    });
};

export const confirmPayment = terminal => (dispatch, getState) => {
  const paymentIntent = paymentIntentSelector(getState());
  terminal.confirmPaymentIntent(paymentIntent)
    .then((response) => {
      if (response.paymentIntent) {
        dispatch({ type: CONFIRM_PAYMENT_INTENT_SUCCESS, payload: response });
      } else if (response.error) {
        dispatch(handleError(response.error.message, false, PAYMENT_FAILURE));
      }
    });
};

export const capturePayment = () => (dispatch, getState) => {
  const paymentIntentId = paymentIntentIdSelector(getState());
  const token = sessionTokenSelector(getState());
  const visitId = appVisitIdSelector(getState());
  const dealershipId = appDealershipIdSelector(getState());
  const params = { payment_intent_id: paymentIntentId, dealership_id: dealershipId };
  capturePaymentIntent(visitId, params, token)
    .then(response => dispatch({ type: CAPTURE_PAYMENT_INTENT_SUCCESS, payload: response }))
    .catch(error => dispatch(handleError(error, true, PAYMENT_FAILURE)));
};
