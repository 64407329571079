import React, { Fragment, Component } from 'react';
import { Redirect } from 'react-router-dom';
import { bool, number, func } from 'prop-types';
import { connect } from 'react-redux';
import scrollIntoViewSmoothly from 'utils/smoothScrollIntoView';

import { welcomePath } from 'shared/constants/routes';
import { DELAY_500 } from 'shared/constants/delays';
import { chatHistoryPropType } from 'shared/constants/prop-types';
import {
  conciergeStartedSpeaking,
  conciergeFinishedSpeaking,
} from 'actions/visit/voice-actions';
import {
  chatHistorySelector,
  chatLoadingSelector,
  chatLoadingStateDelaySelector,
  chatIsInitializedSelector,
} from 'selectors/visit/chat-selectors';
import LoadingState from 'components/common/LoadingState';
import LoadingStateText from 'components/common/LoadingStateText';
import InputBar from './InputBar';
import SpeechInterface from './SpeechInterface';

import styles from './styles.scss';

class ChatPage extends Component {
  constructor(props) {
    super(props);
    if (window.voiceInterface.startVoiceInterface) {
      window.voiceInterface.startVoiceInterface();
    }
  }

  componentDidUpdate() {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.scrollTimeout = setTimeout(() => {
        scrollIntoViewSmoothly(this.messagesEnd, { behavior: 'smooth', block: 'end' });
        window.scroll(0, document.getElementById('visit').scrollHeight);
      }, 50);
    }
  }

  render() {
    if (!this.props.isInitialized) { return <Redirect to={welcomePath()} />; }
    return (
      <Fragment>
        <div className={styles.container}>
          {this.props.history.map(({ component, props, id }) => {
            const ChatComponent = component;
            return <ChatComponent key={id} {...props} />;
          })}
          {
            (() => {
              if (this.props.isLoading) {
                if (this.props.history.at(-1)?.id === 1) {
                  return <LoadingStateText delay={this.props.loadingStateDelay} text="I’m retrieving your information, this may take a few seconds..." />;
                }
                return <LoadingState delay={this.props.loadingStateDelay} />;
              }
              return null;
            })()
          }
          <div className={styles.lastMessage} ref={(el) => { this.messagesEnd = el; }} />
        </div>
        <InputBar />
        <SpeechInterface />
      </Fragment>
    );
  }
}

ChatPage.propTypes = {
  isInitialized: bool,
  history: chatHistoryPropType.isRequired,
  isLoading: bool,
  loadingStateDelay: number,
  conciergeStartedSpeaking: func.isRequired,
  conciergeFinishedSpeaking: func.isRequired,
};

ChatPage.defaultProps = {
  isInitialized: false,
  isLoading: false,
  loadingStateDelay: DELAY_500,
};

const mapStateToProps = state => ({
  isInitialized: chatIsInitializedSelector(state),
  history: chatHistorySelector(state),
  isLoading: chatLoadingSelector(state),
  loadingStateDelay: chatLoadingStateDelaySelector(state),
});

const actions = {
  conciergeStartedSpeaking,
  conciergeFinishedSpeaking,
};

const ChatPageContainer = connect(mapStateToProps, actions)(ChatPage);

export default ChatPageContainer;
