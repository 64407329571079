import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func /* , string */ } from 'prop-types';
import { DELAY_1000, DELAY_1500 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import { printingSuccess /* , printingFailure */ } from 'actions/visit/chat-actions';
// import { chatPrintingSummarySelector } from 'selectors/visit/chat-selectors';

class FinalStep extends Component {
  componentDidMount() {
    /* PRINTER DISABLED PER CLIENT REQUEST ON 11.03.2020
      window.receiptInterface.receiptPrinted = this.props.onPrintingSuccess;
      window.receiptInterface.printingFailed = this.props.onPrintingFailure;

      // printReceipt is injected into the receiptInterface object by the Android wrapper.
      if (window.receiptInterface.printReceipt) {
        window.receiptInterface.printReceipt(this.props.summary);
      } else {
        this.props.onPrintingSuccess();
      }
    */
    this.props.onPrintingSuccess();
  }

  render() {
    return (
      <AnimationGroup>
        <AnimatedTextMessage
          delay={DELAY_1000}
          message="I'll keep you posted on the progress via SMS."
          source={SOURCE_CONCIERGE}
          isBig
          isSpeaking
        />
        <AnimatedTextMessage
          delay={DELAY_1500}
          message="Thank you for your visit."
          source={SOURCE_CONCIERGE}
          isClosing
          isBig
          isSpeaking
        />
      </AnimationGroup>
    );
  }
}

FinalStep.propTypes = {
  // summary: string.isRequired,
  onPrintingSuccess: func.isRequired,
  // onPrintingFailure: func.isRequired,
};

// const mapStateToProps = state => ({
//   summary: chatPrintingSummarySelector(state),
// });

const actions = {
  onPrintingSuccess: printingSuccess,
  // onPrintingFailure: printingFailure,
};

const FinalStepContainer = connect(/* mapStateToProps */ null, actions)(FinalStep);

export default FinalStepContainer;
