import React, { Component } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_1000 } from 'shared/constants/delays';
import { addNotes } from 'actions/visit/chat-actions';
import sendIcon from 'assets/images/send.svg';
import Button from 'components/common/Button';
import animationWrapper from 'components/common/animationWrapper';

import styles from './styles.scss';

class AdditionalNotesStepInput extends Component {
  state = {
    isEditing: false,
    isComplete: false,
    notes: '',
  }

  editNote = () => this.setState({ isEditing: true })
  handleChange = event => this.setState({ notes: event.target.value })

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isComplete: true });
    if (this.state.notes) {
      this.props.onAddNote(this.state.notes);
    } else {
      this.props.onConfirm();
    }
  }

  submitEmpty = () => {
    this.setState({ isComplete: true });
    this.props.onConfirm();
  }

  renderForm = () => (
    <div className={styles.formContainer}>
      <form className={styles.form} onSubmit={this.handleSubmit}>
        <textarea
          placeholder="Please describe what needs to be done"
          className={styles.input}
          value={this.state.notes}
          onChange={this.handleChange}
        />
        <button className={styles.submit} onClick={this.handleSubmit}>
          <img alt="send" className={styles.icon} src={sendIcon} />
        </button>
      </form>
    </div>
  );

  renderButtons = () => (
    <div className={styles.container}>
      <Button
        isSecondary
        className={styles.firstButton}
        caption="Add a note"
        onClick={this.editNote}
        isBig
      />
      <Button
        isSecondary
        caption="That's all"
        onClick={this.submitEmpty}
        isBig
      />
    </div>
  );

  render() {
    if (this.state.isComplete) { return null; }
    return this.state.isEditing
      ? this.renderForm()
      : this.renderButtons();
  }
}

AdditionalNotesStepInput.propTypes = {
  onAddNote: func.isRequired,
  onConfirm: func.isRequired,
};

const actions = {
  onAddNote: addNotes,
  onConfirm: () => addNotes(null),
};

const AdditionalNotesStepInputContainer = connect(null, actions)(AdditionalNotesStepInput);

export default animationWrapper(AdditionalNotesStepInputContainer, DELAY_1000);
