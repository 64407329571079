import React, { Fragment, Component } from 'react';
import { func, number, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { Route, Link } from 'react-router-dom';
import {
  welcomePath,
  appointmentListPath,
  plateNumberFormPath,
  phoneNumberFormPath,
  bookingNumberFormPath,
} from 'shared/constants/routes';
import { initializeVisit } from 'actions/visit/chat-actions';
import { appVisitIdSelector, appDealershipWelcomeScreenSelector } from 'selectors/visit/app-selectors';
import AppointmentList from './AppointmentList';
import PlateNumberForm from './PlateNumberForm';
import PhoneNumberForm from './PhoneNumberForm';
import BookingNumberForm from './BookingNumberForm';
import styles from './styles.scss';

class WelcomePage extends Component {
  componentDidMount() {
    this.props.initializeVisit();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.visitId !== this.props.visitId && this.props.visitId === null) {
      this.props.initializeVisit();
    }
  }

  render() {
    return (
      <Fragment>
        <div className={styles.content}>
          <Route
            exact
            path={welcomePath()}
            render={() => (
              <div
                style={{
                  backgroundImage:
                    this.props.welcomeScreen.url ?
                    `url('${this.props.welcomeScreen.url}') ` :
                    null,
                }}
                className={styles.container}
              >
                <Link to={phoneNumberFormPath()} className={styles.welcomeContainer}>
                  <div className={styles.circle1}>
                    <div className={styles.circle2}>
                      <div className={styles.circle3}>
                        <div className={styles.circle4}>
                          <span className={styles.hello}>Hello!</span>
                          <span className={styles.text}>Tap anywhere to start</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          />
          <Route path={appointmentListPath()} component={AppointmentList} />
          <Route path={plateNumberFormPath()} component={PlateNumberForm} />
          <Route path={phoneNumberFormPath()} component={PhoneNumberForm} />
          <Route path={bookingNumberFormPath()} component={BookingNumberForm} />
        </div>
      </Fragment>
    );
  }
}

WelcomePage.propTypes = {
  visitId: number,
  initializeVisit: func.isRequired,
  welcomeScreen: shape({
    url: string,
  }),
};

WelcomePage.defaultProps = {
  visitId: null,
  welcomeScreen: {},
};

const mapStateToProps = state => ({
  visitId: appVisitIdSelector(state),
  welcomeScreen: appDealershipWelcomeScreenSelector(state),
});

const actions = { initializeVisit };
const WelcomePageContainer = connect(mapStateToProps, actions)(WelcomePage);

export default WelcomePageContainer;
