import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { visitPath, chatPath, welcomePath } from 'shared/constants/routes';

import WelcomePage from './WelcomePage';
import ChatPage from './ChatPage';

const Visit = () => (
  <Fragment>
    <Route exact path={visitPath()} render={() => <Redirect to={welcomePath()} />} />
    <Route path={chatPath()} component={ChatPage} />
    <Route path={welcomePath()} component={WelcomePage} />
  </Fragment>
);

export default Visit;
