import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

import { chatCustomerNameSelector } from 'selectors/visit/chat-selectors';

const KeyHandoverStep = ({ name, isComplete }) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_500}
        message={`Alright, ${name}. Please hand your key to the Hostess.`}
        source={SOURCE_CONCIERGE}
        isOpening
        isBig
        isSpeaking
      />
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage isBig message="It's done!" source={SOURCE_USER} />
      </AnimationGroup>
    )}
  </Fragment>
);

KeyHandoverStep.propTypes = {
  name: string.isRequired,
  isComplete: bool,
};

KeyHandoverStep.defaultProps = {
  isComplete: false,
};

const mapStateToProps = state => ({
  name: chatCustomerNameSelector(state),
});

const KeyHandoverStepContainer = connect(mapStateToProps)(KeyHandoverStep);

export default KeyHandoverStepContainer;
