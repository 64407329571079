import React, { Component } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import { finalizeVisit } from 'actions/visit/chat-actions';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

class LyftStep extends Component {
  componentDidMount() {
    this.props.finalizeVisit();
  }

  render() {
    return (
      <AnimationGroup>
        <AnimatedTextMessage
          message="A Lyft would be great."
          source={SOURCE_USER}
          isBig
        />
        <AnimatedTextMessage
          delays={DELAY_500}
          message="Please make your way to the desk to get more details."
          source={SOURCE_CONCIERGE}
          isOpening
          isBig
          isSpeaking
        />
      </AnimationGroup>
    );
  }
}

LyftStep.propTypes = {
  finalizeVisit: func.isRequired,
};

const actions = {
  finalizeVisit,
};

const LyftStepContainer = connect(null, actions)(LyftStep);

export default LyftStepContainer;
