import React, { Component } from 'react';
import { func, number, string, shape, arrayOf, bool } from 'prop-types';
import { connect } from 'react-redux';

import { chatAllAvailableServicesSelector } from 'selectors/visit/chat-selectors';
import { appServicesPriceVisibleSelector } from 'selectors/visit/app-selectors';
import { addServices, finalizeOrder } from 'actions/visit/chat-actions';
import animationWrapper from 'components/common/animationWrapper';
import maintenanceIcon from 'assets/images/maintenance.svg';
import concernIcon from 'assets/images/concern.svg';

import ServiceSelector from './ServiceSelector';

import styles from './styles.scss';

class ServiceSelectionStepInput extends Component {
  state = {
    isComplete: false,
    maintenanceServices: [],
    concernServices: [],
  }

  handleChangeMaintenance = services => this.setState({ maintenanceServices: services });
  handleChangeConcern = services => this.setState({ concernServices: services });

  handleSubmit = () => {
    this.props.onSave({
      maintenance: this.state.maintenanceServices,
      concern: this.state.concernServices,
    });
    this.props.onSubmit();
    this.setState({ isComplete: true });
  };

  render() {
    const { maintenanceServices, concernServices, isComplete } = this.state;
    const { servicesPriceVisible } = this.props;
    if (isComplete) { return null; }
    return (
      <div className={styles.container}>
        <div className={styles.services}>
          <ServiceSelector
            icon={maintenanceIcon}
            label="Maintenance"
            availableServices={this.props.services.maintenance}
            selectedServices={maintenanceServices}
            servicesPriceVisible={servicesPriceVisible}
            onSubmit={this.handleChangeMaintenance}
          />
          <ServiceSelector
            icon={concernIcon}
            label="Concern"
            availableServices={this.props.services.concern}
            selectedServices={concernServices}
            servicesPriceVisible={servicesPriceVisible}
            onSubmit={this.handleChangeConcern}
          />
        </div>
        {(!!maintenanceServices.length || !!concernServices.length) && (
          <button className={styles.submit} onClick={this.handleSubmit}>
            Confirm
          </button>
        )}
      </div>
    );
  }
}

ServiceSelectionStepInput.propTypes = {
  onSave: func.isRequired,
  onSubmit: func.isRequired,
  servicesPriceVisible: bool.isRequired,
  services: shape({
    maintenance: arrayOf(shape({ id: number, name: string })),
    concern: arrayOf(shape({ id: number, name: string })),
  }).isRequired,
};

const mapStateToProps = state => ({
  services: chatAllAvailableServicesSelector(state),
  servicesPriceVisible: appServicesPriceVisibleSelector(state),
});

const actions = {
  onSave: addServices,
  onSubmit: finalizeOrder,
};

const ServiceSelectionStepInputContainer =
  connect(mapStateToProps, actions)(ServiceSelectionStepInput);

export default animationWrapper(ServiceSelectionStepInputContainer);
