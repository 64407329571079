import React, { Component } from 'react';
import { func, string, object, bool } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_1000 } from 'shared/constants/delays';
import { confirmKeyHandover } from 'actions/visit/chat-actions';
import {
  voiceClassificationSelector,
  voiceModelNameSelector,
  voiceTranscriptionSelector,
} from 'selectors/visit/voice-selectors';
import { classifyTranscription, utteranceNotMatched } from 'actions/visit/voice-actions';
import animationWrapper from 'components/common/animationWrapper';
import Button from 'components/common/Button';

import styles from './styles.scss';

class KeyHandoverStepInput extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.transcription !== prevProps.transcription && !this.props.isComplete) {
      this.props.classifyTranscription({
        modelName: 'keyHandover',
        transcription: this.props.transcription,
      });
    }
    if (this.props.classification !== prevProps.classification &&
        this.props.modelName === 'keyHandover' && !this.props.isComplete) {
      switch (this.props.classification.intent) {
        case 'confirm':
          this.props.onConfirm();
          break;
        default:
          this.props.utteranceNotMatched();
      }
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <Button
          onClick={this.props.onConfirm}
          caption="Done"
          isBig
        />
      </div>
    );
  }
}

KeyHandoverStepInput.propTypes = {
  onConfirm: func.isRequired,
  classification: object, // eslint-disable-line react/forbid-prop-types
  modelName: string,
  transcription: string,
  utteranceNotMatched: func.isRequired,
  classifyTranscription: func.isRequired,
  isComplete: bool,
};

KeyHandoverStepInput.defaultProps = {
  classification: null,
  transcription: '',
  modelName: '',
  isComplete: false,
};

const mapStateToProps = state => ({
  classification: voiceClassificationSelector(state),
  transcription: voiceTranscriptionSelector(state),
  modelName: voiceModelNameSelector(state),
});

const actions = {
  onConfirm: confirmKeyHandover,
  utteranceNotMatched,
  classifyTranscription,
};

const KeyHandoverStepInputContainer = connect(mapStateToProps, actions)(KeyHandoverStepInput);

export default animationWrapper(KeyHandoverStepInputContainer, DELAY_1000);
