import React, { Fragment } from 'react';
import { string } from 'prop-types';

import { DELAY_500 } from 'shared/constants/delays';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';

const DecisionTreeDescriptionStep = ({ description }) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_500}
        message="Please describe your concern in details."
        source={SOURCE_CONCIERGE}
        isClosing
        isBig
        isSpeaking
      />
    </AnimationGroup>
    {description && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={description}
          source={SOURCE_USER}
          isBig
        />
      </AnimationGroup>
    )}
  </Fragment>
);

DecisionTreeDescriptionStep.propTypes = {
  description: string,
};

DecisionTreeDescriptionStep.defaultProps = {
  description: '',
};

export default DecisionTreeDescriptionStep;
