import {
  SET_CONCIERGE_SPEAKING,
  CLASSIFICATION_SUCCESS,
  CLASSIFICATION_FAILURE,
  TRANSCRIPTION_RECEIVED,
} from 'actions/visit/voice-actions';

const initialState = {
  isConciergeSpeaking: false,
  isConciergeListening: false,
  isUserSpeaking: false,
  transcription: null,
  classification: null,
  modelName: null,
  classificationError: null,
};

const voice = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CONCIERGE_SPEAKING:
      return {
        ...state,
        isConciergeSpeaking: payload.value,
      };
    case TRANSCRIPTION_RECEIVED:
      return {
        ...state,
        transcription: payload.transcription,
      };
    case CLASSIFICATION_SUCCESS:
      return {
        ...state,
        classification: payload.classification,
        modelName: payload.modelName,
      };
    case CLASSIFICATION_FAILURE:
      return {
        ...state,
        classificationError: payload.error,
      };
    default:
      return state;
  }
};

export default voice;
