import { isNil } from 'ramda';
import * as moment from 'moment-timezone';

import {
  GREETING_STEP,
  BOOKING_CONFIRMATION_STEP,
  DECISION_TREES_INTRO_STEP,
  ADDITIONAL_SERVICES_STEP,
  BOOKING_SUMMARY_STEP,
  SIGNATURE_STEP,
  COMMUNICATION_STEP,
  /* KEY_HANDOVER_STEP, */
  FINAL_STEP,
  TRANSPORT_SELECTION_STEP,
  UBER_STEP,
  LYFT_STEP,
  SHUTTLE_STEP,
  LOANER_STEP,
  ERROR_STEP,
  CONFIRM_IDENTITY_STEP,
  ENTER_PHONE_NUMBER_STEP,
  CAR_CREATION_STEP,
  MILEAGE_STEP,
  SERVICE_SELECTION_STEP,
  CAR_SELECTION_STEP,
  CUSTOMER_CREATION_STEP,
} from 'shared/constants/visit-steps';

import { sessionTokenSelector, refreshTokenSelector } from 'selectors/visit/session-selectors';
import {
  appDealershipIdSelector,
  appVisitIdSelector,
  appDealershipTimeZoneSelector,
} from 'selectors/visit/app-selectors';
import { DELAY_500, DELAY_1000 } from 'shared/constants/delays';
import { formatPhoneNumber } from 'shared/utils';

import {
  fetchDecisionTrees,
  fetchVisitServices,
  fetchAdditionalServices,
  fetchRecallsForVisit,
  fetchAvailableTransportsInKiosk,
  startVisit,
  fetchVisitCustomer,
  updateAppointment,
  sendSignature,
  refreshAccessToken,
  convertAppointmentToRO,
  fetchBookingByNumber,
  addVisitCustomer,
  addTransport,
  fetchMakeModelYearMap,
  updateVisitVehicle,
  addVisitVehicle,
  addVisitAppointment,
  fetchVisitVehicles,
} from 'shared/api';

import {
  chatAppointmentDatetimeSelector,
  chatBookingSelector,
  chatPhoneNumberSelector,
  chatCurrentVehicleSelector,
  chatAppointmentIdSelector,
  chatBookingNotFoundSelector,
  chatSignatureSelector,
  chatServiceIdsSelector,
  chatDecisionTreesSelector,
  chatCurrentCustomerSelector,
  allServicesSelector,
  chatLastRequestSelector,
  chatCustomerIdSelector,
  chatCustomerProvidedPhoneNumberSelector,
  chatVehiclesSelector,
  chatSelectedRecallsSelector,
} from 'selectors/visit/chat-selectors';

import { initializeDecisionTree } from 'actions/visit/decision-trees-actions';

import { AUTHENTICATION_SUCCESS } from './session-actions';

export const CHANGE_STEP = 'CHANGE_STEP';
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
export const SET_CHAT_LOADING_STATE = 'SET_CHAT_LOADING_STATE';
export const INITIALIZE_CHAT = 'INITIALIZE_CHAT';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';
export const ADD_SERVICES = 'ADD_SERVICES';
export const FETCH_DECISION_TREES = 'FETCH_DECISION_TREES';
export const SET_ADDITIONAL_NOTES = 'SET_ADDITIONAL_NOTES';
export const SET_ADDITIONAL_SERVICES = 'SET_ADDITIONAL_SERVICES';
export const TOGGLE_EXTENSION = 'TOGGLE_EXTENSION';
export const SET_AVAILABLE_RECALLS = 'SET_AVAILABLE_RECALLS';
export const TOGGLE_RECALL = 'TOGGLE_RECALL';
export const UPDATE_SIGNATURE = 'UPDATE_SIGNATURE';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_CLIENT_WAITING = 'SET_CLIENT_WAITING';
export const SET_AVAILABLE_TRANSPORTS = 'SET_AVAILABLE_TRANSPORTS';
export const START_VISIT_SUCCESS = 'START_VISIT_SUCCESS';
export const FETCH_VEHICLE_DETAILS = 'FETCH_VEHICLE_DETAILS';
export const FETCH_REPAIR_ORDER_DETAILS = 'FETCH_REPAIR_ORDER_DETAILS';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const FETCH_UBER_DETAILS = 'FETCH_UBER_DETAILS';
export const SET_TRANSPORT = 'SET_TRANSPORT';
export const FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS = 'FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS';
export const SET_CURRENT_VEHICLE = 'SET_CURRENT_VEHICLE';
export const SET_MILEAGE = 'SET_MILEAGE';
export const SET_LAST_REQUEST = 'SET_LAST_REQUEST';
export const SET_CUSTOMER_PROVIDED_PHONE_NUMBER = 'SET_CUSTOMER_PROVIDED_PHONE_NUMBER';
export const CLEAR_ADDITIONAL_SERVICES = 'CLEAR_ADDITIONAL_SERVICES';
export const CLEAR_SELECTED_RECALLS = 'CLEAR_SELECTED_RECALLS';
export const CONVERT_APPOINTMENT_TO_RO = 'CONVERT_APPOINTMENT_TO_RO';

export const LOANER_TRANSPORTATION_TYPE = 'loaner';
export const SHUTTLE_TRANSPORTATION_TYPE = 'shuttle';
export const UBER_TRANSPORTATION_TYPE = 'uber';
export const LYFT_TRANSPORTATION_TYPE = 'lyft';

export const setLastRequest = request => ({ type: SET_LAST_REQUEST, payload: { request } });

export const initializeStep = (step, props, inputProps) => ({
  type: CHANGE_STEP,
  payload: { step, props, inputProps },
});

export const setLoadingState = (value = true, delay = DELAY_500) => ({
  type: SET_CHAT_LOADING_STATE,
  payload: { value, delay },
});

export const replayLastRequest = () => (dispatch, getState) => {
  dispatch(setLoadingState());
  chatLastRequestSelector(getState())();
};

export const updateCurrentStep = (props, inputProps) => ({
  type: UPDATE_CURRENT_STEP,
  payload: { props, inputProps },
});

export const initializeErrorStep = (error, canRetry) => (dispatch) => {
  let message;

  if (error.code === '600.206') {
    message = (
      `You already have an active service appointment for the selected vehicle. 
      A new appointment for this vehicle cannot be created.
      Instead, you may modify your existing appointment.`
    );
  } else {
    message = error instanceof Object ? error.message : error;
  }

  dispatch(initializeStep(ERROR_STEP, { error: message, canRetry }, { canRetry }));
  dispatch(setLoadingState(false));
};

export const makeNewBooking = () => (dispatch, getState) => {
  const customer = chatCurrentCustomerSelector(getState());
  if (customer) {
    dispatch(initializeStep(CONFIRM_IDENTITY_STEP, { name: customer.name }));
  } else {
    dispatch(initializeStep(ENTER_PHONE_NUMBER_STEP));
  }
};

const finalizeAppointment = () => (dispatch, getState) => {
  const { order } = getState().chat;
  const orderDate = moment
    .default(order.date).tz(appDealershipTimeZoneSelector(getState()), true).format();
  const request = () => (
    updateAppointment(
      appVisitIdSelector(getState()),
      appDealershipIdSelector(getState()),
      {
        ...order,
        date: orderDate,
      },
      [
        ...chatServiceIdsSelector(getState()),
        ...getState().chat.order.additionalServices.map(s => s.id),
      ],
      sessionTokenSelector(getState()),
    ).catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const transformAppointmentToRO = () => (dispatch, getState) => {
  dispatch(setLoadingState(true));
  const request = () => (
    convertAppointmentToRO(
      chatAppointmentIdSelector(getState()),
      appVisitIdSelector(getState()),
      sessionTokenSelector(getState()),
    ).catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const initializeVisit = () => (dispatch, getState) => {
  refreshAccessToken(sessionTokenSelector(getState()), refreshTokenSelector(getState()))
    .then(response => dispatch({ type: AUTHENTICATION_SUCCESS, payload: response }))
    .then(() => startVisit(sessionTokenSelector(getState())))
    .then((response) => {
      dispatch({ type: START_VISIT_SUCCESS, payload: response });
      dispatch(setLoadingState(false));
    });
};

export const initializeChat = () => (dispatch, getState) => {
  dispatch({ type: INITIALIZE_CHAT, payload: {} });
  if (chatBookingNotFoundSelector(getState())) {
    dispatch(makeNewBooking());
  } else if (chatCurrentVehicleSelector(getState()).id === null) {
    dispatch(initializeStep(GREETING_STEP));
    dispatch(setLoadingState(true, DELAY_1000));
    const request = () => (
      fetchVisitVehicles(
        appVisitIdSelector(getState()),
        { customer_id: chatCustomerIdSelector(getState()) },
        sessionTokenSelector(getState()),
      ).catch(error => dispatch(initializeErrorStep(error, true)))
    );
    dispatch(setLastRequest(request));
    request();
  } else {
    dispatch(initializeStep(GREETING_STEP));
    dispatch(setLoadingState(true, DELAY_1000));
    fetchVisitServices(
      appVisitIdSelector(getState()),
      appDealershipIdSelector(getState()),
      chatCurrentVehicleSelector(getState()),
    )
      .then((response) => {
        dispatch(setLoadingState(false));
        dispatch({ type: FETCH_SERVICES_SUCCESS, payload: response });
        dispatch(initializeStep(BOOKING_CONFIRMATION_STEP));
      });
  }
};

export const initializeFinalStep = () => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState());
  dispatch(finalizeAppointment());
};

export const submitBookingNumber = bookingNumber => (dispatch, getState) => {
  dispatch(updateCurrentStep({ bookingNumber }));
  dispatch(setLoadingState());
  const request = () => (
    fetchBookingByNumber(
      appVisitIdSelector(getState()),
      bookingNumber,
      sessionTokenSelector(getState()),
    ).catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const initializeCarCreationStep = () => (dispatch, getState) => {
  dispatch(setLoadingState(true, DELAY_1000));
  const request = () => (
    fetchMakeModelYearMap(appDealershipIdSelector(getState()))
      .then((response) => {
        dispatch({ type: FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS, payload: response });
        dispatch(setLoadingState(false));
        dispatch(initializeStep(CAR_CREATION_STEP));
      })
      .catch((error) => {
        dispatch(setLoadingState(false));
        dispatch(initializeErrorStep(error, true));
      })
  );
  dispatch(setLastRequest(request));
  request();
};

export const initializeMileageStep = previousMileage =>
  initializeStep(MILEAGE_STEP, { previousMileage }, { previousMileage });

export const confirmIdentity = () => (dispatch, getState) => {
  dispatch(updateCurrentStep({ isConfirmed: true, isComplete: true }));
  dispatch(setLoadingState(true, DELAY_1000));
  const request = () => (
    fetchVisitVehicles(
      appVisitIdSelector(getState()),
      { customer_id: chatCustomerIdSelector(getState()) },
      sessionTokenSelector(getState()),
    ).catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const denyIdentity = () => (dispatch, getState) => {
  dispatch(updateCurrentStep({ isConfirmed: false, isComplete: true }));
  if (chatCustomerProvidedPhoneNumberSelector(getState())) {
    dispatch(initializeStep(CUSTOMER_CREATION_STEP, {}, {
      phoneNumber: chatPhoneNumberSelector(getState()),
    }));
  } else {
    dispatch(initializeStep(ENTER_PHONE_NUMBER_STEP));
  }
};

export const submitPhoneNumber = phoneNumber => (dispatch, getState) => {
  dispatch(updateCurrentStep({ phoneNumber, isComplete: true }));
  dispatch({ type: SET_CUSTOMER, payload: { phone: phoneNumber } });
  dispatch(setLoadingState());
  const request = () => (
    fetchVisitCustomer(
      appVisitIdSelector(getState()),
      sessionTokenSelector(getState()),
      { phone_number: phoneNumber },
    ).catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const createCustomer = customer => (dispatch, getState) => {
  const firstName = customer.name.split(' ')[0];
  dispatch(updateCurrentStep({ isComplete: true, name: firstName }, { isComplete: true }));
  dispatch(setLoadingState());
  const request = () => (
    addVisitCustomer(appVisitIdSelector(getState()), customer, sessionTokenSelector(getState()))
      .catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const selectCar = car => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true, selectedCarName: car.name }));
  dispatch({ type: SET_CURRENT_VEHICLE, payload: car });
  dispatch(initializeMileageStep(car.mileage));
};

export const selectNewCar = () => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true, selectedCarName: null }));
  dispatch(initializeCarCreationStep());
};

export const createCar = car => (dispatch) => {
  dispatch(updateCurrentStep({
    isComplete: true,
    selectedCarName: `${car.make} ${car.model}, ${car.year}`,
  }));
  dispatch({ type: SET_CURRENT_VEHICLE, payload: car });
  dispatch(initializeMileageStep());
};

export const updateMileage = mileage => (dispatch, getState) => {
  dispatch(updateCurrentStep({ newMileage: mileage, isComplete: true }));
  dispatch({ type: SET_MILEAGE, payload: { mileage } });
  dispatch(setLoadingState());
  const token = sessionTokenSelector(getState());
  const visitId = appVisitIdSelector(getState());
  const currentVehicle = chatCurrentVehicleSelector(getState());
  const currentCustomer = chatCurrentCustomerSelector(getState());
  let request;
  if (currentVehicle.id) {
    request = () => (
      updateVisitVehicle(visitId, currentVehicle, token)
        .catch(error => dispatch(initializeErrorStep(error, true)))
    );
  } else {
    request = () => (
      addVisitVehicle(visitId, currentVehicle, currentCustomer, token)
        .catch(error => dispatch(initializeErrorStep(error, true)))
    );
  }
  dispatch(setLastRequest(request));
  request();
};

export const finalizeOrder = () => (dispatch, getState) => {
  dispatch(setLoadingState());
  const visitId = appVisitIdSelector(getState());
  const dealershipId = appDealershipIdSelector(getState());
  const token = sessionTokenSelector(getState());
  const params = {
    customer: chatCurrentCustomerSelector(getState()),
    vehicle: chatCurrentVehicleSelector(getState()),
    services: allServicesSelector(getState()),
    recalls: chatSelectedRecallsSelector(getState()),
  };
  const request = () => (
    addVisitAppointment(visitId, dealershipId, params, token)
      .catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const initializeServiceSelectionStep = () => initializeStep(SERVICE_SELECTION_STEP);

export const editBooking = () => updateCurrentStep({ isEditing: true });
export const removeService = id => ({ type: REMOVE_SERVICE, payload: { id } });
export const removeLineItem = id => ({ type: REMOVE_LINE_ITEM, payload: { id } });
export const addServices = newServices => ({ type: ADD_SERVICES, payload: newServices });

export const selectAdditionalServices = selectedServices => (dispatch, getState) => {
  if (selectedServices === null) { dispatch({ type: CLEAR_ADDITIONAL_SERVICES }); }

  const visitId = appVisitIdSelector(getState());
  const { vin } = chatCurrentVehicleSelector(getState());
  const token = sessionTokenSelector(getState());
  const request = () => (
    fetchRecallsForVisit(visitId, vin, token)
      .catch(error => dispatch(initializeErrorStep(error, true)))
  );

  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState());
  dispatch(setLastRequest(request));
  request();
};

export const initializeCarSelectionStep = () => (dispatch, getState) => {
  if (!chatBookingNotFoundSelector(getState()) &&
      chatVehiclesSelector(getState()).length === 1) {
    dispatch(initializeStep(
      CAR_SELECTION_STEP,
      { preselectVehicle: true },
      { preselectVehicle: true },
    ));
  } else {
    dispatch(initializeStep(CAR_SELECTION_STEP));
  }
};

export const goToAdditionalServicesStep = () => (dispatch, getState) => {
  const request = () => (
    fetchAdditionalServices(
      appVisitIdSelector(getState()),
      appDealershipIdSelector(getState()),
      chatCurrentVehicleSelector(getState()),
      sessionTokenSelector(getState()),
    ).then((payload) => {
      dispatch({ type: SET_ADDITIONAL_SERVICES, payload });
      const services = (payload.extension || []).filter(s => s.fee);
      if (services.length) {
        dispatch(initializeStep(ADDITIONAL_SERVICES_STEP, { services }));
      } else {
        dispatch(selectAdditionalServices());
      }
    }).catch(error => dispatch(initializeErrorStep(error, true)))
      .finally(() => dispatch(setLoadingState(false)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const confirmBooking = () => (dispatch, getState) => {
  dispatch(updateCurrentStep({ isComplete: true, isEditing: false }));
  dispatch(setLoadingState());
  const request = () => (
    fetchDecisionTrees(chatServiceIdsSelector(getState()), sessionTokenSelector(getState()))
      .then((response) => {
        if (response.length > 0) {
          dispatch(setLoadingState(false));
          dispatch(initializeStep(DECISION_TREES_INTRO_STEP));
          dispatch({ type: FETCH_DECISION_TREES, payload: response });
          setTimeout(() =>
            dispatch(initializeDecisionTree(chatDecisionTreesSelector(getState())[0])), DELAY_500);
        } else {
          setTimeout(() => dispatch(goToAdditionalServicesStep()), DELAY_1000);
        }
      })
      .catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const addNotes = notes => (dispatch) => {
  if (notes) {
    dispatch({ type: SET_ADDITIONAL_NOTES, payload: { notes } });
  }
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState());
  dispatch(goToAdditionalServicesStep());
};

export const toggleExtension = service => ({
  type: TOGGLE_EXTENSION,
  payload: { service },
});

export const toggleRecall = service => ({
  type: TOGGLE_RECALL,
  payload: { service },
});

export const selectRecalls = selectedRecalls => (dispatch) => {
  if (selectedRecalls === null) { dispatch({ type: CLEAR_SELECTED_RECALLS }); }
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(initializeStep(BOOKING_SUMMARY_STEP));
};

export const confirmOrder = () => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(initializeStep(SIGNATURE_STEP));
};

export const updateSignature = signature => ({
  type: UPDATE_SIGNATURE,
  payload: { signature },
});

export const confirmSignature = () => (dispatch, getState) => {
  dispatch(updateCurrentStep({}, { isComplete: true }));
  const phoneNumber = chatPhoneNumberSelector(getState());
  const request = () => (
    sendSignature(
      chatAppointmentIdSelector(getState()),
      chatSignatureSelector(getState()),
      sessionTokenSelector(getState()),
    ).then(() => {
      dispatch(updateCurrentStep({
        isComplete: true,
        signature: chatSignatureSelector(getState()),
      }));
      if (phoneNumber === '0' || isNil(phoneNumber)) {
        dispatch(initializeStep(COMMUNICATION_STEP, { emptyNumber: true }, { emptyNumber: true }));
      } else {
        dispatch(initializeStep(COMMUNICATION_STEP));
      }
    }).catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const confirmKeyHandover = () => (dispatch, getState) => {
  // dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState(true, DELAY_500));
  const { totalHours, totalPrice } = chatBookingSelector(getState());

  const request = () => (
    fetchAvailableTransportsInKiosk(
      appVisitIdSelector(getState()),
      totalPrice,
      totalHours[1],
      chatAppointmentDatetimeSelector(getState()),
      sessionTokenSelector(getState()),
    )
      .then((response) => {
        dispatch(setLoadingState(false));
        dispatch({ type: SET_AVAILABLE_TRANSPORTS, payload: response });
        dispatch(initializeStep(TRANSPORT_SELECTION_STEP));
      })
      .catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const confirmNumber = phoneNumber => (dispatch, getState) => {
  const preferredPhoneNumber =
    formatPhoneNumber(phoneNumber || chatPhoneNumberSelector(getState()));
  dispatch(updateCurrentStep({ isComplete: true, preferredPhoneNumber }));
  dispatch({ type: SET_PHONE_NUMBER, payload: { preferredPhoneNumber } });

  dispatch(initializeFinalStep());
};

export const paymentSuccess = () => (dispatch) => {
  dispatch(setLoadingState(true, DELAY_500));
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState(false));
  dispatch(initializeStep(FINAL_STEP));
};

export const printingSuccess = () => (dispatch) => {
  dispatch(updateCurrentStep({}, { isComplete: true }));
};

export const printingFailure = error => initializeErrorStep(error);

export const selectWaiting = () => (dispatch) => {
  dispatch(updateCurrentStep({ isConfirmed: true, confirmedWaiting: true }));
  dispatch({ type: SET_CLIENT_WAITING, payload: { isWaiting: true } });
  dispatch(initializeFinalStep());
};

export const selectNotWaiting = () => (dispatch) => {
  dispatch({ type: SET_CLIENT_WAITING, payload: { isWaiting: false } });
  dispatch(updateCurrentStep({ isConfirmed: true, confirmedWaiting: false }));
};

export const selectNoTransport = () => (dispatch) => {
  dispatch(updateCurrentStep({ hasSelectedTransport: true, needsTransport: false }));
  dispatch(initializeFinalStep());
};

export const selectNeedTransport = () => updateCurrentStep({
  hasSelectedTransport: true,
  needsTransport: true,
});

export const selectLoaner = () => (dispatch, getState) => {
  dispatch({ type: SET_TRANSPORT, payload: { transport: LOANER_TRANSPORTATION_TYPE } });
  const request = () => (
    addTransport(
      appVisitIdSelector(getState()),
      chatAppointmentIdSelector(getState()),
      LOANER_TRANSPORTATION_TYPE,
      sessionTokenSelector(getState()),
    ).then(() => {
      dispatch(initializeStep(LOANER_STEP));
    }).catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};
export const selectShuttle = () => (dispatch, getState) => {
  dispatch({ type: SET_TRANSPORT, payload: { transport: SHUTTLE_TRANSPORTATION_TYPE } });
  const request = () => (
    addTransport(
      appVisitIdSelector(getState()),
      chatAppointmentIdSelector(getState()),
      SHUTTLE_TRANSPORTATION_TYPE,
      sessionTokenSelector(getState()),
    ).then(() => {
      dispatch(initializeStep(SHUTTLE_STEP));
    }).catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};
export const selectLyft = () => (dispatch) => {
  const request = () => dispatch(initializeStep(LYFT_STEP));
  dispatch(setLastRequest(request));
  request();
};

export const selectUber = () => (dispatch) => {
  dispatch({ type: SET_TRANSPORT, payload: { transport: UBER_TRANSPORTATION_TYPE } });
  dispatch(initializeStep(UBER_STEP, {}, { showMap: true }));
};

export const requestRide = () => (dispatch) => {
  dispatch({
    type: FETCH_UBER_DETAILS,
    payload: {
      details: {
        vehicle: {
          make: 'Audi',
          model: 'RS5',
        },
        driver: {
          name: 'Ryan',
        },
        eta: '14',
      },
    },
  });
  dispatch(initializeFinalStep());
  // const request = () => (
  //   fetchUberRide(
  //     appVisitIdSelector(getState()),
  //     chatAppointmentIdSelector(getState()),
  //     coordinates,
  //     sessionTokenSelector(getState()),
  //   ).catch(error => dispatch(initializeErrorStep(error, true)))
  // );
  // dispatch(setLastRequest(request));
  // request();
};

export const finalizeVisit = () => initializeFinalStep();
export const fetchCustomerDetails = phoneNumber => (dispatch, getState) => {
  const request = () => (
    fetchVisitCustomer(
      appVisitIdSelector(getState()),
      sessionTokenSelector(getState()),
      { phone_number: phoneNumber },
    ).catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};
