import React, { Component, Fragment } from 'react';
import { func, arrayOf, number, string, shape } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import InlineSVG from 'react-inlinesvg';

import { availableServicesPropType, servicesPropType } from 'shared/constants/prop-types';
import { chatAvailableServicesSelector } from 'selectors/visit/chat-selectors';
import Button from 'components/common/Button';
import closeIcon from 'assets/images/close.svg';
import maintenanceIcon from 'assets/images/maintenance.svg';
import concernIcon from 'assets/images/concern.svg';
import selectedIcon from 'assets/images/checkmark.svg';
import unselectedIcon from 'assets/images/add.svg';

import styles from './styles.scss';

const MAINTENANCE_TAB = 'MAINTENANCE';
const CONCERN_TAB = 'CONCERN';

const MAINTENANCE_HEADER = 'Maintenance services';
const CONCERN_HEADER = 'All concerns';

class ServiceSelector extends Component {
  state = {
    currentTab: null,
  }

  openTab = tab => () => this.setState({ currentTab: tab })
  closeTab = () => this.setState({ currentTab: null })
  closeModal = () => {
    this.setState({ currentTab: null });
    this.props.closeModal();
  }

  isDisabled = service => this.props.disabledPackageItems.map(item => item.id).includes(service.id)

  renderIcon = (className, src) => (
    <div className={className}>
      <InlineSVG src={src} />
    </div>
  )

  renderService = (service, onClick, isSelected, isDisabled) => (
    <button
      key={service.id}
      className={cx(styles.service, {
          [styles.highlighted]: isSelected,
          [styles.disabled]: isDisabled,
        })}
      onClick={onClick(service)}
      disabled={isDisabled}
    >
      <div>
        <span className={styles.serviceName}>{service.name}</span>
        { service.package_items.length > 0 && (
          <div className={styles.packageItemsContainer}>
            <span> package contains: </span>
            { service.package_items.map(item => (
              <span>{item.name}</span>
            ))}
          </div>
        )}
      </div>
      {isSelected ? (
        this.renderIcon(styles.selectedIcon, selectedIcon)
      ) : (
        this.renderIcon(styles.unselectedIcon, unselectedIcon)
      )}
    </button>
  )

  renderSelector = (header, services, selectedServices, toggleService) => (
    <Fragment>
      <div className={styles.header}>
        <span className={styles.headerCaption}>{header}</span>
        <button className={styles.close} onClick={this.closeTab}>
          <InlineSVG src={closeIcon} />
        </button>
      </div>
      <div className={styles.servicesContainer}>
        {services.map(service => (
          this.renderService(
            service,
            toggleService,
            !!selectedServices.find(({ id }) => id === service.id),
            this.isDisabled(service),
          )
        ))}
      </div>
      <div className={styles.footer}>
        <Button caption="Done" onClick={this.closeTab} />
      </div>
    </Fragment>
  )

  renderServices = () => {
    const {
      availableServices,
      selectedMaintenance,
      selectedConcern,
      toggleMaintenance,
      toggleConcern,
    } = this.props;
    const { maintenance, concern } = availableServices;

    return this.state.currentTab === MAINTENANCE_TAB
      ? this.renderSelector(MAINTENANCE_HEADER, maintenance, selectedMaintenance, toggleMaintenance)
      : this.renderSelector(CONCERN_HEADER, concern, selectedConcern, toggleConcern);
  }

  renderTabSelect = () => {
    const {
      availableServices,
      selectedMaintenance,
      selectedConcern,
      closeModal,
      addServices,
    } = this.props;

    return (
      <Fragment>
        <div className={styles.header}>
          What do you want to add?
          <button className={styles.close} onClick={closeModal}>
            <InlineSVG src={closeIcon} />
          </button>
        </div>
        <div className={styles.tabsContainer}>
          <button
            className={cx(styles.tab, {
              [styles.highlighted]: !!selectedMaintenance.length,
              [styles.disabled]: !availableServices.maintenance.length,
            })}
            disabled={!availableServices.maintenance.length}
            onClick={this.openTab(MAINTENANCE_TAB)}
          >
            {!!selectedMaintenance.length && (
              <div className={styles.counter}>
                {selectedMaintenance.length}
              </div>
            )}
            <img alt="maintenance" src={maintenanceIcon} />
            <span className={styles.tabCaption}>Maintenance</span>
          </button>
          <button
            className={cx(styles.tab, {
              [styles.highlighted]: !!selectedConcern.length,
              [styles.disabled]: !availableServices.concern.length,
            })}
            disabled={!availableServices.concern.length}
            onClick={this.openTab(CONCERN_TAB)}
          >
            {!!selectedConcern.length && (
              <div className={styles.counter}>
                {selectedConcern.length}
              </div>
            )}
            <img alt="concern" src={concernIcon} />
            <span className={styles.tabCaption}>Concern</span>
          </button>
        </div>
        {(!!selectedMaintenance.length || !!selectedConcern.length) && (
          <div className={styles.footer}>
            <Button caption="Done" onClick={addServices} />
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    return this.state.currentTab ? this.renderServices() : this.renderTabSelect();
  }
}

ServiceSelector.propTypes = {
  availableServices: availableServicesPropType.isRequired,
  selectedMaintenance: servicesPropType.isRequired,
  selectedConcern: servicesPropType.isRequired,
  toggleMaintenance: func.isRequired,
  toggleConcern: func.isRequired,
  addServices: func.isRequired,
  closeModal: func.isRequired,
  disabledPackageItems: arrayOf(shape({ id: number, name: string })),
};

ServiceSelector.defaultProps = {
  disabledPackageItems: [],
};

const mapStateToProps = state => ({
  availableServices: chatAvailableServicesSelector(state),
});

const ServiceSelectorContainer = connect(mapStateToProps)(ServiceSelector);

export default ServiceSelectorContainer;
