import React from 'react';

import { DELAY_500 } from 'shared/constants/delays';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import { SOURCE_CONCIERGE } from 'components/common/TextMessage';

const DecisionTreesIntroStep = () => (
  <AnimationGroup>
    <AnimatedTextMessage
      delay={DELAY_500}
      isOpening
      isBig
      isSpeaking
      source={SOURCE_CONCIERGE}
      message="I'd like to know more about your concerns regarding your car."
    />
  </AnimationGroup>
);

export default DecisionTreesIntroStep;
