import React from 'react';
import { func, string, number, arrayOf, oneOfType, shape, bool } from 'prop-types';
import { connect } from 'react-redux';
import shortid from 'shortid';

import { DELAY_1000 } from 'shared/constants/delays';
import { nextDecisionTreeStep } from 'actions/visit/decision-trees-actions';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import Button from 'components/common/Button';

import styles from './styles.scss';

const DecisionTreeStepInput = ({
  serviceId, answers, onSubmitAnswer, isComplete,
}) => (
  !isComplete && answers ? (
    <AnimationGroup>
      <Animation delay={DELAY_1000}>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            {answers.map(answer => (
              <Button
                key={shortid.generate()}
                caption={answer.text}
                onClick={() => onSubmitAnswer(serviceId, answer.goto, answer.text)}
                isSecondary
                isBig
              />
            ))}
          </div>
        </div>
      </Animation>
    </AnimationGroup>
  ) : null
);

DecisionTreeStepInput.propTypes = {
  serviceId: number,
  answers: arrayOf(shape({
    text: string,
    goto: oneOfType([string, number]),
  })),
  onSubmitAnswer: func.isRequired,
  isComplete: bool,
};

DecisionTreeStepInput.defaultProps = {
  serviceId: null,
  answers: null,
  isComplete: false,
};

const actions = {
  onSubmitAnswer: nextDecisionTreeStep,
};

const DecisionTreeStepInputContainer = connect(null, actions)(DecisionTreeStepInput);

export default DecisionTreeStepInputContainer;
