import { DECISION_TREE_STEP } from 'shared/constants/visit-steps';

import {
  chatDecisionTreesSelector,
  chatDecisionTreeResultsSelector,
  chatAppointmentIdSelector,
} from 'selectors/visit/chat-selectors';

import { sessionTokenSelector } from 'selectors/visit/session-selectors';

import { sendDecisionTreeResult } from 'shared/api';

import { DELAY_500 } from 'shared/constants/delays';
import { SOURCE_USER, SOURCE_CONCIERGE } from 'components/common/TextMessage';
import { updateCurrentStep, initializeStep, goToAdditionalServicesStep, initializeErrorStep } from 'actions/visit/chat-actions';

export const INITIALIZE_DECISION_TREE = 'INITIALIZE_DECISION_TREE';
export const UPDATE_DECISION_TREE = 'UPDATE_DECISION_TREE';
export const SET_DECISION_TREE_DESCRIPTION = 'SET_DECISION_TREE_DESCRIPTION';

const updateDecisionTree = (text, source) => ({
  type: UPDATE_DECISION_TREE,
  payload: { text, source },
});

const moveToNextDecisionTree = (serviceId, stepId, answer) => (dispatch, getState) => {
  const decisionTree = chatDecisionTreesSelector(getState()).find(t => t.serviceId === serviceId);
  const nextStep = decisionTree.steps[stepId];
  const delay = answer ? DELAY_500 : 0;
  dispatch(updateDecisionTree(nextStep.text, SOURCE_CONCIERGE));
  setTimeout(() => {
    if (nextStep.type === 'question') {
      dispatch(initializeStep(DECISION_TREE_STEP, {
        question: nextStep.text,
      }, {
        serviceId,
        answers: nextStep.answers,
      }));
    } else {
      dispatch(initializeStep(DECISION_TREE_STEP, { question: nextStep.text }));
      // eslint-disable-next-line no-use-before-define
      setTimeout(() => dispatch(nextDecisionTreeStep(serviceId, nextStep.goto)), DELAY_500);
    }
  }, delay);
};

export const finishDecisionTree = () => (dispatch, getState) => {
  const decisionTreeResults = chatDecisionTreeResultsSelector(getState());
  const previousDecisionTreeResult = decisionTreeResults[decisionTreeResults.length - 1];
  sendDecisionTreeResult(
    chatAppointmentIdSelector(getState()),
    previousDecisionTreeResult,
    sessionTokenSelector(getState()),
  ).then(() => {
    dispatch(goToAdditionalServicesStep());
  }).catch(error => dispatch(initializeErrorStep(error)));
};

export const nextDecisionTreeStep = (serviceId, stepId, answer) => (dispatch) => {
  if (answer) {
    dispatch(updateDecisionTree(answer, SOURCE_USER));
    dispatch(updateCurrentStep({ answer }, { isComplete: true }));
  }
  if (stepId === 'END') {
    dispatch(finishDecisionTree());
  } else {
    try {
      dispatch(moveToNextDecisionTree(serviceId, stepId, answer));
    } catch (err) {
      dispatch(finishDecisionTree());
    }
  }
};

export const initializeDecisionTree = decisionTree => (dispatch) => {
  dispatch({
    type: INITIALIZE_DECISION_TREE,
    payload: {
      serviceId: decisionTree.serviceId,
      intro: decisionTree.intro.text,
    },
  });
  dispatch(initializeStep(DECISION_TREE_STEP, { question: decisionTree.intro.text }));
  setTimeout(() => (
    dispatch(nextDecisionTreeStep(decisionTree.serviceId, decisionTree.intro.goto))
  ), DELAY_500);
};

export const describeDecisionTree = description => (dispatch) => {
  dispatch(updateCurrentStep({ description }));
  dispatch({ type: SET_DECISION_TREE_DESCRIPTION, payload: { description } });
  dispatch(finishDecisionTree());
};
