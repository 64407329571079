import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import { appDealershipNameSelector } from 'selectors/visit/app-selectors';

import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const EnterPhoneNumberStep = ({
  phoneNumber,
  isComplete,
  isConfirmed,
  name,
  dealershipName,
}) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_500}
        message="I’m sorry, I don’t recognize your phone number. Please make your way to the advisor’s desk"
        source={SOURCE_CONCIERGE}
        isClosing
        isSpeaking
        isBig
      />
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={`It's ${phoneNumber}.`}
          source={SOURCE_USER}
          isBig
        />
      </AnimationGroup>
    )}
    {isComplete && isConfirmed && (
      <AnimationGroup>
        <AnimatedTextMessage
          delay={DELAY_500}
          message={`Hello, ${name}. Welcome to ${dealershipName}. My name is Carmen!`}
          source={SOURCE_CONCIERGE}
          isOpening
          isBig
        />
      </AnimationGroup>
    )}
  </Fragment>
);

const mapStateToProps = state => ({
  dealershipName: appDealershipNameSelector(state),
});

EnterPhoneNumberStep.propTypes = {
  phoneNumber: string,
  isComplete: bool,
  isConfirmed: bool,
  name: string,
  dealershipName: string.isRequired,
};

EnterPhoneNumberStep.defaultProps = {
  phoneNumber: '',
  isComplete: false,
  isConfirmed: false,
  name: '',
};

export default connect(mapStateToProps)(EnterPhoneNumberStep);
