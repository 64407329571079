import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { chatUberSelector } from 'selectors/visit/chat-selectors';
import { uberPropType } from 'shared/constants/prop-types';

import TextMessage, { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const UberStep = ({
  isComplete,
  uber: { driverName, vehicle, time },
}) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        message="An Uber would be great."
        source={SOURCE_USER}
        isBig
      />
    </AnimationGroup>
    {isComplete && (
      <TextMessage
        message={`${driverName} in ${vehicle} will pick you up in ${time} minutes.`}
        source={SOURCE_CONCIERGE}
        isOpening
        isBig
        isSpeaking
      />
    )}
  </Fragment>
);

UberStep.propTypes = {
  uber: uberPropType.isRequired,
  isComplete: bool,
};

UberStep.defaultProps = {
  isComplete: false,
};

const mapStateToProps = state => ({
  uber: chatUberSelector(state),
});

const UberStepContainer = connect(mapStateToProps)(UberStep);

export default UberStepContainer;
