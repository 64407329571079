import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { func, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import {
  qrCodeFormPath,
  chatPath,
  appointmentListPath,
  plateNumberFormPath,
  bookingNumberFormPath,
} from 'shared/constants/routes';
import { appAppointmentListFetchedSelector } from 'selectors/visit/app-selectors';
import { chatIsVehicleSyncedSelector, chatIsInitializedSelector } from 'selectors/visit/chat-selectors';
import { importAppointment } from 'actions/visit/welcome-actions';
import spinnerIcon from 'assets/images/spinner.svg';

import styles from './styles.scss';

class BookingNumberForm extends Component {
  state = {
    bookingNumber: this.props.bookingNumber || '',
    isLoading: false,
    shouldRedirectBack: false,
  }

  confirm = () => {
    if (this.state.bookingNumber) {
      this.setState({ isLoading: true });
      this.props.onConfirm(this.state.bookingNumber);
    }
  }

  back = () => {
    if (window.printing_communication_interface) {
      window.printing_communication_interface.requestQrCode();
    }
    this.setState({ shouldRedirectBack: true });
  }

  handleChange = event => this.setState({ bookingNumber: event.target.value });

  render() {
    const {
      bookingNumber,
      isLoading,
    } = this.state;
    if (this.props.isChatInitialized && !this.props.isVehicleSynced) {
      return (<Redirect to={{
            pathname: plateNumberFormPath(),
            state: { backPath: bookingNumberFormPath() },
        }}
      />);
    }
    if (this.props.isChatInitialized) { return <Redirect to={chatPath()} />; }
    if (this.props.isAppointmentListFetched) { return <Redirect to={appointmentListPath()} />; }
    if (this.state.shouldRedirectBack) { return <Redirect to={qrCodeFormPath()} />; }

    return (
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.header}>Check in with booking number</div>
          <div className={styles.content}>
            <div className={styles.inputContainer}>
              <div className={styles.label}>Booking number</div>
              <input
                type="number"
                className={styles.input}
                value={bookingNumber}
                disabled={isLoading}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className={styles.footer}>
            <button
              disabled={isLoading}
              onClick={this.back}
              className={cx(styles.back, { [styles.disabled]: isLoading })}
            >
              Back
            </button>
            <button
              disabled={isLoading}
              onClick={this.confirm}
              className={cx(styles.confirm, { [styles.disabled]: isLoading })}
            >
              Confirm
            </button>
          </div>
        </div>
        {isLoading && (
          <div>
            <img alt="loading" className={styles.loadingState} src={spinnerIcon} />
          </div>
        )}
      </div>
    );
  }
}

BookingNumberForm.propTypes = {
  onConfirm: func.isRequired,
  bookingNumber: string,
  isChatInitialized: bool,
  isAppointmentListFetched: bool,
  isVehicleSynced: bool,
};

BookingNumberForm.defaultProps = {
  bookingNumber: '',
  isChatInitialized: false,
  isAppointmentListFetched: false,
  isVehicleSynced: false,
};

const mapStateToProps = state => ({
  isChatInitialized: chatIsInitializedSelector(state),
  isAppointmentListFetched: appAppointmentListFetchedSelector(state),
  isVehicleSynced: chatIsVehicleSyncedSelector(state),
});

const actions = {
  onConfirm: importAppointment,
};

const BookingNumberFormContainer = connect(mapStateToProps, actions)(BookingNumberForm);

export default BookingNumberFormContainer;
