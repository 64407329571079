import React, { Fragment } from 'react';
import { string } from 'prop-types';

import { DELAY_500 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const DecisionTreeStep = ({ question, answer }) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_500}
        message={question}
        source={SOURCE_CONCIERGE}
        isBig
        isSpeaking
      />
    </AnimationGroup>
    {answer && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={answer}
          source={SOURCE_USER}
          isBig
        />
      </AnimationGroup>
    )}
  </Fragment>
);

DecisionTreeStep.propTypes = {
  question: string.isRequired,
  answer: string,
};

DecisionTreeStep.defaultProps = {
  answer: '',
};

export default DecisionTreeStep;
