import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import { formatPhoneNumber } from 'shared/utils';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

import { chatPhoneNumberSelector } from 'selectors/visit/chat-selectors';

const CommunicationStep = ({
  phoneNumber,
  preferredPhoneNumber,
  isComplete,
  emptyNumber,
}) => (
  <Fragment>
    {emptyNumber ? (
      <AnimationGroup>
        <AnimatedTextMessage
          message="Please provide contact phone number so that I could send you a repair order copy."
          source={SOURCE_CONCIERGE}
          isOpening
          isBig
          isSpeaking
        />
      </AnimationGroup>) : (
        <AnimationGroup>
          <AnimatedTextMessage
            message="Is this the best number to send you a repair order copy?"
            source={SOURCE_CONCIERGE}
            isOpening
            isBig
            isSpeaking
          />
          <AnimatedTextMessage
            delay={DELAY_500}
            message={formatPhoneNumber(phoneNumber)}
            source={SOURCE_CONCIERGE}
            isClosing
            isBig
            isSpeaking
          />
        </AnimationGroup>
    )}
    {isComplete && !emptyNumber && preferredPhoneNumber && (
      <AnimationGroup>
        {preferredPhoneNumber === formatPhoneNumber(phoneNumber)
          ? <AnimatedTextMessage isBig message="Yes, that's right." source={SOURCE_USER} />
          : <AnimatedTextMessage isBig message={`No, use this one: ${preferredPhoneNumber}.`} source={SOURCE_USER} />
        }
      </AnimationGroup>
    )}
    {isComplete && emptyNumber && preferredPhoneNumber && (
      <AnimationGroup>
        <AnimatedTextMessage isBig message={`It's ${preferredPhoneNumber}.`} source={SOURCE_USER} />
      </AnimationGroup>
    )}
  </Fragment>
);

CommunicationStep.propTypes = {
  phoneNumber: string,
  preferredPhoneNumber: string,
  isComplete: bool,
  emptyNumber: bool,
};

CommunicationStep.defaultProps = {
  phoneNumber: '',
  preferredPhoneNumber: '',
  isComplete: false,
  emptyNumber: false,
};

const mapStateToProps = state => ({
  phoneNumber: chatPhoneNumberSelector(state),
});

const CommunicationStepContainer = connect(mapStateToProps)(CommunicationStep);

export default CommunicationStepContainer;
