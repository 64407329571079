import React, { Component } from 'react';
import { number, func, string, arrayOf, shape, bool } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import InlineSVG from 'react-inlinesvg';
import Carousel from 'nuka-carousel';

import { chatVehiclesSelector } from 'selectors/visit/chat-selectors';
import { selectCar, selectNewCar } from 'actions/visit/chat-actions';
import animationWrapper from 'components/common/animationWrapper';
import leftIcon from 'assets/images/left.svg';
import rightIcon from 'assets/images/right.svg';

import styles from './styles.scss';

const FULL_WIDTH = '100%';

class CarSelectionStepInput extends Component {
  state = {
    carouselIndex: 0,
    disabled: false,
    selectedVehicle: this.props.preselectVehicle ? this.props.vehicles[0] : null,
    isNewVehicleSelected: false,
  }

  componentDidUpdate() {
    // nuka-carousel sets slider width accordingly to clientWidth and it is inlined,
    // so no possibility to override it with css. That's why hack is used here.
    if (this.carouselRef) {
      this.carouselRef.frame.firstElementChild.style.width = FULL_WIDTH;
    }
  }

  handleSubmit = () => {
    const { selectedVehicle, isNewVehicleSelected } = this.state;
    const { onSelectNewVehicle, onSelectExistingVehicle } = this.props;
    if (isNewVehicleSelected) {
      onSelectNewVehicle();
    } else {
      onSelectExistingVehicle(selectedVehicle);
    }
    this.setState({ disabled: true });
  }

  canSlideLeft = () => this.state.carouselIndex > 0;
  canSlideRight = () => this.state.carouselIndex < this.props.vehicles.length;

  slideLeft = () => {
    if (this.canSlideLeft()) {
      this.setState({
        carouselIndex: this.state.carouselIndex - 1,
      });
    }
  }

  slideRight = () => {
    if (this.canSlideRight()) {
      this.setState({
        carouselIndex: this.state.carouselIndex + 1,
      });
    }
  }

  selectExistingVehicle = selectedVehicle => () => (
    this.setState({
      isNewVehicleSelected: false,
      selectedVehicle,
    })
  );

  selectNewVehicle = () => (
    this.setState({
      isNewVehicleSelected: true,
      selectedVehicle: null,
    })
  );

  render() {
    const {
      disabled,
      carouselIndex,
      selectedVehicle,
      isNewVehicleSelected,
    } = this.state;

    if (disabled) { return null; }
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <button
            onClick={this.slideLeft}
            className={cx(styles.navigationButton, {
              [styles.disabled]: !this.canSlideLeft(),
            })}
          >
            <InlineSVG src={leftIcon} />
          </button>
          <button
            onClick={this.slideRight}
            className={cx(styles.navigationButton, {
              [styles.disabled]: !this.canSlideRight(),
            })}
          >
            <InlineSVG src={rightIcon} />
          </button>
        </div>
        <Carousel
          ref={(ref) => {
            this.carouselRef = ref;
          }}
          slideIndex={carouselIndex}
          afterSlide={newIndex => this.setState({ carouselIndex: newIndex })}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          renderBottomCenterControls={null}
          slideWidth="256px"
          framePadding="32px 0 0"
          cellSpacing={32}
          defaultControlsConfig={{
          }}
        >
          {this.props.vehicles.map(vehicle => (
            <button
              key={vehicle.id}
              className={cx(styles.slide, {
                [styles.selected]: selectedVehicle && (vehicle.id === selectedVehicle.id),
              })}
              onClick={this.selectExistingVehicle(vehicle)}
            >
              <span className={styles.vehicleName}>{vehicle.name}</span>
              <span className={styles.vehicleYear}>{vehicle.year}</span>
            </button>
          ))}
          <button
            className={cx(styles.newCarSlide, { [styles.selected]: isNewVehicleSelected })}
            onClick={this.selectNewVehicle}
          >
            + Add new car
          </button>
        </Carousel>
        {(selectedVehicle || isNewVehicleSelected) && (
          <button className={styles.submit} onClick={this.handleSubmit}>
            Confirm
          </button>
        )}
      </div>
    );
  }
}

CarSelectionStepInput.propTypes = {
  onSelectExistingVehicle: func.isRequired,
  onSelectNewVehicle: func.isRequired,
  vehicles: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    year: number,
  })).isRequired,
  preselectVehicle: bool,
};

CarSelectionStepInput.defaultProps = {
  preselectVehicle: false,
};

const mapStateToProps = state => ({
  vehicles: chatVehiclesSelector(state),
});

const actions = {
  onSelectExistingVehicle: selectCar,
  onSelectNewVehicle: selectNewCar,
};

const CarSelectionStepInputContainer = connect(mapStateToProps, actions)(CarSelectionStepInput);

export default animationWrapper(CarSelectionStepInputContainer);
