import React, { Component } from 'react';
import { number, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { DELAY_1500 } from 'shared/constants/delays';
import { chatLoadingSelector } from 'selectors/visit/chat-selectors';
import { updateMileage } from 'actions/visit/chat-actions';
import animationWrapper from 'components/common/animationWrapper';

import styles from './styles.scss';

const INCREMENT_DECREMENT_STEP = 5000;

class MileageStepInput extends Component {
  state = {
    mileage: parseInt(this.props.previousMileage, 10) || 0,
  }

  incrementInSteps = () => (
    this.setState(({ mileage }) => ({
      mileage: mileage + INCREMENT_DECREMENT_STEP,
    }))
  )

  decrementInSteps = () => {
    this.setState(({ mileage }) => ({
      mileage: (mileage - INCREMENT_DECREMENT_STEP) > 0
        ? mileage - INCREMENT_DECREMENT_STEP
        : 0,
    }));
  }

  handleMileageChange = event => (
    this.setState({
      mileage: event.target.value >= 0 ? parseInt(event.target.value, 10) : this.state.mileage,
    })
  )

  handleSubmit = () => this.props.onSubmit(this.state.mileage)

  render() {
    const { mileage } = this.state;

    return !this.props.isDisabled && (
      <div className={styles.container}>
        <div className={styles.inputsContainer}>
          <button className={styles.inputButton} onClick={this.decrementInSteps}>
            - 5 k
          </button>
          <input
            type="number"
            className={styles.inputField}
            min={0}
            value={mileage}
            onChange={this.handleMileageChange}
          />
          <button className={styles.inputButton} onClick={this.incrementInSteps}>
            + 5 k
          </button>
        </div>
        <button
          type="button"
          className={cx(styles.submit, { [styles.disabled]: !mileage })}
          disabled={!mileage}
          onClick={this.handleSubmit}
        >
          Confirm
        </button>
      </div>
    );
  }
}

MileageStepInput.propTypes = {
  previousMileage: number,
  onSubmit: func.isRequired,
  isDisabled: bool,
};

MileageStepInput.defaultProps = {
  previousMileage: null,
  isDisabled: false,
};

const mapStateToProps = state => ({
  isDisabled: chatLoadingSelector(state),
});

const actions = {
  onSubmit: updateMileage,
};

const MileageStepInputContainer = connect(mapStateToProps, actions)(MileageStepInput);

export default animationWrapper(MileageStepInputContainer, DELAY_1500);
