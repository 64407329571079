import {
  BOOKING_CONFIRMATION_STEP,
  CUSTOMER_CREATION_STEP,
  CAR_CREATION_STEP,
  AVAILABLE_RECALLS_STEP,
  BOOKING_SUMMARY_STEP,
  FINAL_STEP,
} from 'shared/constants/visit-steps';
import {
  DT_CUSTOMER_NOT_FOUND_ERROR_CODE,
  CDK_EXTRACT_CUSTOMER_NOT_FOUND_ERROR_CODE,
} from 'shared/constants/error-codes';
import { fetchVisitServices } from 'shared/api';
import { isDevelopmentEnv } from 'shared/utils';
import {
  appVisitIdSelector,
  appDealershipIdSelector,
  appBookingFetchedByQrCodeSelector,
} from 'selectors/visit/app-selectors';
import {
  chatCurrentCustomerSelector,
  chatCurrentVehicleSelector,
  chatIsInitializedSelector,
  chatBookingNotFoundSelector,
} from 'selectors/visit/chat-selectors';
import {
  FETCH_VEHICLE_DETAILS,
  SET_CUSTOMER,
  FETCH_UBER_DETAILS,
  FETCH_SERVICES_SUCCESS,
  FETCH_REPAIR_ORDER_DETAILS,
  FETCH_VEHICLES_SUCCESS,
  SET_CURRENT_VEHICLE,
  SET_AVAILABLE_RECALLS,
  SET_CUSTOMER_PROVIDED_PHONE_NUMBER,
  setLoadingState,
  initializeChat,
  initializeStep,
  updateCurrentStep,
  initializeFinalStep,
  initializeErrorStep,
  initializeCarCreationStep,
  initializeMileageStep,
  initializeServiceSelectionStep,
  initializeCarSelectionStep,
} from './chat-actions';

import {
  UPDATE_PLATE_DETAILS,
  FETCH_APPOINTMENT_DETAILS_SUCCESS,
  FETCH_CUSTOMER_APPOINTMENTS_SUCCESS,
  FETCHING_BOOKING_BY_QR_CODE,
  initializeNewBooking,
  fetchAppointmentsOfCustomer,
} from './welcome-actions';

export const EXIT = 'EXIT';
export const NOT_SYNCED = 'not_synced';
export const BACK_FROM_CHAT = 'back_from_chat';
const FETCH_CUSTOMER_RESPONSE_TYPE = 'import_customer';
const FETCH_APPOINTMENT_RESPONSE_TYPE = 'import_appointment';
const FETCH_CUSTOMER_APPOINTMENTS_RESPONSE_TYPE = 'import_customer_appointments';
const CONVERT_APPOINTMENT_TO_RO_RESPONSE_TYPE = 'add_repair_order';
const FETCH_UBER_RESPONSE_TYPE = 'update_transport';
const REQUEST_UBER_RESPONSE_TYPE = 'add_transport';
const ADD_CUSTOMER_RESPONSE_TYPE = 'add_customer';
const ADD_VEHICLE_RESPONSE_TYPE = 'add_vehicle';
const UPDATE_VEHICLE_RESPONSE_TYPE = 'update_vehicle';
const ADD_APPOINTMENT_RESPONSE_TYPE = 'add_appointment';
const UPDATE_APPOINTMENT_RESPONSE_TYPE = 'update_appointment';
const SYNC_VEHICLE_RESPONSE_TYPE = 'sync_vehicle';
const FETCH_VEHICLES_RESPONSE_TYPE = 'import_vehicles';
const IMPORT_RECALLS_RESPONSE_TYPE = 'import_recalls';

const fetchCustomerForTheFirstTime = data => (dispatch) => {
  if (data) {
    dispatch({ type: SET_CUSTOMER, payload: data });
    dispatch({ type: SET_CUSTOMER_PROVIDED_PHONE_NUMBER, payload: { value: true } });
    dispatch(fetchAppointmentsOfCustomer(data.phone_number));
  } else {
    dispatch(initializeNewBooking());
  }
};

const handleCustomerInNewBookingStep = (data, errorMessage, fullErrors) => (dispatch, getState) => {
  dispatch(setLoadingState(false));
  if (data) {
    dispatch({ type: SET_CUSTOMER, payload: data });
    dispatch(updateCurrentStep({ isConfirmed: true, isComplete: true, name: data.name }));
    const currentVehicle = chatCurrentVehicleSelector(getState());
    if (currentVehicle) {
      dispatch(initializeMileageStep(currentVehicle.mileage));
    } else {
      dispatch(initializeCarCreationStep());
    }
  } else if (fullErrors.find(({ code }) => (code === DT_CUSTOMER_NOT_FOUND_ERROR_CODE ||
             code === CDK_EXTRACT_CUSTOMER_NOT_FOUND_ERROR_CODE))) {
    dispatch(initializeStep(CUSTOMER_CREATION_STEP, {}, {
      phoneNumber: chatCurrentCustomerSelector(getState()).phoneNumber,
    }));
  } else {
    dispatch(initializeErrorStep(errorMessage, true));
  }
};

export const actionCableListener = message => (dispatch, getState) => {
  if (isDevelopmentEnv) {
    console.log('Action cable response: ', message); // eslint-disable-line no-console
  }
  const {
    action,
    data,
    errors,
    full_errors: fullErrors,
  } = message;
  const isSystemError = !(errors instanceof Object);
  const errorMessage = isSystemError ? errors : errors.error;
  switch (action) {
    case FETCH_CUSTOMER_APPOINTMENTS_RESPONSE_TYPE:
      if (data) {
        dispatch(setLoadingState(false));
        dispatch({ type: FETCH_CUSTOMER_APPOINTMENTS_SUCCESS, payload: data });
      } else {
        dispatch(setLoadingState(false));
        dispatch(initializeNewBooking());
      }
      break;
    case FETCH_APPOINTMENT_RESPONSE_TYPE:
      if (data) {
        dispatch({ type: FETCH_APPOINTMENT_DETAILS_SUCCESS, payload: data });
        dispatch(initializeChat());
      } else if (appBookingFetchedByQrCodeSelector(getState())) {
        dispatch(setLoadingState(false));
        dispatch({
          type: FETCHING_BOOKING_BY_QR_CODE,
          payload: { isBookingFetchedByQrCode: false },
        });
      } else {
        dispatch(initializeNewBooking());
      }
      break;
    case FETCH_CUSTOMER_RESPONSE_TYPE:
      if (chatIsInitializedSelector(getState())) {
        dispatch(handleCustomerInNewBookingStep(data, errorMessage, fullErrors));
      } else {
        dispatch(fetchCustomerForTheFirstTime(data));
      }
      break;
    case FETCH_VEHICLES_RESPONSE_TYPE:
      dispatch(setLoadingState(false));
      if (data) {
        dispatch({ type: FETCH_VEHICLES_SUCCESS, payload: { vehicles: data } });
        dispatch(initializeCarSelectionStep());
      } else if (errors) {
        dispatch(initializeErrorStep(errors, true));
      } else {
        dispatch(initializeCarCreationStep());
      }
      break;
    case CONVERT_APPOINTMENT_TO_RO_RESPONSE_TYPE:
      dispatch(setLoadingState(false));
      if (data) {
        dispatch({ type: FETCH_REPAIR_ORDER_DETAILS, payload: data });
        dispatch(initializeStep(FINAL_STEP));
      } else {
        dispatch(initializeErrorStep(errors, true));
      }
      break;
    case REQUEST_UBER_RESPONSE_TYPE:
      if (errors) {
        dispatch(setLoadingState(false));
        dispatch(initializeErrorStep(errors, true));
      }
      break;
    case FETCH_UBER_RESPONSE_TYPE:
      if (data) {
        dispatch({ type: FETCH_UBER_DETAILS, payload: data });
        dispatch(initializeFinalStep());
      } else {
        dispatch(initializeErrorStep(errors, true));
      }
      break;
    case ADD_CUSTOMER_RESPONSE_TYPE:
      if (data) {
        dispatch(setLoadingState(false));
        dispatch({ type: SET_CUSTOMER, payload: data });
        dispatch(initializeCarCreationStep());
      } else {
        dispatch(initializeErrorStep(errors, true));
      }
      break;
    case ADD_VEHICLE_RESPONSE_TYPE:
      if (data && data.state !== NOT_SYNCED) {
        dispatch({ type: FETCH_VEHICLE_DETAILS, payload: data });
        fetchVisitServices(
          appVisitIdSelector(getState()),
          appDealershipIdSelector(getState()),
          chatCurrentVehicleSelector(getState()),
        )
          .then((response) => {
            dispatch(setLoadingState(false));
            dispatch({ type: FETCH_SERVICES_SUCCESS, payload: response });
            dispatch(initializeServiceSelectionStep());
          });
      } else if (isSystemError) {
        dispatch(initializeErrorStep(errors, true));
      } else {
        dispatch(setLoadingState(false));
        dispatch({ type: SET_CURRENT_VEHICLE, payload: {} });
        dispatch(initializeStep(CAR_CREATION_STEP, { tryAgain: true }));
      }
      break;
    case UPDATE_VEHICLE_RESPONSE_TYPE:
      if (data && !chatBookingNotFoundSelector(getState())) {
        fetchVisitServices(
          appVisitIdSelector(getState()),
          appDealershipIdSelector(getState()),
          chatCurrentVehicleSelector(getState()),
        )
          .then((response) => {
            dispatch(setLoadingState(false));
            dispatch({ type: FETCH_SERVICES_SUCCESS, payload: response });
            dispatch(initializeStep(BOOKING_CONFIRMATION_STEP));
          });
      } else if (data) {
        fetchVisitServices(
          appVisitIdSelector(getState()),
          appDealershipIdSelector(getState()),
          chatCurrentVehicleSelector(getState()),
        )
          .then((response) => {
            dispatch(setLoadingState(false));
            dispatch({ type: FETCH_SERVICES_SUCCESS, payload: response });
            dispatch(initializeServiceSelectionStep());
          });
      } else {
        dispatch(initializeErrorStep(errors, true));
      }
      break;
    case SYNC_VEHICLE_RESPONSE_TYPE:
      dispatch(setLoadingState(false));
      if (data) {
        dispatch({ type: FETCH_VEHICLE_DETAILS, payload: data });
      } else {
        dispatch({ type: UPDATE_PLATE_DETAILS, payload: { valid: false } });
      }
      break;
    case IMPORT_RECALLS_RESPONSE_TYPE:
      dispatch(setLoadingState(false));

      if (data.length) {
        dispatch({
          type: SET_AVAILABLE_RECALLS,
          payload: {
            recalls: data.map(recall => ({
              ...recall,
              operationCode: recall.operation_code,
              actionType: recall.action_type,
              fee: 0,
              package_items: [],
            })),
          },
        });
        dispatch(initializeStep(AVAILABLE_RECALLS_STEP));
      } else {
        dispatch(initializeStep(BOOKING_SUMMARY_STEP));
      }
      break;
    case ADD_APPOINTMENT_RESPONSE_TYPE:
      if (data) {
        dispatch(setLoadingState(false));
        dispatch({ type: FETCH_APPOINTMENT_DETAILS_SUCCESS, payload: data });
        dispatch(updateCurrentStep({ isComplete: true }));
        dispatch(initializeStep(BOOKING_CONFIRMATION_STEP, { delayed: true }, { delayed: true }));
      } else {
        dispatch(initializeErrorStep(errors, true));
      }
      break;
    case UPDATE_APPOINTMENT_RESPONSE_TYPE:
      dispatch(setLoadingState(false));
      if (data) {
        dispatch({ type: FETCH_APPOINTMENT_DETAILS_SUCCESS, payload: data });
        dispatch(initializeStep(FINAL_STEP));
      } else {
        dispatch(initializeErrorStep(errors, true));
      }
      break;
    default:
      break;
  }
};

export const exit = () => (dispatch) => {
  if (window.voiceInterface.stopVoiceInterface) {
    window.voiceInterface.stopVoiceInterface();
  }
  dispatch({ type: EXIT, payload: {} });
};

export const backFromChat = () => (dispatch) => {
  dispatch({ type: BACK_FROM_CHAT, payload: {} });
};
