import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';

import { authenticateVisit } from 'actions/visit/session-actions';
import { sessionLoadingStateSelector } from 'selectors/visit/session-selectors';
import styles from './styles.scss';

class VisitAuth extends Component {
  state = {
    username: '',
    password: '',
  }

  handleSubmit = (event) => {
    const { username, password } = this.state;

    event.preventDefault();
    this.props.authenticate(username, password);
  }

  handleUsernameChange = event => this.setState({ username: event.target.value })
  handlePasswordChange = event => this.setState({ password: event.target.value })

  render() {
    const { isLoading } = this.props;
    const { username, password } = this.state;

    return (
      <div className={styles.container}>
        <form onSubmit={this.handleSubmit}>
          <input
            name="username"
            value={username}
            onChange={this.handleUsernameChange}
            disabled={isLoading}
          />
          <input
            name="password"
            type="password"
            value={password}
            onChange={this.handlePasswordChange}
            disabled={isLoading}
          />
          <input type="submit" disabled={isLoading} />
          {isLoading && <div>Loading...</div>}
        </form>
      </div>
    );
  }
}

VisitAuth.propTypes = {
  isLoading: bool.isRequired,
  authenticate: func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: sessionLoadingStateSelector(state),
});

const actions = {
  authenticate: authenticateVisit,
};

const VisitAuthContainer = connect(mapStateToProps, actions)(VisitAuth);

export default VisitAuthContainer;
