import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import cx from 'classnames';

import { welcomePath } from 'shared/constants/routes';
import { exit } from 'actions/visit/app-actions';
import exitIcon from 'assets/images/exit.svg';
import withStopSpeaking from 'components/common/speakingWrapper';

import styles from './styles.scss';

class ExitButton extends Component {
  state = {
    shouldRedirect: false,
  }

  onClick = () => {
    this.props.exit();
    this.setState({ shouldRedirect: true });
  }

  render() {
    if (this.state.shouldRedirect) { return <Redirect to={welcomePath()} />; }
    return (
      <button
        className={cx(this.props.className, styles.container)}
        onClick={this.onClick}
      >
        <img alt="exit" src={exitIcon} />
        <span className={styles.caption}>Exit</span>
      </button>
    );
  }
}

ExitButton.propTypes = {
  className: string,
  exit: func.isRequired,
};

ExitButton.defaultProps = {
  className: '',
};

const actions = {
  exit,
};

const ExitButtonContainer = connect(null, actions)(ExitButton);

export default withStopSpeaking(ExitButtonContainer);
