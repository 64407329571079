import React, { Fragment } from 'react';
import { number, bool } from 'prop-types';

import { DELAY_500, DELAY_1000 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const MileageStep = ({ newMileage, previousMileage, isComplete }) => (
  <Fragment>
    {previousMileage ? (
      <AnimationGroup>
        <AnimatedTextMessage
          delay={DELAY_500}
          message={`On your last visit your car mileage was ${previousMileage}`}
          source={SOURCE_CONCIERGE}
          isOpening
          isBig
          isSpeaking
        />
        <AnimatedTextMessage
          delay={DELAY_1000}
          message="What is the approximate mileage right now?"
          source={SOURCE_CONCIERGE}
          isClosing
          isBig
          isSpeaking
        />
      </AnimationGroup>
    ) : (
      <AnimationGroup>
        <AnimatedTextMessage
          delay={DELAY_500}
          message="What is the approximate mileage of your car right now?"
          source={SOURCE_CONCIERGE}
          isOpening
          isBig
          isSpeaking
        />
      </AnimationGroup>
    )}
    {isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={`Current car mileage is ${newMileage}`}
          source={SOURCE_USER}
          isBig
        />
      </AnimationGroup>
    )}
  </Fragment>
);

MileageStep.propTypes = {
  previousMileage: number,
  newMileage: number,
  isComplete: bool,
};

MileageStep.defaultProps = {
  previousMileage: null,
  newMileage: null,
  isComplete: false,
};

export default MileageStep;
