import MobileView from 'components/views/VisitView';

import visitReducer from 'reducers/visit';

import initApp from './index';

// Placeholder for injected Android methods to communicate with Square device
window.paymentInterface = {};
window.receiptInterface = {};
window.speechInterface = {};
window.voiceInterface = {};
window.qrReaderInterface = {};

initApp(MobileView, visitReducer, true);
