import React, { Component, Fragment } from 'react';
import { number, string, bool, func, object, array } from 'prop-types';
import { connect } from 'react-redux';
import InlineSVG from 'react-inlinesvg';

import { DELAY_500, DELAY_1000 } from 'shared/constants/delays';
import { sessionTokenSelector } from 'selectors/visit/session-selectors';
import { chatCustomerNameSelector, chatTotalPriceSelector } from 'selectors/visit/chat-selectors';
import { appVisitIdSelector, appDealershipIdSelector } from 'selectors/visit/app-selectors';
import {
  paymentIntentSecretSelector,
  paymentIntentSelector,
  paymentCompletedSelector,
  paymentReaderSelector,
  paymentDiscoveredReadersSelector,
  paymentErrorsCountSelector,
  paymentErrorSelector,
  paymentConnectionErrorSelector,
} from 'selectors/visit/payment-selectors';
import { paymentSuccess } from 'actions/visit/chat-actions';
import {
  fetchReaders,
  connectReader,
  createPayment,
  collectPaymentMethod,
  confirmPayment,
  capturePayment,
} from 'actions/visit/payment-actions';
import { SOURCE_CONCIERGE } from 'components/common/TextMessage';
import { fetchConnectionToken } from 'shared/api';
import selectedIcon from 'assets/images/checkmark.svg';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

import styles from './styles.scss';

class PaymentStep extends Component {
  state = {
    status: 'terminal_initialized',
  }

  componentDidMount() {
    if (!this.terminal) {
      this.terminal = window.StripeTerminal.create({
        onFetchConnectionToken: () => (
          fetchConnectionToken(this.props.visitId, this.props.dealershipId, this.props.token)
        ),
      });
      const readerType = 'simulated';
      this.props.onTerminalInitialization(this.terminal, readerType);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.discoveredReaders !== prevProps.discoveredReaders && this.state.status === 'terminal_initialized') {
      this.setState({ status: 'connecting_reader' });
      this.connectDiscoveredReader(this.props.discoveredReaders[0]);
    }
    if (this.props.reader !== prevProps.reader && this.state.status === 'connecting_reader') {
      this.setState({ status: 'creating_payment' });
      /* We need to use fixed amount cause only then, in test mode, payment is approved
       * https://stripe.com/docs/terminal/testing#test-cards */
      this.props.onReaderConnection(this.props.amount.toFixed() * 100);
    }
    if (this.props.paymentIntentSecret !== prevProps.paymentIntentSecret && this.state.status === 'creating_payment') {
      this.setState({ status: 'collecting_payment_method' });
      this.props.onPaymentCreation(this.terminal);
    }
    if (this.props.paymentIntent !== prevProps.paymentIntent && this.state.status === 'collecting_payment_method') {
      this.setState({ status: 'confirming_payment' });
      this.props.onPaymentMethodCollection(this.terminal);
    } else if (this.props.paymentIntent !== prevProps.paymentIntent && this.state.status === 'confirming_payment') {
      this.setState({ status: 'capturing_payment' });
      this.props.onPaymentConfirmation();
    }
    if (this.props.paymentCompleted && this.state.status === 'capturing_payment') {
      this.setState({ status: 'payment_completed' });
      this.props.onPaymentSuccess();
    }
    if (this.props.errorsCount !== prevProps.errorsCount && this.props.paymentError) {
      this.setState({ status: 'creating_payment' });
    } else if (this.props.errorsCount !== prevProps.errorsCount && this.props.connectionError) {
      this.setState({ status: 'terminal_initialized' });
    }
  }

  connectDiscoveredReader = reader => this.props.onReadersFetchingSuccess(this.terminal, reader);

  render() {
    const { name, isComplete } = this.props;

    return (
      <Fragment>
        <AnimationGroup>
          <AnimatedTextMessage
            delay={DELAY_500}
            message={`Thank you, ${name}!`}
            source={SOURCE_CONCIERGE}
            isOpening
            isBig
            isSpeaking
          />
          <AnimatedTextMessage
            delay={DELAY_1000}
            message="Please enter your credit card and follow the instructions on the small screen."
            source={SOURCE_CONCIERGE}
            isBig
            isSpeaking
          />
        </AnimationGroup>
        {isComplete && (
          <AnimationGroup>
            <Animation>
              <div className={styles.container}>
                <div className={styles.header}>
                  Your payment has been confirmed.
                </div>
                <div className={styles.icon}>
                  <InlineSVG src={selectedIcon} />
                </div>
              </div>
            </Animation>
          </AnimationGroup>
        )}
      </Fragment>
    );
  }
}

PaymentStep.propTypes = {
  name: string.isRequired,
  amount: number.isRequired,
  token: string.isRequired,
  visitId: number.isRequired,
  dealershipId: number.isRequired,
  onPaymentSuccess: func.isRequired,
  // onPaymentFailure: func.isRequired,
  onTerminalInitialization: func.isRequired,
  onReadersFetchingSuccess: func.isRequired,
  onReaderConnection: func.isRequired,
  onPaymentCreation: func.isRequired,
  onPaymentMethodCollection: func.isRequired,
  onPaymentConfirmation: func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  discoveredReaders: array.isRequired,
  reader: object,
  isComplete: bool,
  paymentIntentSecret: string,
  paymentIntent: object,
  /* eslint-enable */
  paymentCompleted: bool,
  errorsCount: number.isRequired,
  paymentError: bool.isRequired,
  connectionError: bool.isRequired,
};

PaymentStep.defaultProps = {
  isComplete: false,
  paymentCompleted: false,
  reader: null,
  paymentIntent: null,
  paymentIntentSecret: null,
};

const mapStateToProps = state => ({
  name: chatCustomerNameSelector(state),
  amount: chatTotalPriceSelector(state),
  token: sessionTokenSelector(state),
  visitId: appVisitIdSelector(state),
  dealershipId: appDealershipIdSelector(state),
  reader: paymentReaderSelector(state),
  discoveredReaders: paymentDiscoveredReadersSelector(state),
  paymentIntent: paymentIntentSelector(state),
  paymentIntentSecret: paymentIntentSecretSelector(state),
  paymentCompleted: paymentCompletedSelector(state),
  errorsCount: paymentErrorsCountSelector(state),
  paymentError: paymentErrorSelector(state),
  connectionError: paymentConnectionErrorSelector(state),
});

const actions = {
  onPaymentSuccess: paymentSuccess,
  // onPaymentFailure: paymentFailure,
  onTerminalInitialization: fetchReaders,
  onReadersFetchingSuccess: connectReader,
  onReaderConnection: createPayment,
  onPaymentCreation: collectPaymentMethod,
  onPaymentMethodCollection: confirmPayment,
  onPaymentConfirmation: capturePayment,
};

const PaymentStepContainer = connect(mapStateToProps, actions)(PaymentStep);

export default PaymentStepContainer;
