import { combineReducers } from 'redux';

import app from './app-reducer';
import session from './session-reducer';
import chat from './chat-reducer';
import payment from './payment-reducer';
import voice from './voice-reducer';

const reducers = combineReducers({
  app,
  session,
  chat,
  payment,
  voice,
});

export default reducers;
