import React from 'react';
import { string } from 'prop-types';
import { Route } from 'react-router-dom';

import { appointmentListPath, chatPath } from 'shared/constants/routes';
import ExitButton from 'components/common/ExitButton';
import carmenLogo from 'assets/images/carmen_logo_white.svg';

import styles from './styles.scss';

const HeaderBar = ({ dealershipLogo }) => (
  <div className={styles.container}>
    <img alt="carmen logo" src={carmenLogo} className={styles.logo} />
    {dealershipLogo && (
      <img alt="dealership logo" src={dealershipLogo} className={styles.dealership} />
    )}
    <Route
      path={appointmentListPath()}
      render={() => (
        <ExitButton className={styles.exit} />
      )}
    />
    <Route
      path={chatPath()}
      render={() => (
        <ExitButton className={styles.exit} />
      )}
    />
  </div>
);

HeaderBar.propTypes = {
  dealershipLogo: string,
};

HeaderBar.defaultProps = {
  dealershipLogo: null,
};

export default HeaderBar;
