import React from 'react';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500, DELAY_1000 } from 'shared/constants/delays';
import { updateSignature } from 'actions/visit/chat-actions';
import { SOURCE_CONCIERGE } from 'components/common/TextMessage';
import DrawableCanvas from 'components/common/DrawableCanvas';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

import styles from './styles.scss';

const CANVAS_WIDTH = 977;
const CANVAS_HEIGHT = 328;
const CANVAS_LINE_WIDTH = 2;

const SignatureStep = ({ isComplete, signature, onUpdateSignature }) => (
  <AnimationGroup>
    <AnimatedTextMessage
      delay={DELAY_500}
      message="Please sign your repair order below."
      source={SOURCE_CONCIERGE}
      isOpening
      isBig
      isSpeaking
    />
    <Animation delay={DELAY_1000}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.label}>Your signature</span>
        </div>
        <DrawableCanvas
          signature={signature}
          width={CANVAS_WIDTH}
          height={CANVAS_HEIGHT}
          lineWidth={CANVAS_LINE_WIDTH}
          onUpdateContent={onUpdateSignature}
          disabled={isComplete}
        />
      </div>
    </Animation>
  </AnimationGroup>
);

SignatureStep.propTypes = {
  isComplete: bool,
  signature: string,
  onUpdateSignature: func.isRequired,
};

SignatureStep.defaultProps = {
  signature: null,
  isComplete: false,
};

const actions = {
  onUpdateSignature: updateSignature,
};

const SignatureStepContainer = connect(null, actions)(SignatureStep);

export default SignatureStepContainer;
