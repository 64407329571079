import React, { Fragment } from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500 } from 'shared/constants/delays';
import { chatCustomerWaitingSelector } from 'selectors/visit/chat-selectors';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const TransportSelectionStep = ({
  isWaiting, isConfirmed, confirmedWaiting, hasSelectedTransport, needsTransport,
}) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        message={
          isWaiting
            ? "In your booking, you've decided to wait for your car"
            : "In your booking, you've decided not to wait for your car"
        }
        source={SOURCE_CONCIERGE}
        isOpening
        isBig
        isSpeaking
      />
      <AnimatedTextMessage
        delay={DELAY_500}
        message="Please confirm your decision"
        source={SOURCE_CONCIERGE}
        isClosing
        isBig
        isSpeaking
      />
    </AnimationGroup>
    {isConfirmed && (
      confirmedWaiting ? (
        <AnimationGroup>
          <AnimatedTextMessage
            message="I'll wait."
            source={SOURCE_USER}
            isBig
          />
          <AnimatedTextMessage
            delay={DELAY_500}
            message="Great! You can relax in the lounge."
            source={SOURCE_CONCIERGE}
            isOpening
            isBig
            isSpeaking
          />
        </AnimationGroup>
      ) : (
        <AnimationGroup>
          <AnimatedTextMessage
            message="I'll be on my way."
            source={SOURCE_USER}
            isBig
          />
          <AnimatedTextMessage
            delay={DELAY_500}
            message="Do you need an alternative transport?"
            source={SOURCE_CONCIERGE}
            isOpening
            isBig
            isSpeaking
          />
        </AnimationGroup>
      )
    )}
    {hasSelectedTransport && (
      needsTransport ? (
        <AnimationGroup>
          <AnimatedTextMessage
            message="I need transport."
            source={SOURCE_USER}
            isBig
          />
          <AnimatedTextMessage
            delay={DELAY_500}
            message="Which option do you prefer?"
            source={SOURCE_CONCIERGE}
            isOpening
            isBig
            isSpeaking
          />
        </AnimationGroup>
      ) : (
        <AnimationGroup>
          <AnimatedTextMessage
            message="No, thanks."
            source={SOURCE_USER}
            isBig
          />
          <AnimatedTextMessage
            delay={DELAY_500}
            message="Great! Have a safe trip home."
            source={SOURCE_CONCIERGE}
            isOpening
            isBig
            isSpeaking
          />
        </AnimationGroup>
      )
    )}
  </Fragment>
);

TransportSelectionStep.propTypes = {
  isWaiting: bool,
  isConfirmed: bool,
  confirmedWaiting: bool,
  hasSelectedTransport: bool,
  needsTransport: bool,
};

TransportSelectionStep.defaultProps = {
  isWaiting: false,
  isConfirmed: false,
  confirmedWaiting: false,
  hasSelectedTransport: false,
  needsTransport: false,
};

const mapStateToProps = state => ({
  isWaiting: chatCustomerWaitingSelector(state),
});

const TransportSelectionStepContainer = connect(mapStateToProps)(TransportSelectionStep);

export default TransportSelectionStepContainer;
