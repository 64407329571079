import React, { Fragment } from 'react';
import { string, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { DELAY_500, DELAY_1000, DELAY_1500 } from 'shared/constants/delays';
import { makeFloat } from 'shared/utils';
import { bookingPropType } from 'shared/constants/prop-types';
import { chatBookingSelector } from 'selectors/visit/chat-selectors';
import { removeService, removeLineItem } from 'actions/visit/chat-actions';

import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
// import clockIcon from 'assets/images/clock.svg';
import removeIcon from 'assets/images/remove.svg';

import styles from './styles.scss';

const serviceRemovable = (services, lineItems) => {
  const removableServices = [services.maintenance, services.concern, services.extensions];

  return [Object.values(removableServices).flatten(), lineItems].flatten().length > 1;
};

const containsPackage = services =>
  services.maintenance.concat(services.concern).some(s => s.package_items.length !== 0);

const BookingConfirmationStep = ({
  booking: {
    vehicle,
    services,
    date,
    totalPrice,
    // totalTime,
    totalPriceBeforeDiscount,
    discount,
    lineItems,
  },
  delayed,
  isEditing,
  isComplete,
  textFromUser,
  remove,
  removeItem,
}) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={delayed ? DELAY_1000 : DELAY_500}
        message="Please confirm your booking:"
        source={SOURCE_CONCIERGE}
        isOpening
        isBig
        isSpeaking
      />
      <Animation delay={delayed ? DELAY_1500 : DELAY_1000}>
        <div className={styles.container}>
          <div className={styles.header}>Booking details</div>
          <div className={styles.info}>
            <span className={styles.vehicle}>{vehicle}</span>
            <span className={styles.date}>{date}</span>
          </div>
          <div>
            {services.maintenance.map(({
              /* eslint-disable camelcase */
              id, name, fee, package_items, saving,
              /* eslint-enable camelcase */
            }) => (
              <div key={id} className={styles.service}>
                {isEditing && serviceRemovable(services, lineItems) && (
                  <button className={styles.remove} onClick={() => remove(id)}>
                    <img alt="remove service" src={removeIcon} />
                  </button>
                )}
                <div>
                  <span className={styles.name}>{name}</span>
                  {package_items.length > 0 && (
                    <div className={styles.packageItemsContainer}>
                      <span> package contains: </span>
                      {package_items.map(item => (
                        <span>{item.name}</span>
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  {package_items.length > 0 && saving > 0 && (
                    <span className={cx(styles.price, styles.packageItemsPrice)}>
                      ${makeFloat(fee + saving)}
                    </span>
                  )}
                  <span className={styles.price}>${makeFloat(fee)}</span>
                </div>
              </div>
              ))}
            {services.concern.map(({
              /* eslint-disable camelcase */
              id, name, fee, package_items, saving,
              /* eslint-enable camelcase */
            }) => (
              <div key={id} className={styles.service}>
                {isEditing && serviceRemovable(services, lineItems) && (
                  <button className={styles.remove} onClick={() => remove(id)}>
                    <img alt="remove service" src={removeIcon} />
                  </button>
                )}
                <div>
                  <span className={styles.name}>{name}</span>
                  {package_items.length > 0 && (
                    <div className={styles.packageItemsContainer}>
                      <span> package contains: </span>
                      {package_items.map(item => (
                        <span>{item.name}</span>
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  {package_items.length > 0 && saving > 0 && (
                    <span className={cx(styles.price, styles.packageItemsPrice)}>
                      ${makeFloat(fee + saving)}
                    </span>
                  )}
                  <span className={styles.price}>${makeFloat(fee)}</span>
                </div>
              </div>
              ))}
            {lineItems.map(({
              id, name, fee,
            }) => (
              <div key={id} className={styles.service}>
                {isEditing && serviceRemovable(services, lineItems) && (
                  <button className={styles.remove} onClick={() => removeItem(id)}>
                    <img alt="remove service" src={removeIcon} />
                  </button>
                )}
                <div>
                  <span className={styles.name}>{name}</span>
                </div>
                <div>
                  <span className={styles.price}>${makeFloat(fee)}</span>
                </div>
              </div>
              ))}
          </div>
          <div className={styles.summary}>
            <div className={styles.total}>
              <span className={styles.totalLabel}>Total</span>
              <div className={styles.totalPriceContainer}>
                <div>
                  {containsPackage(services) && (
                    <span className={cx(styles.totalValue, styles.totalPackageItemsValue)}>
                      ${makeFloat(totalPriceBeforeDiscount)}
                    </span>
                  )}
                  <span className={styles.totalValue}>${makeFloat(totalPrice)}</span>
                </div>
                {containsPackage(services) && (
                  <div>
                    <span>With package you save</span>
                    <span className={styles.discount}>{discount}%</span>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.summaryBlock}>
              <span className={styles.taxInfo}>Plus applicable fees and taxes</span>
            </div>
            {/* <div className={styles.time}>
              <img alt="clock icon" src={clockIcon} />
              <div>
                <span className={styles.timeLabel}>The estimated service time is:</span>
                <span className={styles.timeValue}>{totalTime}</span>
              </div>
            </div> */}
          </div>
        </div>
      </Animation>
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage message="Yeah, that's right" source={SOURCE_USER} isBig />
      </AnimationGroup>
    )}
    {textFromUser && (
      <AnimationGroup>
        <AnimatedTextMessage message={textFromUser} source={SOURCE_USER} isBig />
      </AnimationGroup>
    )}
  </Fragment>
);

BookingConfirmationStep.propTypes = {
  textFromUser: string,
  isComplete: bool,
  isEditing: bool,
  delayed: bool,
  remove: func.isRequired,
  removeItem: func.isRequired,
  booking: bookingPropType.isRequired,
};

BookingConfirmationStep.defaultProps = {
  textFromUser: '',
  isComplete: false,
  delayed: false,
  isEditing: false,
};

const mapStateToProps = state => ({
  booking: chatBookingSelector(state),
});

const actions = {
  remove: removeService,
  removeItem: removeLineItem,
};

const BookingConfirmationStepContainer = connect(mapStateToProps, actions)(BookingConfirmationStep);

export default BookingConfirmationStepContainer;
