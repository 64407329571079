import {
  SET_AUTH_LOADING_STATE,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILURE,
} from 'actions/visit/session-actions';

const initialState = {
  token: localStorage.getItem('CONCIERGE_TOKEN'),
  refreshToken: localStorage.getItem('CONCIERGE_REFRESH_TOKEN'),
  error: null,
  loadingState: false,
};

const session = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_AUTH_LOADING_STATE:
      return {
        ...state,
        loadingState: true,
      };
    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        token: payload.access_token,
        refreshToken: payload.refresh_token,
        loadingState: false,
      };
    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        error: payload.error,
        loadingState: false,
      };
    default:
      return state;
  }
};

export default session;
