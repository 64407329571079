import React, { Component } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { describeDecisionTree } from 'actions/visit/decision-trees-actions';
import sendIcon from 'assets/images/send.svg';
import { DELAY_1000 } from 'shared/constants/delays';
import animationWrapper from 'components/common/animationWrapper';

import styles from './styles.scss';

class DecisionTreeDescriptionStepInput extends Component {
  state = {
    description: '',
    isComplete: false,
    isValid: true,
  }

  handleChange = event => this.setState({ description: event.target.value })

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.description) {
      this.setState({ isComplete: true });
      this.props.onSubmit(this.state.description);
    } else {
      this.setState({ isValid: false });
    }
  }

  render() {
    if (this.state.isComplete) { return null; }
    return (
      <div className={styles.container}>
        <form className={styles.form} onSubmit={this.handleSubmit}>
          <textarea
            className={cx(styles.input, { [styles.invalid]: !this.state.isValid })}
            value={this.state.description}
            onChange={this.handleChange}
          />
          <button className={styles.submit} onClick={this.handleSubmit}>
            <img alt="send" className={styles.icon} src={sendIcon} />
          </button>
        </form>
      </div>
    );
  }
}

DecisionTreeDescriptionStepInput.propTypes = {
  onSubmit: func.isRequired,
};

const actions = {
  onSubmit: describeDecisionTree,
};

const DecisionTreeDescriptionStepInputContainer =
  connect(null, actions)(DecisionTreeDescriptionStepInput);

export default animationWrapper(DecisionTreeDescriptionStepInputContainer, DELAY_1000);
