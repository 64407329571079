import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { func, array, object, string } from 'prop-types';

import { DELAY_1000 } from 'shared/constants/delays';
import {
  selectWaiting,
  selectNotWaiting,
  selectNoTransport,
  selectNeedTransport,
  selectLoaner,
  selectShuttle,
  selectUber,
  selectLyft,
} from 'actions/visit/chat-actions';
import { chatAvailableTransportsSelector } from 'selectors/visit/chat-selectors';
import {
  voiceClassificationSelector,
  voiceModelNameSelector,
  voiceTranscriptionSelector,
} from 'selectors/visit/voice-selectors';
import { classifyTranscription, utteranceNotMatched } from 'actions/visit/voice-actions';
import animationWrapper from 'components/common/animationWrapper';
import Button from 'components/common/Button';

import styles from './styles.scss';

const WAITING_INPUT = 'WAITING_INPUT';
const NEED_TRANSPORT_INPUT = 'NEED_TRANSPORT_INPUT';
const SELECT_TRANSPORT_INPUT = 'SELECT_TRANSPORT_INPUT';

class TransportSelectionStepInput extends Component {
  state = {
    currentInput: WAITING_INPUT,
    isComplete: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.transcription !== prevProps.transcription && !this.state.isComplete) {
      this.props.classifyTranscription({
        modelName: 'transportSelection',
        transcription: this.props.transcription,
      });
    }
    if (this.props.classification !== prevProps.classification &&
        this.props.modelName === 'transportSelection' && !this.state.isComplete) {
      switch (this.props.classification.intent) {
        case 'wait':
          this.selectWaiting();
          break;
        case 'on my way':
          this.selectNotWaiting();
          break;
        case 'need transport':
          this.selectNeedTransport();
          break;
        case 'no transport':
          this.selectNoTransport();
          break;
        default:
          this.props.utteranceNotMatched();
      }
    }
  }

  selectWaiting = () => {
    this.setState({ isComplete: true });
    this.props.selectWaiting();
  }

  selectNotWaiting = () => {
    this.setState({ currentInput: NEED_TRANSPORT_INPUT });
    this.props.selectNotWaiting();
  }

  selectNoTransport = () => {
    this.setState({ isComplete: true });
    this.props.selectNoTransport();
  }

  selectNeedTransport = () => {
    this.setState({ currentInput: SELECT_TRANSPORT_INPUT });
    this.props.selectNeedTransport();
  }

  selectLoaner = () => {
    this.setState({ isComplete: true });
    this.props.selectLoaner();
  }

  selectShuttle = () => {
    this.setState({ isComplete: true });
    this.props.selectShuttle();
  }

  selectUber = () => {
    this.setState({ isComplete: true });
    this.props.selectUber();
  }

  selectLyft = () => {
    this.setState({ isComplete: true });
    this.props.selectLyft();
  }

  renderWaitingInput = () => (
    <Fragment>
      <Button
        className={styles.firstButton}
        caption="I'll be on my way."
        onClick={this.selectNotWaiting}
        isSecondary
        isBig
      />
      <Button
        caption="I'll wait."
        onClick={this.selectWaiting}
        isBig
      />
    </Fragment>
  )

  renderNeedTransportInput = () => (
    <Fragment>
      <Button
        className={styles.firstButton}
        caption="No, thanks."
        onClick={this.selectNoTransport}
        isSecondary
        isBig
      />
      <Button
        caption="I need transport."
        onClick={this.selectNeedTransport}
        isBig
      />
    </Fragment>
  )

  renderSelectTransportInput = availableTransports => (
    <Fragment>
      {availableTransports.map((t) => {
        if (t.kind === 'loaner') {
          return (
            <Button
              key={t.kind}
              className={styles.firstButton}
              caption="Loaner"
              onClick={this.selectLoaner}
              isSecondary
              isBig
            />
          );
        } else if (t.kind === 'shuttle') {
          return (
            <Button
              key={t.kind}
              className={styles.firstButton}
              caption="Shuttle"
              onClick={this.selectShuttle}
              isSecondary
              isBig
            />
          );
        } else if (t.kind === 'uber') {
          return (
            <Button
              key="lyft"
              className={styles.firstButton}
              caption="Lyft"
              onClick={this.selectLyft}
              isSecondary
              isBig
            />
          );
        }
        return null;
      })}
    </Fragment>
  )

  renderCurrentInput = () => {
    switch (this.state.currentInput) {
      case WAITING_INPUT:
        return this.renderWaitingInput();
      case NEED_TRANSPORT_INPUT:
        return this.renderNeedTransportInput();
      case SELECT_TRANSPORT_INPUT:
        return this.renderSelectTransportInput(this.props.availableTransports);
      default:
        return null;
    }
  }

  render() {
    if (this.state.isComplete) { return null; }
    return (
      <div className={styles.container}>
        {this.renderCurrentInput()}
      </div>
    );
  }
}

TransportSelectionStepInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  availableTransports: array.isRequired,
  selectWaiting: func.isRequired,
  selectNotWaiting: func.isRequired,
  selectNoTransport: func.isRequired,
  selectNeedTransport: func.isRequired,
  selectLoaner: func.isRequired,
  selectShuttle: func.isRequired,
  selectUber: func.isRequired,
  selectLyft: func.isRequired,
  classification: object, // eslint-disable-line react/forbid-prop-types
  modelName: string,
  transcription: string,
  utteranceNotMatched: func.isRequired,
  classifyTranscription: func.isRequired,
};

TransportSelectionStepInput.defaultProps = {
  classification: null,
  transcription: '',
  modelName: '',
};

const mapStateToProps = state => ({
  availableTransports: chatAvailableTransportsSelector(state),
  classification: voiceClassificationSelector(state),
  transcription: voiceTranscriptionSelector(state),
  modelName: voiceModelNameSelector(state),
});

const actions = {
  selectWaiting,
  selectNotWaiting,
  selectNoTransport,
  selectNeedTransport,
  selectLoaner,
  selectShuttle,
  selectUber,
  selectLyft,
  utteranceNotMatched,
  classifyTranscription,
};

const TransportSelectionStepInputContainer =
  connect(mapStateToProps, actions)(TransportSelectionStepInput);

export default animationWrapper(TransportSelectionStepInputContainer, DELAY_1000);
