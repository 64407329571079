export const paymentIntentSecretSelector = state => state.payment.paymentIntentSecret;
export const paymentIntentSelector = state => state.payment.paymentIntent;
export const paymentIntentIdSelector = state => state.payment.paymentIntent.id;
export const paymentCompletedSelector = state => state.payment.isCompleted;
export const paymentReaderSelector = state => state.payment.reader;
export const paymentDiscoveredReadersSelector = state => state.payment.discoveredReaders;
export const paymentReaderDisplayUpdated = state => state.payment.readerDisplayUpdated;
export const paymentErrorsCountSelector = state => state.payment.errorsCount;
export const paymentErrorSelector = state => state.payment.paymentError;
export const paymentConnectionErrorSelector = state => state.payment.connectionError;
