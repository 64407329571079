import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { func, bool } from 'prop-types';

import {
  chatAvailableRecallsSelector,
  chatSelectedRecallsSelector,
} from 'selectors/visit/chat-selectors';
import { DELAY_500, DELAY_1000, DELAY_1500 } from 'shared/constants/delays';
import { servicesPropType } from 'shared/constants/prop-types';
import { toggleRecall } from 'actions/visit/chat-actions';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import RecallService from 'components/common/ExtensionService/RecallService';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

import styles from './styles.scss';

const AvailableRecallsStep = ({
  isComplete, onToggle, availableRecalls, selectedRecalls,
}) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_500}
        message="There is also a safety recall available for your car."
        source={SOURCE_CONCIERGE}
        isOpening
        isBig
        isSpeaking
      />
      <AnimatedTextMessage
        delay={DELAY_1000}
        message="Would you like to add this service to your booking?"
        source={SOURCE_CONCIERGE}
        isBig
        isSpeaking
      />
      <Animation delay={DELAY_1500}>
        <div className={styles.services}>
          {!!availableRecalls.length &&
            <div className={styles.alert}>
              <p>Please note that recall services are conducted based on parts availability.</p>
              <p>Your advisor will update you upon arrival.</p>
            </div>
          }
          {availableRecalls.map(service => (
            <RecallService
              key={service.id}
              service={service}
              onClick={isComplete ? () => null : () => onToggle(service)}
              isHighlighted={!!selectedRecalls.find(({ id }) => service.id === id)}
            />
          ))}
        </div>
      </Animation>
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={
            selectedRecalls.length
              ? `Let's add this to the list: ${selectedRecalls.map(s => s.name).join(', ')}`
              : 'No, thanks.'
            }
          source={SOURCE_USER}
          isBig
        />
      </AnimationGroup>
    )}
  </Fragment>
);

AvailableRecallsStep.propTypes = {
  isComplete: bool,
  availableRecalls: servicesPropType.isRequired,
  selectedRecalls: servicesPropType,
  onToggle: func.isRequired,
};

AvailableRecallsStep.defaultProps = {
  isComplete: false,
  selectedRecalls: [],
};

const mapStateToProps = state => ({
  availableRecalls: chatAvailableRecallsSelector(state),
  selectedRecalls: chatSelectedRecallsSelector(state),
});

const actions = {
  onToggle: toggleRecall,
};

const AvailableRecallsStepContainer = connect(mapStateToProps, actions)(AvailableRecallsStep);

export default AvailableRecallsStepContainer;
